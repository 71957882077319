<aria-onboarding-wrapper>
    <div body class="pb-5">
        <a class="absolute left-4 top-4 flex flex-row cursor-pointer" (click)="goToLogin()">
            <img src="/assets/icons/btns/arrow-left.svg" alt="" />
            <span class="ml-2">Back</span>
        </a>

        <div>
            <div class="items-center flex-col flex w-full" id="magic-link-header">
                <h1 class="sm:text-[36px] mt-4 font-[800] xs:leading-9 xs:text-[24px] text-center">
                    Access Denied
                </h1>

                <h3 class="text-sm font-thin mt-2 leading-5 font-marfa-light">You do not have access to Aria-I
                </h3>
            </div>
        </div>
    </div>
</aria-onboarding-wrapper>