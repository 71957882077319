<section class="w-full h-full" *ngIf="isDesktop; else mobileView">
    <video #video (canplay)="video?.play()" (loadedmetadata)="video.muted = true"
        class="absolute left-0 right-0 top-0 bottom-0 z-[-1] object-cover w-full h-full" autoplay
        controlslist="nodownload" preload="auto" disablepictureinpicture loop muted playsinline>
        <source src="../../../assets/landings/hero.webm" />
    </video>

    <div class="flex justify-between items-center h-svh">
        <div></div>
        <div class="z-1 right-0 pt-5 pb-5 max-w-[600px] h-full text-white">
            <div class="bg-[#121212] h-full max-w-[576px] rounded-[30px] flex flex-col mr-5">
                <div class="w-full pt-5">
                    <div class="text-center justify-center align-middle flex">
                        <div class="svg-icon"></div>
                        <div class="pl-2 text-[18px]">ARIA-i</div>
                    </div>
                </div>

                <div class="pr-32 pl-32 pt-[103px] text-center text-[#828186]">
                    ARIA-i is an AI model designed specifically to help you with learning, making and writing music.
                    Designed by the team that creates music products used by the Juilliard, Berklee and Stanford.
                </div>

                <div class="pt-[102px] text-[36px] text-center w-full">Get started</div>

                <div class="flex w-full pr-7 pl-7 pt-[43px] justify-between">
                    <button class="rounded-[15px] w-[250px] h-[50px] bg-[#333] cursor-pointer" routerLink="login">Log
                        in</button>
                    <button class="rounded-[15px] w-[250px] h-[50px] bg-[#333] cursor-pointer"
                        routerLink="register">Sign
                        up</button>
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #mobileView>
    <section class="w-full h-full">
        <video #video (canplay)="video?.play()"
            class="absolute left-0 right-0 top-0 bottom-0 z-[-1] object-cover w-full h-full" autoplay
            controlslist="nodownload" preload="auto" disablepictureinpicture muted loop playsinline>
            <source src="../../../assets/landings/hero-mobile.webm" />
        </video>

        <div class="px-4 w-full absolute bottom-0">
            <div class="bg-black text-white rounded-t-[30px]">
                <div class="text-[36px] pt-8 text-center w-full">Get started</div>
                <div class="flex w-full pr-7 pl-7 pt-[43px] justify-center items-center flex-col">
                    <button class="rounded-[15px] w-full h-[50px] bg-[#333] cursor-pointer mb-5" routerLink="login">Log
                        in</button>
                    <button class="rounded-[15px] w-full h-[50px] bg-[#333] cursor-pointer mb-6"
                        routerLink="register">Sign
                        up</button>
                </div>

                <div>
                    <div class="w-full pb-5">
                        <div class="text-center justify-center align-middle flex">
                            <div class="svg-icon"></div>
                            <div class="pl-2 text-[18px]">ARIA-i by nkoda</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</ng-template>