/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { UserMessageDto } from '../../models/user-message-dto';

export interface ApiMessageSessionIdPost$Json$Params {
  sessionId: string;
      body?: UserMessageDto
}

export function apiMessageSessionIdPost$Json(http: HttpClient, rootUrl: string, params: ApiMessageSessionIdPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserMessageDto>> {
  const rb = new RequestBuilder(rootUrl, apiMessageSessionIdPost$Json.PATH, 'post');
  if (params) {
    rb.path('sessionId', params.sessionId, {"style":"simple"});
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<UserMessageDto>;
    })
  );
}

apiMessageSessionIdPost$Json.PATH = '/api/message/{sessionId}';
