import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { ModalService } from 'ngx-modal-ease';
import { Subject, takeUntil } from 'rxjs';
import { ProductDto, ReceiptDto } from '../../api/aria/models';
import { SubscriptionPlanComponent } from '../../components/subscription-plan/subscription-plan.component';
import { SettingsService } from '../../services/settings.service';
import { SubscriptionInfoService } from '../../services/subscription-info.service';

@Component({
  selector: 'aria-pricing',
  standalone: true,
  imports: [SubscriptionPlanComponent, SvgIconComponent, CommonModule],
  templateUrl: './pricing.component.html',
  styleUrl: './pricing.component.scss',
})
export class PricingComponent implements OnInit, AfterViewInit, OnDestroy {
  plans!: Array<ProductDto>;
  subscriptions!: Array<ReceiptDto>;
  selectedProductId!: string;
  gridTemplateCols: string = '';

  private _unsubscribeObs$ = new Subject();

  constructor(
    private _modalService: ModalService,
    private _subscriptionInfoService: SubscriptionInfoService,
    private _userSettings: SettingsService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}
  ngOnInit(): void {
    this._subscriptionInfoService.b2cProductsObs$.pipe(takeUntil(this._unsubscribeObs$)).subscribe({
      next: (plans) => {
        plans.forEach((plan) => {
          if (plan.price?.amount && plan.price?.currency) {
            plan.price.currency = plan.price.currency.toLocaleUpperCase();
          }
        });

        this.plans = plans;
        this.setGridColumns();
        this._changeDetectorRef.detectChanges();
      },
    });

    this._subscriptionInfoService.subscriptionsObs$.pipe(takeUntil(this._unsubscribeObs$)).subscribe({
      next: (subscriptions) => {
        this.subscriptions = subscriptions;
      },
    });

    this._userSettings.userSettingsObs$.pipe(takeUntil(this._unsubscribeObs$)).subscribe({
      next: (userSettings) => {
        this.selectedProductId = userSettings.productSelected!;
      },
    });
  }

  ngAfterViewInit(): void {
    this._changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this._unsubscribeObs$.complete();
  }

  closeModal() {
    this._modalService.close();
  }

  private setGridColumns() {
    if (window.innerWidth < 460) {
      this.gridTemplateCols = 'grid-template-columns: repeat(1, minmax(0, 1fr))';
      return;
    }

    if (window.innerWidth < 960) {
      this.gridTemplateCols = 'grid-template-columns: repeat(2, minmax(0, 1fr))';
      return;
    }

    this.gridTemplateCols = `grid-template-columns: repeat(${this.plans?.length}, minmax(0, 1fr))`;
  }

  @HostListener('window:resize')
  @HostListener('window:orientationchange')
  onResize() {
    this.setGridColumns();
  }
}
