<div class="bg-[#333333] dark:bg-[#171717] w-[210px] flex items-center justify-between flex-col font-marfa h-full">
    <div class="flex items-center flex-col h-full">
        <div>
            <svg-icon src="assets/icons/aria-logo-lg.svg"
                [svgStyle]="{ 'width.px': 54, 'height.px': 60, 'color': planInfo.color }"></svg-icon>
        </div>
        <div class="pricing-text-secondary mt-4">{{ planInfo.title }}</div>
        <div *ngIf="planInfo.price?.amount && planInfo.price?.currency; else zeroPrice" class="text-sm text-[#8b8b8b]">
            {{
            planInfo.price?.amount ?? 0 | currency : planInfo.price?.currency! ?? 'USD' }}/{{
            planInfo.price?.interval ?? 'month' }}
        </div>

        <div class="flex items-center flex-col justify-between h-full">
            <div class="mt-4">
                <div *ngFor="let item of planInfo.productInfo; let i = index" class="text-white flex items-start mb-2">
                    <svg-icon src="assets/icons/check-outline.svg"
                        [svgStyle]="{ 'width.px': 24, 'color': planInfo.color, 'margin-top': '-4px' }"></svg-icon>
                    <div class="text-white text-[12px]">{{ item }}</div>
                </div>
            </div>

            <div class="mt-4" *ngIf="ownedPlans?.includes(planInfo.id!) || planInfo.isDefault; else upgradeButton">
                <button class="w-[210px] h-10 bg-[#8b8b8b20] rounded-[12px] text-sm text-[#8b8b8b]">You own this
                    plan</button>
            </div>
        </div>
    </div>
</div>

<ng-template #upgradeButton>
    <div class="mt-4">

        <aria-submit-button class="flex w-[210px]" cssClasses="h-10 rounded-[12px] text-sm text-black"
            (onButtonPressed)="createCheckoutSession(planInfo)" [loading]="loading"
            [bgColor]="planInfo.color ?? '#00FF3C'" textColor="#000000"
            [text]="upgradeDowngradeText"></aria-submit-button>
    </div>
</ng-template>

<ng-template #zeroPrice>
    <div class="text-sm text-[#8b8b8b]">{{
        "0.00" | currency : 'USD' }}/month
    </div>
</ng-template>