import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Edition } from '../../api/aria/models';

@Component({
  selector: 'aria-nkoda-link-edition-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './nkoda-link-edition-list.component.html',
  styleUrl: './nkoda-link-edition-list.component.scss',
})
export class NkodaLinkEditionListComponent {
  @Input() editions!: Array<Edition>;
}
