/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiCategoryCategoryIdCsvGet$Json } from '../fn/category/api-category-category-id-csv-get-json';
import { ApiCategoryCategoryIdCsvGet$Json$Params } from '../fn/category/api-category-category-id-csv-get-json';
import { apiCategoryCategoryIdCsvGet$Plain } from '../fn/category/api-category-category-id-csv-get-plain';
import { ApiCategoryCategoryIdCsvGet$Plain$Params } from '../fn/category/api-category-category-id-csv-get-plain';
import { apiCategoryCategoryIdDelete$Json } from '../fn/category/api-category-category-id-delete-json';
import { ApiCategoryCategoryIdDelete$Json$Params } from '../fn/category/api-category-category-id-delete-json';
import { apiCategoryCategoryIdDelete$Plain } from '../fn/category/api-category-category-id-delete-plain';
import { ApiCategoryCategoryIdDelete$Plain$Params } from '../fn/category/api-category-category-id-delete-plain';
import { apiCategoryCategoryIdGet$Json } from '../fn/category/api-category-category-id-get-json';
import { ApiCategoryCategoryIdGet$Json$Params } from '../fn/category/api-category-category-id-get-json';
import { apiCategoryCategoryIdGet$Plain } from '../fn/category/api-category-category-id-get-plain';
import { ApiCategoryCategoryIdGet$Plain$Params } from '../fn/category/api-category-category-id-get-plain';
import { apiCategoryGet$Json } from '../fn/category/api-category-get-json';
import { ApiCategoryGet$Json$Params } from '../fn/category/api-category-get-json';
import { apiCategoryGet$Plain } from '../fn/category/api-category-get-plain';
import { ApiCategoryGet$Plain$Params } from '../fn/category/api-category-get-plain';
import { apiCategoryListGet$Json } from '../fn/category/api-category-list-get-json';
import { ApiCategoryListGet$Json$Params } from '../fn/category/api-category-list-get-json';
import { apiCategoryListGet$Plain } from '../fn/category/api-category-list-get-plain';
import { ApiCategoryListGet$Plain$Params } from '../fn/category/api-category-list-get-plain';
import { apiCategoryPagedGet$Json } from '../fn/category/api-category-paged-get-json';
import { ApiCategoryPagedGet$Json$Params } from '../fn/category/api-category-paged-get-json';
import { apiCategoryPagedGet$Plain } from '../fn/category/api-category-paged-get-plain';
import { ApiCategoryPagedGet$Plain$Params } from '../fn/category/api-category-paged-get-plain';
import { apiCategoryPost$Json } from '../fn/category/api-category-post-json';
import { ApiCategoryPost$Json$Params } from '../fn/category/api-category-post-json';
import { apiCategoryPost$Plain } from '../fn/category/api-category-post-plain';
import { ApiCategoryPost$Plain$Params } from '../fn/category/api-category-post-plain';
import { CategoryDto } from '../models/category-dto';
import { CategoryDtoPagedResult } from '../models/category-dto-paged-result';

@Injectable({ providedIn: 'root' })
export class CategoryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiCategoryGet()` */
  static readonly ApiCategoryGetPath = '/api/category';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCategoryGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCategoryGet$Plain$Response(params?: ApiCategoryGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CategoryDto>>> {
    return apiCategoryGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCategoryGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCategoryGet$Plain(params?: ApiCategoryGet$Plain$Params, context?: HttpContext): Observable<Array<CategoryDto>> {
    return this.apiCategoryGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CategoryDto>>): Array<CategoryDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCategoryGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCategoryGet$Json$Response(params?: ApiCategoryGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CategoryDto>>> {
    return apiCategoryGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCategoryGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCategoryGet$Json(params?: ApiCategoryGet$Json$Params, context?: HttpContext): Observable<Array<CategoryDto>> {
    return this.apiCategoryGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CategoryDto>>): Array<CategoryDto> => r.body)
    );
  }

  /** Path part for operation `apiCategoryPost()` */
  static readonly ApiCategoryPostPath = '/api/category';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCategoryPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCategoryPost$Plain$Response(params?: ApiCategoryPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CategoryDto>> {
    return apiCategoryPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCategoryPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCategoryPost$Plain(params?: ApiCategoryPost$Plain$Params, context?: HttpContext): Observable<CategoryDto> {
    return this.apiCategoryPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CategoryDto>): CategoryDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCategoryPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCategoryPost$Json$Response(params?: ApiCategoryPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CategoryDto>> {
    return apiCategoryPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCategoryPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCategoryPost$Json(params?: ApiCategoryPost$Json$Params, context?: HttpContext): Observable<CategoryDto> {
    return this.apiCategoryPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CategoryDto>): CategoryDto => r.body)
    );
  }

  /** Path part for operation `apiCategoryListGet()` */
  static readonly ApiCategoryListGetPath = '/api/category/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCategoryListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCategoryListGet$Plain$Response(params?: ApiCategoryListGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CategoryDto>>> {
    return apiCategoryListGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCategoryListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCategoryListGet$Plain(params?: ApiCategoryListGet$Plain$Params, context?: HttpContext): Observable<Array<CategoryDto>> {
    return this.apiCategoryListGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CategoryDto>>): Array<CategoryDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCategoryListGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCategoryListGet$Json$Response(params?: ApiCategoryListGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CategoryDto>>> {
    return apiCategoryListGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCategoryListGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCategoryListGet$Json(params?: ApiCategoryListGet$Json$Params, context?: HttpContext): Observable<Array<CategoryDto>> {
    return this.apiCategoryListGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CategoryDto>>): Array<CategoryDto> => r.body)
    );
  }

  /** Path part for operation `apiCategoryCategoryIdGet()` */
  static readonly ApiCategoryCategoryIdGetPath = '/api/category/{categoryId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCategoryCategoryIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCategoryCategoryIdGet$Plain$Response(params: ApiCategoryCategoryIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CategoryDto>> {
    return apiCategoryCategoryIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCategoryCategoryIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCategoryCategoryIdGet$Plain(params: ApiCategoryCategoryIdGet$Plain$Params, context?: HttpContext): Observable<CategoryDto> {
    return this.apiCategoryCategoryIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CategoryDto>): CategoryDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCategoryCategoryIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCategoryCategoryIdGet$Json$Response(params: ApiCategoryCategoryIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CategoryDto>> {
    return apiCategoryCategoryIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCategoryCategoryIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCategoryCategoryIdGet$Json(params: ApiCategoryCategoryIdGet$Json$Params, context?: HttpContext): Observable<CategoryDto> {
    return this.apiCategoryCategoryIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CategoryDto>): CategoryDto => r.body)
    );
  }

  /** Path part for operation `apiCategoryCategoryIdDelete()` */
  static readonly ApiCategoryCategoryIdDeletePath = '/api/category/{categoryId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCategoryCategoryIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCategoryCategoryIdDelete$Plain$Response(params: ApiCategoryCategoryIdDelete$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return apiCategoryCategoryIdDelete$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCategoryCategoryIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCategoryCategoryIdDelete$Plain(params: ApiCategoryCategoryIdDelete$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.apiCategoryCategoryIdDelete$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCategoryCategoryIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCategoryCategoryIdDelete$Json$Response(params: ApiCategoryCategoryIdDelete$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return apiCategoryCategoryIdDelete$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCategoryCategoryIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCategoryCategoryIdDelete$Json(params: ApiCategoryCategoryIdDelete$Json$Params, context?: HttpContext): Observable<boolean> {
    return this.apiCategoryCategoryIdDelete$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiCategoryPagedGet()` */
  static readonly ApiCategoryPagedGetPath = '/api/category/paged';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCategoryPagedGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCategoryPagedGet$Plain$Response(params?: ApiCategoryPagedGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CategoryDtoPagedResult>>> {
    return apiCategoryPagedGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCategoryPagedGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCategoryPagedGet$Plain(params?: ApiCategoryPagedGet$Plain$Params, context?: HttpContext): Observable<Array<CategoryDtoPagedResult>> {
    return this.apiCategoryPagedGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CategoryDtoPagedResult>>): Array<CategoryDtoPagedResult> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCategoryPagedGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCategoryPagedGet$Json$Response(params?: ApiCategoryPagedGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CategoryDtoPagedResult>>> {
    return apiCategoryPagedGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCategoryPagedGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCategoryPagedGet$Json(params?: ApiCategoryPagedGet$Json$Params, context?: HttpContext): Observable<Array<CategoryDtoPagedResult>> {
    return this.apiCategoryPagedGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CategoryDtoPagedResult>>): Array<CategoryDtoPagedResult> => r.body)
    );
  }

  /** Path part for operation `apiCategoryCategoryIdCsvGet()` */
  static readonly ApiCategoryCategoryIdCsvGetPath = '/api/category/{categoryId}/csv';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCategoryCategoryIdCsvGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCategoryCategoryIdCsvGet$Plain$Response(params: ApiCategoryCategoryIdCsvGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return apiCategoryCategoryIdCsvGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCategoryCategoryIdCsvGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCategoryCategoryIdCsvGet$Plain(params: ApiCategoryCategoryIdCsvGet$Plain$Params, context?: HttpContext): Observable<Blob> {
    return this.apiCategoryCategoryIdCsvGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCategoryCategoryIdCsvGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCategoryCategoryIdCsvGet$Json$Response(params: ApiCategoryCategoryIdCsvGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return apiCategoryCategoryIdCsvGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCategoryCategoryIdCsvGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCategoryCategoryIdCsvGet$Json(params: ApiCategoryCategoryIdCsvGet$Json$Params, context?: HttpContext): Observable<Blob> {
    return this.apiCategoryCategoryIdCsvGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

}
