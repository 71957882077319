export enum AppRoutes {
  Login = 'login',
  ForgotPassword = 'forgot-password',
  MagicLink = 'magic-link',
  InstitutionLogin = 'institution-login',
  InstitutionAccess = 'institution-access',
  Register = 'register',
  Main = 'main',
  Settings = 'settings',
  Unauthorized = 'unauthorized',
  Pricing = 'pricing',
  PurchaseSuccess = 'purchase/success',
  Landing = '/',
}
