<aria-onboarding-wrapper *ngIf="!emailSent; else emailSentSection" [showFooter]="false" [showBack]="true"
    title="Sign in password-free" subtitle="We will send you a magic link to access ARIA-i password-free">
    <div body id="forgot-password-form" class="w-full flex items-center flex-col">
        <form class="flex flex-col text-sm text-black mx-5 items-center w-[300px] sm:w-[240px]">
            <aria-text-input class="w-full" placeholder="Email" name="Email" [formControl]="magicLinkControl"
                [serverError]="serverErrorVM" id="aria-magic-link-input">
            </aria-text-input>
        </form>

        <aria-submit-button [loading]="loading" textColor="#FFFFFF" (onButtonPressed)="submit()"
            class="sm:w-[240px] mt-2 3xs:w-[300px]" text="Send"></aria-submit-button>
    </div>
</aria-onboarding-wrapper>

<ng-template #emailSentSection>
    <aria-onboarding-wrapper [showFooter]="false" [showBack]="false">
        <div body>
            <div class="items-center flex-col flex w-full" id="magic-link-header">
                <p class="sm:text-[36px] font-[800] leading-9 xs:text-[24px]">Email on the way</p>
                <p class="text-[16px] font-thin mt-2 leading-5 max-w-[300px] text-center font-marfa-light">
                    Your magic sign in link have been sent to
                    {{ magicLinkControl.value }}
                </p>

                <p class="mt-8 mb-3">Didn't receive the link?</p>

                <button class="font-marfa-light text-sm bg-[#F3F5F7] p-2 rounded-lg" (click)="sendAgain()">Send email
                    again</button>
            </div>
        </div>
    </aria-onboarding-wrapper>
</ng-template>