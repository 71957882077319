import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { ModalService } from 'ngx-modal-ease';
import { Subject, takeUntil } from 'rxjs';
import { ProductDto, ReceiptDto } from '../../api/aria/models';
import { UserInfoModel } from '../../models/user-info-model';
import { AuthService } from '../../services/auth.service';
import { ClientStripeService } from '../../services/client-stripe.service';
import { SubscriptionInfoService } from '../../services/subscription-info.service';
import { StringUtils } from '../../utils/string.utils';

@Component({
  selector: 'aria-subscription-management',
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
  templateUrl: './subscription-management.component.html',
  styleUrl: './subscription-management.component.scss',
})
export class SubscriptionManagementComponent implements OnInit, OnDestroy {
  subscriptions!: Array<ReceiptDto>;
  products!: Array<ProductDto>;

  selectedProduct!: ProductDto;

  currentOwnedProduct!: ProductDto;
  upgradeDowngradeText!: string;

  private _unsubscribeObs$ = new Subject();
  private _user!: UserInfoModel;

  constructor(
    private _modalService: ModalService,
    private _subscriptionService: SubscriptionInfoService,
    private _clientStripeService: ClientStripeService,
    private _authService: AuthService
  ) {}

  ngOnInit(): void {
    this._subscriptionService.subscriptionsObs$.pipe(takeUntil(this._unsubscribeObs$)).subscribe({
      next: (subscriptions) => {
        this.subscriptions = subscriptions;
      },
    });

    this._subscriptionService.b2cProductsObs$.pipe(takeUntil(this._unsubscribeObs$)).subscribe({
      next: (products) => {
        this.products = products.filter((x) => !x.isDefault);
        this.products.forEach((product) => {
          if (product.price) product.price.currency = product.price?.currency?.toLocaleUpperCase();
        });

        if (this.products[0]?.id) this.selectProduct(this.products[0].id);

        if (!this.subscriptions || !this.subscriptions.length) {
          this.currentOwnedProduct = products[0];
          return;
        }

        this.currentOwnedProduct = this.products.find(
          (x) => this.subscriptions.find((receipt) => receipt.ariaProductId == x.id)?.ariaProductId === x?.id
        )!;
      },
    });

    this._authService.userObs$.pipe(takeUntil(this._unsubscribeObs$)).subscribe({
      next: (user) => {
        this._user = user;
      },
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeObs$.complete();
  }

  closeModal() {
    this._modalService.close();
  }

  selectProduct(productId: string) {
    if (StringUtils.isNullOrEmpty(productId)) return;
    this.selectedProduct = this.products.find((x) => x.id === productId)!;

    this.setUpgradeDowngradeText();
  }

  setUpgradeDowngradeText() {
    const indexOfCurrentProduct = this.products.findIndex((x) => x.id === this.selectedProduct.id);

    if (!this.subscriptions) {
      this.upgradeDowngradeText = `Upgrade to ${this.selectedProduct?.title}`;
      return;
    }

    if (this.subscriptions?.length === 0) {
      this.upgradeDowngradeText = `Upgrade to ${this.selectedProduct?.title}`;
      return;
    }

    if (indexOfCurrentProduct === this.products.length - 1) {
      this.upgradeDowngradeText = `Upgrade to ${this.products[indexOfCurrentProduct]?.title}`;
      return;
    }

    if (indexOfCurrentProduct !== this.products.length - 1 && indexOfCurrentProduct !== 0) {
      this.upgradeDowngradeText = `Upgrade to ${this.products[indexOfCurrentProduct]?.title}`;
      return;
    }

    this.upgradeDowngradeText = `Downgrade to ${this.products[indexOfCurrentProduct]?.title}`;
  }

  createCustomerPortalSession() {
    const subscription = this.subscriptions.find((x) => x.transactionId);
    const transactionId = subscription?.transactionId;
    if (!transactionId) {
      this._clientStripeService.createCheckoutSession(this.selectedProduct, this._user).subscribe({
        next: ([checkoutSession, stripe]) => {
          if (!checkoutSession?.checkoutSessionId) return;
          stripe?.redirectToCheckout({
            sessionId: checkoutSession.checkoutSessionId,
          });
        },
      });

      return;
    }

    this._clientStripeService.createCustomerPortalSession(transactionId).subscribe({
      next: (url: string) => {
        if (this.selectedProduct.id === this.currentOwnedProduct.id) {
          window.location.href = url;
          return;
        }

        location.href = url + `/subscriptions/${transactionId}/update`;
      },
    });
  }

  createCheckoutSession(product: ProductDto) {
    this._clientStripeService.createCheckoutSession(product, this._user).subscribe({
      next: ([checkoutSession, stripe]) => {
        if (!checkoutSession?.checkoutSessionId) return;
        stripe?.redirectToCheckout({
          sessionId: checkoutSession.checkoutSessionId,
        });
      },
    });
  }
}
