import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ModalService } from 'ngx-modal-ease';
import { forkJoin } from 'rxjs';
import { CategoryDto, ModelDto, ResearcherHistoryDto } from '../../api/aria/models';
import { HistoryService, ModelService } from '../../api/aria/services';
import { EmbeddingComponent } from '../embedding/embedding.component';
import { FullPromptComponent } from '../full-prompt/full-prompt.component';

@Component({
  selector: 'aria-history',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './history.component.html',
  styleUrl: './history.component.scss',
})
export class HistoryComponent implements OnInit {
  researcherHistory!: ResearcherHistoryDto;
  categories!: Array<CategoryDto>;
  models!: Array<ModelDto>;
  selectedModelName!: string;

  constructor(
    private _modalService: ModalService,
    private _historyService: HistoryService,
    private _modelsService: ModelService
  ) {}

  ngOnInit(): void {
    const messageId = this._modalService.options?.data!['messageId'] as string;
    forkJoin([
      this._historyService.apiHistoryMessageMessageIdGet$Json({
        messageId: messageId,
      }),
      this._modelsService.apiModelGet$Json(),
    ]).subscribe({
      next: ([researcherSettings, models]) => {
        this.researcherHistory = researcherSettings;
        this.models = models;
        this.selectedModelName = this.models.find((x) => x.id === researcherSettings.modelName)?.name ?? '';
      },
    });
  }

  openFullPromptModal() {
    this._modalService.open(FullPromptComponent, {
      modal: {
        enter: 'enter-scale-down 0.1s ease-out',
      },
      overlay: {
        leave: 'fade-out 0.3s',
      },
      size: {
        width: '100%',
        height: '100%',
        padding: '0',
      },
      data: {
        researcherHistory: this.researcherHistory,
      },
    });
  }

  openEmbeddingModel() {
    this._modalService.open(EmbeddingComponent, {
      modal: {
        enter: 'enter-scale-down 0.1s ease-out',
      },
      overlay: {
        leave: 'fade-out 0.3s',
      },
      size: {
        width: this.researcherHistory?.embeddings?.length ? '100%' : 'fit-content',
        height: this.researcherHistory?.embeddings?.length ? '100%' : 'fit-content',
      },
      data: {
        embeddings: this.researcherHistory?.embeddings,
      },
    });
  }

  closeDialog() {
    this._modalService.close();
  }
}
