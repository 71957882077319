import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ThresholdInfo } from '../api/aria/models';
import { ProductService } from '../api/aria/services';

@Injectable({
  providedIn: 'root',
})
export class ClientThresholdService {
  private _userThresholdSubject = new BehaviorSubject<ThresholdInfo>({});
  public userThresholdObs$ = this._userThresholdSubject.asObservable();

  constructor(private _productService: ProductService) {}

  getUserThreshold(productId: string) {
    this._productService.apiProductProductIdThresholdGet$Json({ productId }).subscribe((threshold) => {
      this._userThresholdSubject.next(threshold);
    });
  }

  decrementUserThreshold() {
    const currentValue = this._userThresholdSubject.value;
    if (currentValue.currentThreshold) currentValue.currentThreshold--;

    this._userThresholdSubject.next(currentValue);
  }
}
