/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ProductDtoPagedResult } from '../../models/product-dto-paged-result';
import { SortDirection } from '../../models/sort-direction';

export interface ApiProductPagedGet$Json$Params {
  PageSize?: number;
  PageNumber?: number;
  Filter?: string;
  FilterFields?: Array<string>;
  SortField?: string;
  SortOrder?: SortDirection;
  ContinuationToken?: string;
}

export function apiProductPagedGet$Json(http: HttpClient, rootUrl: string, params?: ApiProductPagedGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductDtoPagedResult>> {
  const rb = new RequestBuilder(rootUrl, apiProductPagedGet$Json.PATH, 'get');
  if (params) {
    rb.query('PageSize', params.PageSize, {"style":"form"});
    rb.query('PageNumber', params.PageNumber, {"style":"form"});
    rb.query('Filter', params.Filter, {"style":"form"});
    rb.query('FilterFields', params.FilterFields, {"style":"form"});
    rb.query('SortField', params.SortField, {"style":"form"});
    rb.query('SortOrder', params.SortOrder, {"style":"form"});
    rb.query('ContinuationToken', params.ContinuationToken, {"style":"form"});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ProductDtoPagedResult>;
    })
  );
}

apiProductPagedGet$Json.PATH = '/api/product/paged';
