import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThinkService {
  private ariaThinkingSubject = new BehaviorSubject<boolean>(false);
  public ariaThinkingObs = this.ariaThinkingSubject.asObservable();

  constructor() {}

  startThinking() {
    this.ariaThinkingSubject.next(true);
  }

  stopAriaThinking() {
    this.ariaThinkingSubject.next(false);
  }
}
