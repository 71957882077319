import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { CheckBoxComponent } from '../../components/onboarding/check-box/check-box.component';
import { OnboardingWrapperComponent } from '../../components/onboarding/onboarding-wrapper/onboarding-wrapper.component';
import { PasswordErrorComponent } from '../../components/onboarding/password-error/password-error.component';
import { SubmitButtonComponent } from '../../components/onboarding/submit-button/submit-button.component';
import { TextInputComponent } from '../../components/onboarding/text-input/text-input.component';
import { ProviderComponent } from '../../components/provider/provider.component';
import { AppRoutes } from '../../constants/appRoutes';
import { PasswordRegex } from '../../constants/authConstants';
import { SignUpModel } from '../../models/sign-up-model';
import { AuthService } from '../../services/auth.service';
import { TextInputService } from '../../services/text-input.service';
import { emailAvailability } from '../../validators/email-availability.validator';
import { extendedPatternValidator } from '../../validators/extended-pattern.validator';

@Component({
  selector: 'app-signup',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    ProviderComponent,
    TextInputComponent,
    CheckBoxComponent,
    PasswordErrorComponent,
    ProviderComponent,
    SubmitButtonComponent,
    OnboardingWrapperComponent,
  ],
  templateUrl: './signup.component.html',
  styleUrl: './signup.component.scss',
})
export class SignupComponent implements OnInit, OnDestroy {
  appRoutes = AppRoutes;
  continueWithEmail = false;
  signupFormGroup!: FormGroup;
  serverErrorVM: string = '';
  isTermAndConditionAccepted = false;
  showTermsAndConditionError = false;
  requestInProgress = false;

  private _unsubscribe$: Subject<void> = new Subject();

  constructor(private _authService: AuthService, private _router: Router, private _textInput: TextInputService) {}

  ngOnInit(): void {
    this._unsubscribe$ = new Subject();
    this.signupFormGroup = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      password: new FormControl('', [
        extendedPatternValidator(PasswordRegex.atLeastOneSpecialCharacter, 'includeSpecial'),
        extendedPatternValidator(PasswordRegex.atLeastOneNumber, 'includeNumber'),
        extendedPatternValidator(PasswordRegex.atLeastOneUpperCaseCharacter, 'upperCase'),
        extendedPatternValidator(PasswordRegex.atLeastOneLowerCaseCharacter, 'lowerCase'),
        extendedPatternValidator(PasswordRegex.atLeastEightCharacters, 'minLength'),
        Validators.required,
      ]),
      email: new FormControl('', {
        validators: [Validators.required, Validators.email],
        asyncValidators: [emailAvailability(this._authService)],
      }),
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe$.complete();
  }

  get email() {
    return this.signupFormGroup.get('email') as FormControl;
  }

  get firstName() {
    return this.signupFormGroup.get('firstName') as FormControl;
  }

  get lastName() {
    return this.signupFormGroup.get('lastName') as FormControl;
  }

  get password() {
    return this.signupFormGroup.get('password') as FormControl;
  }

  submit() {
    if (!this.signupFormGroup.valid) {
      this.signupFormGroup.markAllAsTouched();
      this._textInput.shakeInput();
      return;
    }

    if (!this.isTermAndConditionAccepted) {
      this.showTermsAndConditionError = true;
      return;
    }

    this.requestInProgress = true;
    const signupModel = {} as SignUpModel;
    signupModel.password = this.password?.value;
    signupModel.email = this.email?.value;
    signupModel.firstName = this.firstName?.value;
    signupModel.lastName = this.lastName?.value;

    this._authService
      .signUp(signupModel)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe({
        next: () => {
          this._router.navigateByUrl(AppRoutes.Main);
        },
        error: (httpErrorResponse: HttpErrorResponse) => {
          this.serverErrorVM = httpErrorResponse.error?.message;
        },
      })
      .add(() => {
        this.requestInProgress = false;
      });
  }

  checkTermsAndConditions() {
    this.isTermAndConditionAccepted = !this.isTermAndConditionAccepted;
    this.showTermsAndConditionError = false;
  }
}
