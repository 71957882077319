import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AppRoutes } from '../../constants/appRoutes';
import { InstitutionService } from '../../services/institution.service';

@Component({
  selector: 'aria-institution-access',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './institution-access.component.html',
  styleUrl: './institution-access.component.scss',
})
export class InstitutionAccessComponent implements OnInit, OnDestroy {
  private _unsubscribeObs$ = new Subject();
  constructor(private _location: Location, private _institutionService: InstitutionService, private _router: Router) {}

  ngOnInit(): void {
    this._unsubscribeObs$ = new Subject();
  }

  ngOnDestroy(): void {
    this._unsubscribeObs$.complete();
  }

  back() {
    this._location.back();
  }

  acceptInstitutionAccess() {
    this._institutionService
      .acceptInstitutionAccess()
      .pipe(takeUntil(this._unsubscribeObs$))
      .subscribe({
        next: () => {
          this._router.navigate([AppRoutes.Main]);
        },
      });
  }

  declineInstitutionAccess() {
    this._institutionService
      .declineInstitutionAccess()
      .pipe(takeUntil(this._unsubscribeObs$))
      .subscribe({
        next: () => {
          this._router.navigate([AppRoutes.Main]);
        },
      });
  }
}
