<button (click)="showHide()">
  <span>
    <div class="flex h-[72px] w-8 items-center justify-center text-current hover:scale-120 hover:transition-120">
      <div class="flex h-6 w-6 flex-col items-center" *ngIf="!isHidden; else openPanel">
        <div class="h-3 w-1 rounded-full" style="
            background: var(--text-tertiary);
            transform: translateY(0.15rem) rotate(0deg) translateZ(0px);
          "></div>
        <div class="h-3 w-1 rounded-full" style="
            background: var(--text-tertiary);
            transform: translateY(-0.15rem) rotate(0deg) translateZ(0px);
          "></div>
      </div>
    </div>
  </span>
</button>

<ng-template #openPanel>
  <div class="flex h-6 w-6 flex-col items-center">
    <div class="h-3 w-1 rounded-full"
      style="background: var(--text-tertiary); transform: translateY(0.15rem) rotate(15deg) translateZ(0px);"></div>
    <div class="h-3 w-1 rounded-full"
      style="background: var(--text-tertiary); transform: translateY(-0.15rem) rotate(-15deg) translateZ(0px);"></div>
  </div>
</ng-template>