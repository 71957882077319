/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiModelConfigModelConfigIdDelete$Json } from '../fn/model/api-model-config-model-config-id-delete-json';
import { ApiModelConfigModelConfigIdDelete$Json$Params } from '../fn/model/api-model-config-model-config-id-delete-json';
import { apiModelConfigModelConfigIdDelete$Plain } from '../fn/model/api-model-config-model-config-id-delete-plain';
import { ApiModelConfigModelConfigIdDelete$Plain$Params } from '../fn/model/api-model-config-model-config-id-delete-plain';
import { apiModelConfigModelConfigIdGet$Json } from '../fn/model/api-model-config-model-config-id-get-json';
import { ApiModelConfigModelConfigIdGet$Json$Params } from '../fn/model/api-model-config-model-config-id-get-json';
import { apiModelConfigModelConfigIdGet$Plain } from '../fn/model/api-model-config-model-config-id-get-plain';
import { ApiModelConfigModelConfigIdGet$Plain$Params } from '../fn/model/api-model-config-model-config-id-get-plain';
import { apiModelConfigPagedGet$Json } from '../fn/model/api-model-config-paged-get-json';
import { ApiModelConfigPagedGet$Json$Params } from '../fn/model/api-model-config-paged-get-json';
import { apiModelConfigPagedGet$Plain } from '../fn/model/api-model-config-paged-get-plain';
import { ApiModelConfigPagedGet$Plain$Params } from '../fn/model/api-model-config-paged-get-plain';
import { apiModelConfigPost$Json } from '../fn/model/api-model-config-post-json';
import { ApiModelConfigPost$Json$Params } from '../fn/model/api-model-config-post-json';
import { apiModelConfigPost$Plain } from '../fn/model/api-model-config-post-plain';
import { ApiModelConfigPost$Plain$Params } from '../fn/model/api-model-config-post-plain';
import { apiModelGet$Json } from '../fn/model/api-model-get-json';
import { ApiModelGet$Json$Params } from '../fn/model/api-model-get-json';
import { apiModelGet$Plain } from '../fn/model/api-model-get-plain';
import { ApiModelGet$Plain$Params } from '../fn/model/api-model-get-plain';
import { apiModelModelIdDelete$Json } from '../fn/model/api-model-model-id-delete-json';
import { ApiModelModelIdDelete$Json$Params } from '../fn/model/api-model-model-id-delete-json';
import { apiModelModelIdDelete$Plain } from '../fn/model/api-model-model-id-delete-plain';
import { ApiModelModelIdDelete$Plain$Params } from '../fn/model/api-model-model-id-delete-plain';
import { apiModelModelIdGet$Json } from '../fn/model/api-model-model-id-get-json';
import { ApiModelModelIdGet$Json$Params } from '../fn/model/api-model-model-id-get-json';
import { apiModelModelIdGet$Plain } from '../fn/model/api-model-model-id-get-plain';
import { ApiModelModelIdGet$Plain$Params } from '../fn/model/api-model-model-id-get-plain';
import { apiModelPagedGet$Json } from '../fn/model/api-model-paged-get-json';
import { ApiModelPagedGet$Json$Params } from '../fn/model/api-model-paged-get-json';
import { apiModelPagedGet$Plain } from '../fn/model/api-model-paged-get-plain';
import { ApiModelPagedGet$Plain$Params } from '../fn/model/api-model-paged-get-plain';
import { apiModelPost$Json } from '../fn/model/api-model-post-json';
import { ApiModelPost$Json$Params } from '../fn/model/api-model-post-json';
import { apiModelPost$Plain } from '../fn/model/api-model-post-plain';
import { ApiModelPost$Plain$Params } from '../fn/model/api-model-post-plain';
import { ModelConfigurationDto } from '../models/model-configuration-dto';
import { ModelConfigurationDtoPagedResult } from '../models/model-configuration-dto-paged-result';
import { ModelDto } from '../models/model-dto';
import { ModelDtoPagedResult } from '../models/model-dto-paged-result';
import { ModelUpdateDto } from '../models/model-update-dto';

@Injectable({ providedIn: 'root' })
export class ModelService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiModelGet()` */
  static readonly ApiModelGetPath = '/api/model';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiModelGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiModelGet$Plain$Response(params?: ApiModelGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ModelDto>>> {
    return apiModelGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiModelGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiModelGet$Plain(params?: ApiModelGet$Plain$Params, context?: HttpContext): Observable<Array<ModelDto>> {
    return this.apiModelGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ModelDto>>): Array<ModelDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiModelGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiModelGet$Json$Response(params?: ApiModelGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ModelDto>>> {
    return apiModelGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiModelGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiModelGet$Json(params?: ApiModelGet$Json$Params, context?: HttpContext): Observable<Array<ModelDto>> {
    return this.apiModelGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ModelDto>>): Array<ModelDto> => r.body)
    );
  }

  /** Path part for operation `apiModelPost()` */
  static readonly ApiModelPostPath = '/api/model';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiModelPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiModelPost$Plain$Response(params?: ApiModelPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelUpdateDto>> {
    return apiModelPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiModelPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiModelPost$Plain(params?: ApiModelPost$Plain$Params, context?: HttpContext): Observable<ModelUpdateDto> {
    return this.apiModelPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelUpdateDto>): ModelUpdateDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiModelPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiModelPost$Json$Response(params?: ApiModelPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelUpdateDto>> {
    return apiModelPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiModelPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiModelPost$Json(params?: ApiModelPost$Json$Params, context?: HttpContext): Observable<ModelUpdateDto> {
    return this.apiModelPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelUpdateDto>): ModelUpdateDto => r.body)
    );
  }

  /** Path part for operation `apiModelModelIdGet()` */
  static readonly ApiModelModelIdGetPath = '/api/model/{modelId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiModelModelIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiModelModelIdGet$Plain$Response(params: ApiModelModelIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelDto>> {
    return apiModelModelIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiModelModelIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiModelModelIdGet$Plain(params: ApiModelModelIdGet$Plain$Params, context?: HttpContext): Observable<ModelDto> {
    return this.apiModelModelIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelDto>): ModelDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiModelModelIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiModelModelIdGet$Json$Response(params: ApiModelModelIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelDto>> {
    return apiModelModelIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiModelModelIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiModelModelIdGet$Json(params: ApiModelModelIdGet$Json$Params, context?: HttpContext): Observable<ModelDto> {
    return this.apiModelModelIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelDto>): ModelDto => r.body)
    );
  }

  /** Path part for operation `apiModelModelIdDelete()` */
  static readonly ApiModelModelIdDeletePath = '/api/model/{modelId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiModelModelIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiModelModelIdDelete$Plain$Response(params: ApiModelModelIdDelete$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return apiModelModelIdDelete$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiModelModelIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiModelModelIdDelete$Plain(params: ApiModelModelIdDelete$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.apiModelModelIdDelete$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiModelModelIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiModelModelIdDelete$Json$Response(params: ApiModelModelIdDelete$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return apiModelModelIdDelete$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiModelModelIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiModelModelIdDelete$Json(params: ApiModelModelIdDelete$Json$Params, context?: HttpContext): Observable<boolean> {
    return this.apiModelModelIdDelete$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiModelConfigModelConfigIdGet()` */
  static readonly ApiModelConfigModelConfigIdGetPath = '/api/model/config/{modelConfigId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiModelConfigModelConfigIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiModelConfigModelConfigIdGet$Plain$Response(params: ApiModelConfigModelConfigIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelConfigurationDto>> {
    return apiModelConfigModelConfigIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiModelConfigModelConfigIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiModelConfigModelConfigIdGet$Plain(params: ApiModelConfigModelConfigIdGet$Plain$Params, context?: HttpContext): Observable<ModelConfigurationDto> {
    return this.apiModelConfigModelConfigIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelConfigurationDto>): ModelConfigurationDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiModelConfigModelConfigIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiModelConfigModelConfigIdGet$Json$Response(params: ApiModelConfigModelConfigIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelConfigurationDto>> {
    return apiModelConfigModelConfigIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiModelConfigModelConfigIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiModelConfigModelConfigIdGet$Json(params: ApiModelConfigModelConfigIdGet$Json$Params, context?: HttpContext): Observable<ModelConfigurationDto> {
    return this.apiModelConfigModelConfigIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelConfigurationDto>): ModelConfigurationDto => r.body)
    );
  }

  /** Path part for operation `apiModelConfigModelConfigIdDelete()` */
  static readonly ApiModelConfigModelConfigIdDeletePath = '/api/model/config/{modelConfigId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiModelConfigModelConfigIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiModelConfigModelConfigIdDelete$Plain$Response(params: ApiModelConfigModelConfigIdDelete$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return apiModelConfigModelConfigIdDelete$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiModelConfigModelConfigIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiModelConfigModelConfigIdDelete$Plain(params: ApiModelConfigModelConfigIdDelete$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.apiModelConfigModelConfigIdDelete$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiModelConfigModelConfigIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiModelConfigModelConfigIdDelete$Json$Response(params: ApiModelConfigModelConfigIdDelete$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return apiModelConfigModelConfigIdDelete$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiModelConfigModelConfigIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiModelConfigModelConfigIdDelete$Json(params: ApiModelConfigModelConfigIdDelete$Json$Params, context?: HttpContext): Observable<boolean> {
    return this.apiModelConfigModelConfigIdDelete$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiModelPagedGet()` */
  static readonly ApiModelPagedGetPath = '/api/model/paged';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiModelPagedGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiModelPagedGet$Plain$Response(params?: ApiModelPagedGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ModelDtoPagedResult>>> {
    return apiModelPagedGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiModelPagedGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiModelPagedGet$Plain(params?: ApiModelPagedGet$Plain$Params, context?: HttpContext): Observable<Array<ModelDtoPagedResult>> {
    return this.apiModelPagedGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ModelDtoPagedResult>>): Array<ModelDtoPagedResult> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiModelPagedGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiModelPagedGet$Json$Response(params?: ApiModelPagedGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ModelDtoPagedResult>>> {
    return apiModelPagedGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiModelPagedGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiModelPagedGet$Json(params?: ApiModelPagedGet$Json$Params, context?: HttpContext): Observable<Array<ModelDtoPagedResult>> {
    return this.apiModelPagedGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ModelDtoPagedResult>>): Array<ModelDtoPagedResult> => r.body)
    );
  }

  /** Path part for operation `apiModelConfigPagedGet()` */
  static readonly ApiModelConfigPagedGetPath = '/api/model/config/paged';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiModelConfigPagedGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiModelConfigPagedGet$Plain$Response(params?: ApiModelConfigPagedGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ModelConfigurationDtoPagedResult>>> {
    return apiModelConfigPagedGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiModelConfigPagedGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiModelConfigPagedGet$Plain(params?: ApiModelConfigPagedGet$Plain$Params, context?: HttpContext): Observable<Array<ModelConfigurationDtoPagedResult>> {
    return this.apiModelConfigPagedGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ModelConfigurationDtoPagedResult>>): Array<ModelConfigurationDtoPagedResult> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiModelConfigPagedGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiModelConfigPagedGet$Json$Response(params?: ApiModelConfigPagedGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ModelConfigurationDtoPagedResult>>> {
    return apiModelConfigPagedGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiModelConfigPagedGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiModelConfigPagedGet$Json(params?: ApiModelConfigPagedGet$Json$Params, context?: HttpContext): Observable<Array<ModelConfigurationDtoPagedResult>> {
    return this.apiModelConfigPagedGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ModelConfigurationDtoPagedResult>>): Array<ModelConfigurationDtoPagedResult> => r.body)
    );
  }

  /** Path part for operation `apiModelConfigPost()` */
  static readonly ApiModelConfigPostPath = '/api/model/config';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiModelConfigPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiModelConfigPost$Plain$Response(params?: ApiModelConfigPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelConfigurationDto>> {
    return apiModelConfigPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiModelConfigPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiModelConfigPost$Plain(params?: ApiModelConfigPost$Plain$Params, context?: HttpContext): Observable<ModelConfigurationDto> {
    return this.apiModelConfigPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelConfigurationDto>): ModelConfigurationDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiModelConfigPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiModelConfigPost$Json$Response(params?: ApiModelConfigPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ModelConfigurationDto>> {
    return apiModelConfigPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiModelConfigPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiModelConfigPost$Json(params?: ApiModelConfigPost$Json$Params, context?: HttpContext): Observable<ModelConfigurationDto> {
    return this.apiModelConfigPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ModelConfigurationDto>): ModelConfigurationDto => r.body)
    );
  }

}
