<aria-onboarding-wrapper title="Sign up" subtitle="Create an account and continue" [showFooter]="true"
    [showBack]="false">
    <div body id="signup-form" class="w-full flex items-center flex-col">
        <form [formGroup]="signupFormGroup" (ngSubmit)="submit()"
            class="flex flex-col text-sm text-black mx-5 items-center w-[300px] sm:w-[260px]">
            <aria-text-input class="w-full" placeholder="First name" name="First name" [formControl]="firstName"
                id="aria-first-name-input">
            </aria-text-input>
            <aria-text-input class="w-full" placeholder="Last name" name="Last name" [formControl]="lastName"
                id="aria-last-name-input">
            </aria-text-input>
            <aria-text-input class="w-full" placeholder="Email" type="email" name="Email" [formControl]="email"
                id="aria-email-input">
            </aria-text-input>
            <aria-text-input class="w-full" placeholder="Password" type="password" [formControl]="password"
                name="Password" id="aria-password-input" [showError]="false">
            </aria-text-input>

            <div class="w-full flex items-start justify-start">
                <p class="onboarding-text-error text-[10px] ml-4"
                    *ngIf="serverErrorVM && email.value && password.value">
                    {{ serverErrorVM }}
                </p>
            </div>

            <aria-password-error class="w-full" [control]="password"></aria-password-error>


            <div class="pb-[25px] mt-2 px-2 flex flex-row">
                <aria-check-box (valueEmitted)="checkTermsAndConditions()"></aria-check-box>

                <div>
                    <p class="text-[10px] font-marfa-light leading-3 text-start max-w-52"
                        [class.text-[#F3014A]]="showTermsAndConditionError">
                        By creating an account you agree to our <a href="https://www.nkoda.com/legal/end-user-agreement"
                            class="font-marfa">Terms of
                            Service</a>
                        and <a href="https://www.nkoda.com/legal/privacy-policy" class="font-marfa">Privacy Policy</a>
                    </p>
                </div>
            </div>

            <aria-submit-button class="w-full" text="Sign up" [loading]="requestInProgress"></aria-submit-button>
        </form>

        <div id="signup-socials" class="my-6">
            <p class="flex justify-center text-center mb-4 text-sm">or continue with</p>
            <aria-provider></aria-provider>
        </div>
    </div>
    <div footer
        class="text-sm flex flex-row max-w-[576px] min-w-[340px] sm:w-[576px] justify-center h-[60px] items-center bg-[#333] text-white rounded-b-[30px]">
        <p>Already have an account?</p>
        <a routerLink="/login" class="ml-3">Sign in</a>
    </div>
</aria-onboarding-wrapper>