import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TextInputService {
  private _shakeInputSubject = new Subject();
  public shakeInputObs = this._shakeInputSubject.asObservable();

  constructor() {}

  shakeInput() {
    this._shakeInputSubject.next(null);
  }
}
