import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StripeService } from '../../api/aria/services';
import { AppRoutes } from '../../constants/appRoutes';
import { SettingsService } from '../../services/settings.service';
import { StringUtils } from '../../utils/string.utils';

@Component({
  selector: 'aria-pricing-callback',
  standalone: true,
  imports: [],
  templateUrl: './pricing-callback.component.html',
  styleUrl: './pricing-callback.component.scss',
})
export class PricingCallbackComponent implements OnInit {
  constructor(
    private _stripeService: StripeService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this._activatedRoute.queryParams.subscribe({
      next: (params) => {
        const sessionId = params['session_id'];

        if (!StringUtils.isNullOrEmpty(sessionId)) {
          this._stripeService
            .apiStripeSessionCheckoutSessionIdPut$Json({
              checkoutSessionId: sessionId,
            })
            .subscribe({
              next: (receipt) => {
                this._router.navigate([AppRoutes.Main]);

                if (StringUtils.isNullOrEmpty(receipt?.ariaProductId)) return;
                this._settingsService.setProductId(receipt.ariaProductId!);
              },
            });
        }
      },
    });
  }
}
