import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Ripple, initTWE } from 'tw-elements';

@Component({
  selector: 'aria-submit-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './submit-button.component.html',
  styleUrl: './submit-button.component.scss',
})
export class SubmitButtonComponent implements OnInit {
  @Input() text = '';
  @Input() id = 'submit-button';
  @Input() loading = false;
  @Input() bgColor = '#333';
  @Input() textColor = '#FFF';
  @Input() cssClasses = '';
  @Output() onButtonPressed = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {
    initTWE({ Ripple });
  }

  buttonPressed() {
    this.onButtonPressed.emit(true);
  }
}
