<ul class="pagination" *ngIf="nrOfPages && nrOfPages > 1">
    <li>
        <a class="pagination-step pagination-step-left cursor-pointer" [class.disabled]="activePage === 1"
            (click)="setPage(activePage - 1)">
            <div class="arrow arrow--left"></div>
        </a>
    </li>
    <li *ngFor="let pageNr of numbers" (click)="setPage(pageNr)">
        <a class="pagination-step cursor-pointer" [class.active]="pageNr === activePage">
            {{ pageNr }}
        </a>
    </li>
    <li>
        <a class="pagination-step pagination-step-right cursor-pointer" [class.disabled]="activePage === nrOfPages"
            (click)="setPage(activePage + 1)">
            <div class="arrow arrow--right"></div>
        </a>
    </li>
</ul>