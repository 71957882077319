export enum WebSocketState {
  /**
   * Reserved for future use.
   */
  None,

  /**
   * The connection is negotiating the handshake with the remote endpoint.
   */
  Connecting,

  /**
   * The initial state after the HTTP handshake has been completed.
   */
  Open,

  /**
   * A close message was sent to the remote endpoint.
   */
  CloseSent,

  /**
   * A close message was received from the remote endpoint.
   */
  CloseReceived,

  /**
   * Indicates the WebSocket close handshake completed gracefully.
   */
  Closed,

  /**
   * Indicates that the WebSocket has been aborted.
   */
  Aborted,
}
