import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { UserInfoModel } from '../../models/user-info-model';

@Component({
  selector: 'aria-user-message',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sender.component.html',
  styleUrl: './sender.component.scss',
})
export class UserMessageComponent {
  @Input() message!: string;
  @Input() user!: UserInfoModel;
}
