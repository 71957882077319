/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiUserGet$Json } from '../fn/user/api-user-get-json';
import { ApiUserGet$Json$Params } from '../fn/user/api-user-get-json';
import { apiUserGet$Plain } from '../fn/user/api-user-get-plain';
import { ApiUserGet$Plain$Params } from '../fn/user/api-user-get-plain';
import { apiUserResearcherSettingModelModelIdCategoryCategoryIdGet$Json } from '../fn/user/api-user-researcher-setting-model-model-id-category-category-id-get-json';
import { ApiUserResearcherSettingModelModelIdCategoryCategoryIdGet$Json$Params } from '../fn/user/api-user-researcher-setting-model-model-id-category-category-id-get-json';
import { apiUserResearcherSettingModelModelIdCategoryCategoryIdGet$Plain } from '../fn/user/api-user-researcher-setting-model-model-id-category-category-id-get-plain';
import { ApiUserResearcherSettingModelModelIdCategoryCategoryIdGet$Plain$Params } from '../fn/user/api-user-researcher-setting-model-model-id-category-category-id-get-plain';
import { apiUserResearcherSettingsPost$Json } from '../fn/user/api-user-researcher-settings-post-json';
import { ApiUserResearcherSettingsPost$Json$Params } from '../fn/user/api-user-researcher-settings-post-json';
import { apiUserResearcherSettingsPost$Plain } from '../fn/user/api-user-researcher-settings-post-plain';
import { ApiUserResearcherSettingsPost$Plain$Params } from '../fn/user/api-user-researcher-settings-post-plain';
import { apiUserSettingsGet$Json } from '../fn/user/api-user-settings-get-json';
import { ApiUserSettingsGet$Json$Params } from '../fn/user/api-user-settings-get-json';
import { apiUserSettingsGet$Plain } from '../fn/user/api-user-settings-get-plain';
import { ApiUserSettingsGet$Plain$Params } from '../fn/user/api-user-settings-get-plain';
import { apiUserSettingsPost$Json } from '../fn/user/api-user-settings-post-json';
import { ApiUserSettingsPost$Json$Params } from '../fn/user/api-user-settings-post-json';
import { apiUserSettingsPost$Plain } from '../fn/user/api-user-settings-post-plain';
import { ApiUserSettingsPost$Plain$Params } from '../fn/user/api-user-settings-post-plain';
import { apiUserSettingsUserIdentityIdPost$Json } from '../fn/user/api-user-settings-user-identity-id-post-json';
import { ApiUserSettingsUserIdentityIdPost$Json$Params } from '../fn/user/api-user-settings-user-identity-id-post-json';
import { apiUserSettingsUserIdentityIdPost$Plain } from '../fn/user/api-user-settings-user-identity-id-post-plain';
import { ApiUserSettingsUserIdentityIdPost$Plain$Params } from '../fn/user/api-user-settings-user-identity-id-post-plain';
import { ResearcherSettingsDto } from '../models/researcher-settings-dto';
import { UserProfileDto } from '../models/user-profile-dto';
import { UserSettingsDto } from '../models/user-settings-dto';

@Injectable({ providedIn: 'root' })
export class UserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiUserGet()` */
  static readonly ApiUserGetPath = '/api/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserGet$Plain$Response(params?: ApiUserGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserProfileDto>> {
    return apiUserGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUserGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserGet$Plain(params?: ApiUserGet$Plain$Params, context?: HttpContext): Observable<UserProfileDto> {
    return this.apiUserGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserProfileDto>): UserProfileDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserGet$Json$Response(params?: ApiUserGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserProfileDto>> {
    return apiUserGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUserGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserGet$Json(params?: ApiUserGet$Json$Params, context?: HttpContext): Observable<UserProfileDto> {
    return this.apiUserGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserProfileDto>): UserProfileDto => r.body)
    );
  }

  /** Path part for operation `apiUserSettingsGet()` */
  static readonly ApiUserSettingsGetPath = '/api/user/settings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserSettingsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserSettingsGet$Plain$Response(params?: ApiUserSettingsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserSettingsDto>> {
    return apiUserSettingsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUserSettingsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserSettingsGet$Plain(params?: ApiUserSettingsGet$Plain$Params, context?: HttpContext): Observable<UserSettingsDto> {
    return this.apiUserSettingsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserSettingsDto>): UserSettingsDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserSettingsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserSettingsGet$Json$Response(params?: ApiUserSettingsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserSettingsDto>> {
    return apiUserSettingsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUserSettingsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserSettingsGet$Json(params?: ApiUserSettingsGet$Json$Params, context?: HttpContext): Observable<UserSettingsDto> {
    return this.apiUserSettingsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserSettingsDto>): UserSettingsDto => r.body)
    );
  }

  /** Path part for operation `apiUserSettingsPost()` */
  static readonly ApiUserSettingsPostPath = '/api/user/settings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserSettingsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserSettingsPost$Plain$Response(params?: ApiUserSettingsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserSettingsDto>> {
    return apiUserSettingsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUserSettingsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserSettingsPost$Plain(params?: ApiUserSettingsPost$Plain$Params, context?: HttpContext): Observable<UserSettingsDto> {
    return this.apiUserSettingsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserSettingsDto>): UserSettingsDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserSettingsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserSettingsPost$Json$Response(params?: ApiUserSettingsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserSettingsDto>> {
    return apiUserSettingsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUserSettingsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserSettingsPost$Json(params?: ApiUserSettingsPost$Json$Params, context?: HttpContext): Observable<UserSettingsDto> {
    return this.apiUserSettingsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserSettingsDto>): UserSettingsDto => r.body)
    );
  }

  /** Path part for operation `apiUserSettingsUserIdentityIdPost()` */
  static readonly ApiUserSettingsUserIdentityIdPostPath = '/api/user/settings/{userIdentityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserSettingsUserIdentityIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserSettingsUserIdentityIdPost$Plain$Response(params: ApiUserSettingsUserIdentityIdPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserSettingsDto>> {
    return apiUserSettingsUserIdentityIdPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUserSettingsUserIdentityIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserSettingsUserIdentityIdPost$Plain(params: ApiUserSettingsUserIdentityIdPost$Plain$Params, context?: HttpContext): Observable<UserSettingsDto> {
    return this.apiUserSettingsUserIdentityIdPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserSettingsDto>): UserSettingsDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserSettingsUserIdentityIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserSettingsUserIdentityIdPost$Json$Response(params: ApiUserSettingsUserIdentityIdPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserSettingsDto>> {
    return apiUserSettingsUserIdentityIdPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUserSettingsUserIdentityIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserSettingsUserIdentityIdPost$Json(params: ApiUserSettingsUserIdentityIdPost$Json$Params, context?: HttpContext): Observable<UserSettingsDto> {
    return this.apiUserSettingsUserIdentityIdPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserSettingsDto>): UserSettingsDto => r.body)
    );
  }

  /** Path part for operation `apiUserResearcherSettingsPost()` */
  static readonly ApiUserResearcherSettingsPostPath = '/api/user/researcher/settings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserResearcherSettingsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserResearcherSettingsPost$Plain$Response(params?: ApiUserResearcherSettingsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ResearcherSettingsDto>> {
    return apiUserResearcherSettingsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUserResearcherSettingsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserResearcherSettingsPost$Plain(params?: ApiUserResearcherSettingsPost$Plain$Params, context?: HttpContext): Observable<ResearcherSettingsDto> {
    return this.apiUserResearcherSettingsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResearcherSettingsDto>): ResearcherSettingsDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserResearcherSettingsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserResearcherSettingsPost$Json$Response(params?: ApiUserResearcherSettingsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ResearcherSettingsDto>> {
    return apiUserResearcherSettingsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUserResearcherSettingsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserResearcherSettingsPost$Json(params?: ApiUserResearcherSettingsPost$Json$Params, context?: HttpContext): Observable<ResearcherSettingsDto> {
    return this.apiUserResearcherSettingsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResearcherSettingsDto>): ResearcherSettingsDto => r.body)
    );
  }

  /** Path part for operation `apiUserResearcherSettingModelModelIdCategoryCategoryIdGet()` */
  static readonly ApiUserResearcherSettingModelModelIdCategoryCategoryIdGetPath = '/api/user/researcher/setting/model/{modelId}/category/{categoryId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserResearcherSettingModelModelIdCategoryCategoryIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserResearcherSettingModelModelIdCategoryCategoryIdGet$Plain$Response(params: ApiUserResearcherSettingModelModelIdCategoryCategoryIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ResearcherSettingsDto>> {
    return apiUserResearcherSettingModelModelIdCategoryCategoryIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUserResearcherSettingModelModelIdCategoryCategoryIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserResearcherSettingModelModelIdCategoryCategoryIdGet$Plain(params: ApiUserResearcherSettingModelModelIdCategoryCategoryIdGet$Plain$Params, context?: HttpContext): Observable<ResearcherSettingsDto> {
    return this.apiUserResearcherSettingModelModelIdCategoryCategoryIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResearcherSettingsDto>): ResearcherSettingsDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserResearcherSettingModelModelIdCategoryCategoryIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserResearcherSettingModelModelIdCategoryCategoryIdGet$Json$Response(params: ApiUserResearcherSettingModelModelIdCategoryCategoryIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ResearcherSettingsDto>> {
    return apiUserResearcherSettingModelModelIdCategoryCategoryIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUserResearcherSettingModelModelIdCategoryCategoryIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserResearcherSettingModelModelIdCategoryCategoryIdGet$Json(params: ApiUserResearcherSettingModelModelIdCategoryCategoryIdGet$Json$Params, context?: HttpContext): Observable<ResearcherSettingsDto> {
    return this.apiUserResearcherSettingModelModelIdCategoryCategoryIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResearcherSettingsDto>): ResearcherSettingsDto => r.body)
    );
  }

}
