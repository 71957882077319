import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppTheme, Theme } from '../constants/themeConstans';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private _themeSubject = new BehaviorSubject<string>(localStorage.getItem(Theme) ?? AppTheme.White);
  public _themeObs = this._themeSubject.asObservable();

  constructor() {}

  switchTheme() {
    if (document.documentElement.classList.contains(AppTheme.Dark)) {
      document.documentElement.classList.remove(AppTheme.Dark);
      localStorage.removeItem(Theme);
    } else {
      document.documentElement.classList.add(AppTheme.Dark);
      localStorage.setItem(Theme, AppTheme.Dark);
    }

    const appTheme = localStorage.getItem(Theme) ?? AppTheme.White;

    this._themeSubject.next(appTheme);
    localStorage.setItem(Theme, appTheme);
  }
}
