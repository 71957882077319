import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ModelConfigDisplayDto, ProductDto } from '../../api/aria/models';
import { SettingsService } from '../../services/settings.service';

@Component({
  selector: 'aria-predefined-questions',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './predefined-questions.component.html',
  styleUrl: './predefined-questions.component.scss',
})
export class PredefinedQuestionsComponent implements OnInit, OnDestroy {
  selectedProduct!: ProductDto;
  selectedCategoryId!: string;
  currentPredefinedQuestions: Array<string> = [];

  @Output() predefinedQuestionClicked = new EventEmitter<string>();

  private _unsubscribeObs$ = new Subject();

  constructor(private _settingsService: SettingsService) {}

  ngOnInit(): void {
    this._settingsService.userSettingsObs$.pipe(takeUntil(this._unsubscribeObs$)).subscribe({
      next: (settings) => {
        if (settings?.modelConfigId) {
          this.selectedCategoryId = settings.modelConfigId!;
        }

        const modelConfig = this.selectedProduct?.modelConfigsData?.find((x) => x.id === settings.modelConfigId);
        this.setPredefinedQuestions(modelConfig);
      },
    });

    this._settingsService.selectedModelConfigObs$.pipe(takeUntil(this._unsubscribeObs$)).subscribe({
      next: (modelConfig) => {
        this.setPredefinedQuestions(modelConfig);
      },
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeObs$.complete();
  }

  setPredefinedQuestions(modelConfig: ModelConfigDisplayDto | undefined) {
    if (!modelConfig || !modelConfig.predefinedQuestions) {
      this.currentPredefinedQuestions = [];
      return;
    }

    this.currentPredefinedQuestions = modelConfig.predefinedQuestions;
  }

  predefinedQuestionClick(question: string) {
    this.predefinedQuestionClicked.emit(question);
  }
}
