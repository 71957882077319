import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import player from 'lottie-web';
import { provideLottieOptions } from 'ngx-lottie';
import { provideMarkdown } from 'ngx-markdown';
import { ApiModule as AriaApiModule } from './api/aria/api.module';
import { routes } from './app.routes';
import { environment } from './environments/environment';
import { BearerInterceptor } from './interceptors/bearer.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';

export const HttpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: BearerInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
];

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    provideOAuthClient({
      resourceServer: {
        allowedUrls: [''],
        sendAccessToken: true,
      },
    }),
    provideAngularSvgIcon(),
    provideMarkdown({}),
    provideLottieOptions({
      player: () => player,
    }),
    AriaApiModule.forRoot({ rootUrl: environment.apiBaseUrl }).providers!,
  ],
};
