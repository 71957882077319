<div class="bg-token-main-surface-primary flex flex-col gap-3 px-4 pb-1 text-sm text-token-text-primary 
    sm:px-6 sm:pb-2 md:pl-0 md:pt-5 w-full h-full overflow-auto">
    <button (click)="closeDialog()"
        class="absolute right-10 top-10 text-token-text-tertiary hover:text-token-text-secondary">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.34315 6.34338L17.6569 17.6571M17.6569 6.34338L6.34315 17.6571" stroke="currentColor"
                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
    </button>

    <div class="px-10 py-4 prose w-full">
        <div *ngIf="researcherHistoryDto?.systemPrompt">
            <h1>System Prompt</h1>
            <p class="w-full">{{researcherHistoryDto.systemPrompt}}</p>
        </div>

        <div *ngIf="researcherHistoryDto?.embeddings?.length">
            <h1>Embedded data</h1>
            <div class="w-full" *ngFor="let item of researcherHistoryDto.embeddings">
                <p>{{ item }}</p>
            </div>
        </div>

        <div *ngIf="researcherHistoryDto?.userHistory?.length">
            <h1>User History</h1>
            <div class="w-full" *ngFor="let item of researcherHistoryDto.userHistory">
                <p>{{ item }}</p>
            </div>
        </div>

    </div>
</div>