/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CategoryDtoPagedResult } from '../../models/category-dto-paged-result';
import { SortDirection } from '../../models/sort-direction';

export interface ApiCategoryPagedGet$Plain$Params {
  PageSize?: number;
  PageNumber?: number;
  Filter?: string;
  FilterFields?: Array<string>;
  SortField?: string;
  SortOrder?: SortDirection;
  ContinuationToken?: string;
}

export function apiCategoryPagedGet$Plain(http: HttpClient, rootUrl: string, params?: ApiCategoryPagedGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CategoryDtoPagedResult>>> {
  const rb = new RequestBuilder(rootUrl, apiCategoryPagedGet$Plain.PATH, 'get');
  if (params) {
    rb.query('PageSize', params.PageSize, {"style":"form"});
    rb.query('PageNumber', params.PageNumber, {"style":"form"});
    rb.query('Filter', params.Filter, {"style":"form"});
    rb.query('FilterFields', params.FilterFields, {"style":"form"});
    rb.query('SortField', params.SortField, {"style":"form"});
    rb.query('SortOrder', params.SortOrder, {"style":"form"});
    rb.query('ContinuationToken', params.ContinuationToken, {"style":"form"});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<CategoryDtoPagedResult>>;
    })
  );
}

apiCategoryPagedGet$Plain.PATH = '/api/category/paged';
