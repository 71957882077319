<div class="pt-4 pb-2">
    <div *ngFor="let item of editions; let i = index">
        <div *ngIf="item" class="flex flex-row justify-between w-full mb-3 pb-3"
            [class]="editions.length - 1 !== i ? 'border-[#8b8b8b] border-b-[1px]' : ''">
            <div>
                <a class="rounded-xl" target="_blank"
                    [href]="'https://app.nkoda.com/work?ref=' + item?.link?.resourceRef">
                    <img [src]="item?.cover?.url" class="w-28 rounded-xl">
                </a>
            </div>

            <div class="w-full flex justify-between pl-4">
                <div class="flex flex-col justify-between h-full">
                    <div>
                        <div class="flex flex-col">
                            <a class="text-xs cursor-pointer"
                                [href]="'https://app.nkoda.com/work?ref=' + item?.link?.resourceRef" target="_blank">
                                {{ item?.title }}
                            </a>
                            <a class="text-[11px] text-[#8b8b8b]">{{ item?.subTitle }}</a>

                            <a class="text-xs cursor-pointer text-[#8b8b8b]"
                                [href]="'https://app.nkoda.com/artist?ref=' + item?.artist?.id"
                                target="_blank">{{item.artist?.name}}</a>
                        </div>
                    </div>

                    <div>
                        <div class="flex flex-col">
                            <a class="text-xs text-[#8b8b8b]">{{ item?.instrumentation }}</a>
                            <a class="text-xs" target="_blank"
                                [href]="'https://app.nkoda.com/publisher?ref=' + item?.publisher?.id">{{
                                item?.publisher?.name }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>