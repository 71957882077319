import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'aria-check-box',
  standalone: true,
  imports: [],
  templateUrl: './check-box.component.html',
  styleUrl: './check-box.component.scss',
})
export class CheckBoxComponent {
  @Output() valueEmitted = new EventEmitter<Event>();
}
