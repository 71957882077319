<div class="font-marfa h-svh bg-black flex justify-between flex-col">
    <div class="flex flex-col items-center bg-black mb-[-1px]">
        <div class="flex flex-row align-middle items-center mt-[30px]">
            <div class="bg-white w-12 h-12 rounded-2xl p-1 flex items-center justify-center">
                <img class="fill-black w-9 h-9" src="../../../assets/icon-lg.svg" />
            </div>
            <div class="text-[18px] ml-3.5 text-white">ARIA-I</div>
        </div>

        <div
            class="relative mt-[55px] rounded-[30px] max-w-[576px] 2xs:max-w-[320px] xs:max-w-[576px] sm:w-[576px] bg-white text-black">
            <a class="absolute xs:left-4 xs:top-4 sm:left-8 sm:top-8 flex flex-row cursor-pointer" (click)="back()">
                <img src="/assets/icons/btns/arrow-left.svg" alt="" />
                <span class="ml-2">Back</span>
            </a>

            <div class="pb-5 px-10">
                <div class="xs:mt-6 sm:mt-10">
                    <div class="items-center flex-col flex w-full" id="magic-link-header">
                        <h1 class="sm:text-[36px] mt-[30px] font-[800] xs:leading-5 leading-9 xs:text-[24px]">
                            Institution Access
                        </h1>
                        <h3 class="sm:text-sm font-[800] xs:leading-5 leading-9 xs:text-[12px] xs:mt-0 sm:mt-8">
                            You've been identified as member of nkoda university MD, would you like to proceed as one of
                            this institution members or would you like to use your own subscription?</h3>
                    </div>

                    <div class="flex flex-col items-end justify-end mt-6 text-sm">
                        <button class="text-black  p-3 rounded-[10px]" (click)="declineInstitutionAccess()">Use my own
                            subscription</button>
                        <button class="text-white bg-black p-3 rounded-[10px]"
                            (click)="acceptInstitutionAccess()">Access through nkoda university
                            MD</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>