/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiHistoryMessageMessageIdGet$Json } from '../fn/history/api-history-message-message-id-get-json';
import { ApiHistoryMessageMessageIdGet$Json$Params } from '../fn/history/api-history-message-message-id-get-json';
import { apiHistoryMessageMessageIdGet$Plain } from '../fn/history/api-history-message-message-id-get-plain';
import { ApiHistoryMessageMessageIdGet$Plain$Params } from '../fn/history/api-history-message-message-id-get-plain';
import { ResearcherHistoryDto } from '../models/researcher-history-dto';

@Injectable({ providedIn: 'root' })
export class HistoryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiHistoryMessageMessageIdGet()` */
  static readonly ApiHistoryMessageMessageIdGetPath = '/api/history/message/{messageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHistoryMessageMessageIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHistoryMessageMessageIdGet$Plain$Response(params: ApiHistoryMessageMessageIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ResearcherHistoryDto>> {
    return apiHistoryMessageMessageIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHistoryMessageMessageIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHistoryMessageMessageIdGet$Plain(params: ApiHistoryMessageMessageIdGet$Plain$Params, context?: HttpContext): Observable<ResearcherHistoryDto> {
    return this.apiHistoryMessageMessageIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResearcherHistoryDto>): ResearcherHistoryDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHistoryMessageMessageIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHistoryMessageMessageIdGet$Json$Response(params: ApiHistoryMessageMessageIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ResearcherHistoryDto>> {
    return apiHistoryMessageMessageIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHistoryMessageMessageIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHistoryMessageMessageIdGet$Json(params: ApiHistoryMessageMessageIdGet$Json$Params, context?: HttpContext): Observable<ResearcherHistoryDto> {
    return this.apiHistoryMessageMessageIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResearcherHistoryDto>): ResearcherHistoryDto => r.body)
    );
  }

}
