import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'aria-landing',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.scss',
})
export class LandingComponent implements OnInit {
  isDesktop!: boolean;

  constructor() {}

  ngOnInit(): void {
    this.isDesktop = window.innerWidth > 768;
  }

  @HostListener('window:resize')
  @HostListener('window:orientationchange')
  onResize() {
    this.isDesktop = window.innerWidth > 768;
  }
}
