import { Component, OnInit } from '@angular/core';
import { environment } from './environments/environment';
import { ThemeService } from './services/theme.service';

declare global {
  interface Window {
    oaAppId: string;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  standalone: false,
})
export class AppComponent implements OnInit {
  constructor(private _themeService: ThemeService) {}

  ngOnInit(): void {
    window.oaAppId = environment.oaAppId;
  }
}
