import { CommonModule, Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'aria-onboarding-wrapper',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './onboarding-wrapper.component.html',
  styleUrl: './onboarding-wrapper.component.scss',
})
export class OnboardingWrapperComponent {
  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() showBack!: boolean;
  @Input() showFooter!: boolean;

  constructor(private _location: Location) {}

  goBack() {
    this._location.back();
  }
}
