<div *ngIf="control && control.errors && (control.value || control?.touched)"
    class=" rounded-xl font-marfa-light text-[12px] w-full leading-4 onboarding-bg-tertiary py-2 px-4 mt-1">
    <p>Please ensure your password is</p>
    <div class="flex flex-row justify-between">
        <span>&nbsp;&#x2022; at least 8 characters long;</span>
        <img [class.filter-white]="control.errors['minLength'] === undefined && !control.errors['required']"
            src="assets/icons/btns/check-mark.svg" alt="">
    </div>

    <div class="flex flex-row justify-between">
        <span>&nbsp;&#x2022; upper case letters;</span>
        <img [class.filter-white]="control.errors['upperCase'] === undefined && !control.errors['required']"
            src="assets/icons/btns/check-mark.svg" alt="">
    </div>

    <div class="flex flex-row justify-between">
        <span>&nbsp;&#x2022; lower case letters;</span>
        <img [class.filter-white]="control.errors['lowerCase'] === undefined && !control.errors['required']"
            src="assets/icons/btns/check-mark.svg" alt="">
    </div>

    <div class="flex flex-row justify-between">
        <span>&nbsp;&#x2022; a number;</span>
        <img [class.filter-white]="control.errors['includeNumber'] === undefined && !control.errors['required']"
            src="assets/icons/btns/check-mark.svg" alt="">
    </div>

    <div class="flex flex-row justify-between">
        <span>&nbsp;&#x2022; and a special character;</span>
        <img [class.filter-white]="control.errors['includeSpecial'] === undefined && !control.errors['required']"
            src="assets/icons/btns/check-mark.svg" alt="">
    </div>
</div>