<div class="flex-grow overflow-y-auto h-full">
    <div class="relative grid grid-cols-[1fr_auto_1fr] px-6 py-4 md:pb-10 md:pt-[4.5rem]">
        <div></div><span class="text-2xl font-semibold md:text-3xl">Manage your plan</span><button
            (click)="closeModal()"
            class="justify-self-end text-token-text-primary opacity-50 transition hover:opacity-75 md:absolute md:right-6 md:top-6 "><svg
                xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"
                class="icon-lg">
                <path fill="currentColor" fill-rule="evenodd"
                    d="M5.636 5.636a1 1 0 0 1 1.414 0l4.95 4.95 4.95-4.95a1 1 0 0 1 1.414 1.414L13.414 12l4.95 4.95a1 1 0 0 1-1.414 1.414L12 13.414l-4.95 4.95a1 1 0 0 1-1.414-1.414l4.95-4.95-4.95-4.95a1 1 0 0 1 0-1.414"
                    clip-rule="evenodd"></path>
            </svg>
        </button>
    </div>

    <div class="mb-6 flex justify-center">
        <div class="w-[fit-content]">
            <div role="group" dir="ltr" aria-label="Toggle for switching between Personal and Business plans"
                class="cursor-pointer select-none rounded-full border border-solid border-token-border-light p-0.5"
                tabindex="0" style="outline: none;">
                <div class="relative flex flex-row">
                    <div class="relative z-10 px-3 py-1.5 text-center text-sm font-semibold"
                        *ngFor="let item of products; let i = index">
                        <button (click)="selectProduct(item.id!)" type="button" data-state="on" role="radio"
                            aria-checked="true" aria-label="Toggle for switching to Personal plans"
                            [class]="selectedProduct.id === item.id ? 'text-token-main-surface-primary dark:text-white' : ''">
                            {{ item.title }}
                        </button>

                        <div class="absolute inset-0 -z-10 rounded-full "
                            [class]="selectedProduct.id === item.id ? 'dark:bg-white bg-black' : ''"
                            style="opacity: 1;">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="flex flex-col justify-center gap-4 px-3 py-3 md:min-h-[30rem] md:flex-row md:gap-0 md:py-0">
        <div class="text-sm relative flex-1 flex gap-5 flex-col border-token-border-light border-t border-l border-r border-b rounded-xl md:min-h-[30rem] md:rounded-none md:border-r-0 md:last:border-r md:first:rounded-tl-xl md:first:rounded-bl-xl md:last:rounded-tr-xl md:last:rounded-br-xl md:max-w-96 justify-center px-6 pt-6 pb-10 md:pb-6"
            data-testid="plus-pricing-modal-column">
            <div class="relative flex flex-col">
                <div class="flex flex-col gap-1">
                    <div class="flex items-center gap-2 text-xl font-semibold">
                        <div
                            class="w-8 h-8 flex items-center justify-center rounded-full dark:bg-transparent bg-black pt-[2px]">
                            <svg-icon src="assets/icons/aria-logo-lg.svg"
                                [svgStyle]="{ 'width.px': 24, 'color': selectedProduct?.color, 'margin-top': '-3px' }">
                            </svg-icon>
                        </div>

                        {{selectedProduct?.title}}
                    </div>
                    <div class="flex items-baseline gap-1.5">
                        <p class="text-base text-token-text-tertiary" data-testid="plus-pricing-column-cost">
                            {{
                            selectedProduct?.price?.amount | currency : selectedProduct?.price?.currency! }}/{{
                            selectedProduct?.price?.interval }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="flex flex-col flex-grow gap-2" *ngIf="selectedProduct?.productInfo">
                <div class="relative bg-token-main-surface-primary" *ngFor="let item of selectedProduct.productInfo">
                    <div class="text-l flex justify-start gap-2"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" fill="none" viewBox="0 0 24 24" class="mt-0.5 h-4 w-4 shrink-0">
                            <path fill="currentColor" fill-rule="evenodd"
                                d="M18.063 5.674a1 1 0 0 1 .263 1.39l-7.5 11a1 1 0 0 1-1.533.143l-4.5-4.5a1 1 0 1 1 1.414-1.414l3.647 3.647 6.82-10.003a1 1 0 0 1 1.39-.263"
                                clip-rule="evenodd"></path>
                        </svg><span>{{item}}</span></div>
                </div>
            </div>
            <div class="relative flex flex-col bg-token-main-surface-primary text-xs text-token-text-secondary">
                <div *ngIf="selectedProduct?.id === currentOwnedProduct?.id" (click)="createCustomerPortalSession()">
                    <a class="px-2 underline cursor-pointer">
                        Manage my subscription
                    </a>
                </div>

                <div *ngIf="selectedProduct?.id !== currentOwnedProduct?.id" (click)="createCustomerPortalSession()">
                    <a class="px-2 underline cursor-pointer">
                        {{ upgradeDowngradeText }}
                    </a>

                </div>
                <div>
                    <a target="_blank" class="px-2 underline">
                        I need help with a billing issue
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div
        class="mb-12 mt-8 flex flex-col items-center justify-center gap-2 text-sm text-token-text-secondary md:mb-8 md:mt-8">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path fill="currentColor" fill-rule="evenodd"
                d="M7 19a1 1 0 0 1-1-1c0-1.692.812-2.982 1.983-3.815C9.124 13.373 10.589 13 12 13c1.41 0 2.876.373 4.017 1.185C17.188 15.018 18 16.308 18 18a1 1 0 0 1-1 1z"
                clip-rule="evenodd"></path>
            <path fill="currentColor"
                d="M19.5 18c0-1.944-.829-3.502-2.047-4.586A5.2 5.2 0 0 1 19.5 13c1.058 0 2.157.311 3.013.988.878.694 1.487 1.768 1.487 3.179 0 .46-.336.833-.75.833zM6.547 13.414C5.33 14.498 4.5 16.056 4.5 18H.75c-.414 0-.75-.373-.75-.833 0-1.41.609-2.486 1.487-3.18C2.343 13.312 3.442 13 4.5 13c.691 0 1.4.133 2.047.414M12 5a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7M4.5 7a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M19.5 7a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5">
            </path>
        </svg>
        <div class="flex flex-col items-center justify-center">Need more capabilities for your business?<div><a
                    target="_blank" class="mx-1 underline" href="mailto:contact@nkoda.com">contact&#x40;nkoda.com</a>
            </div>
        </div>
    </div>
</div>