import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { ProductDto, ReceiptDto } from '../api/aria/models';
import { ProductService, SubscriptionService } from '../api/aria/services';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionInfoService {
  private b2cProductsSubject = new BehaviorSubject<Array<ProductDto>>([]);
  b2cProductsObs$ = this.b2cProductsSubject.asObservable();

  private userProductsSubject = new BehaviorSubject<Array<ProductDto>>([]);
  userProductsObs$ = this.userProductsSubject.asObservable();

  private subscriptionsSubject = new BehaviorSubject<Array<ReceiptDto>>([]);
  subscriptionsObs$ = this.subscriptionsSubject.asObservable();

  constructor(private productService: ProductService, private subscriptionService: SubscriptionService) {}

  getB2CProducts(): Observable<Array<ProductDto>> {
    return this.productService.apiProductB2CGet$Json().pipe(
      tap((products) => {
        this.b2cProductsSubject.next(products);
      })
    );
  }

  getUserProducts(): Observable<Array<ProductDto>> {
    return this.productService.apiProductUserGet$Json().pipe(
      tap((products) => {
        this.userProductsSubject.next(products);
      })
    );
  }

  getUserSubscriptions(): Observable<Array<ReceiptDto>> {
    return this.subscriptionService.apiSubscriptionGet$Json().pipe(
      tap((subscriptions) => {
        this.subscriptionsSubject.next(subscriptions);
      })
    );
  }
}
