import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private _authService: AuthService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(req).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          this._authService.refreshToken().subscribe({
            next: (result) => {
              if (this._authService.isLoggedIn()) {
                return next.handle(req);
              }

              return this._authService.logout().subscribe();
            },
          });
        }

        // Re-throw the error to propagate it further
        return throwError(() => err);
      })
    );
  }
}
