import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { CheckBoxComponent } from '../../components/onboarding/check-box/check-box.component';
import { OnboardingWrapperComponent } from '../../components/onboarding/onboarding-wrapper/onboarding-wrapper.component';
import { SubmitButtonComponent } from '../../components/onboarding/submit-button/submit-button.component';
import { TextInputComponent } from '../../components/onboarding/text-input/text-input.component';
import { ProviderComponent } from '../../components/provider/provider.component';
import { AppRoutes } from '../../constants/appRoutes';
import { TextInputService } from '../../services/text-input.service';
import { AuthService } from './../../services/auth.service';

@Component({
  selector: 'aria-login',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    RouterModule,
    CommonModule,
    ProviderComponent,
    TextInputComponent,
    CheckBoxComponent,
    SubmitButtonComponent,
    OnboardingWrapperComponent,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit, OnDestroy {
  loginFormGroup!: FormGroup;
  appRoutes = AppRoutes;

  showValidationMessages: boolean = false;
  serverErrorVM: string = '';
  requestInProgress: boolean = false;

  private _unsubscribeObs$!: Subject<void>;
  private _shouldUsePersistentStorage = false;

  constructor(private _authService: AuthService, private _router: Router, private _textInput: TextInputService) {}

  ngOnInit(): void {
    this._unsubscribeObs$ = new Subject();
    this.loginFormGroup = new FormGroup({
      userName: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
    });

    this.password?.valueChanges.pipe(takeUntil(this._unsubscribeObs$)).subscribe(() => {
      if (this.serverErrorVM) {
        this.serverErrorVM = '';
      }
    });

    this.userName?.valueChanges.pipe(takeUntil(this._unsubscribeObs$)).subscribe(() => {
      if (this.serverErrorVM) {
        this.serverErrorVM = '';
      }
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeObs$.complete();
  }

  submit() {
    if (!this.loginFormGroup.valid) {
      this.showValidationMessages = true;
      this.loginFormGroup.markAllAsTouched();
      this._textInput.shakeInput();
      return;
    }

    const userName = this.loginFormGroup.get('userName')?.value;
    const password = this.loginFormGroup.get('password')?.value;
    this.requestInProgress = true;

    this._authService
      .loginCredentials(userName, password)
      .pipe(takeUntil(this._unsubscribeObs$))
      .subscribe({
        next: () => {
          this._router.navigate([AppRoutes.Main]);
        },
        error: (response: HttpErrorResponse) => {
          if (response.error?.error_description === 'invalid_username_or_password') {
            this.serverErrorVM = 'Invalid email or password, please try again.';
            this.showValidationMessages = true;
          }
        },
        complete: () => {
          this.showValidationMessages = false;
          this.loginFormGroup.reset();
        },
      })
      .add(() => {
        this.requestInProgress = false;
      });
  }

  saveCredentials(event: Event) {
    this._shouldUsePersistentStorage = (event.target as HTMLInputElement).checked;
    this._authService.saveCredentials(this._shouldUsePersistentStorage);
  }

  get userName() {
    return this.loginFormGroup.get('userName') as FormControl;
  }

  get password() {
    return this.loginFormGroup.get('password') as FormControl;
  }
}
