import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  public isConnectedSubject = new BehaviorSubject(true);

  constructor() {
    window.addEventListener('online', async () => {
      const networkStatus = await this.updateNetworkStatus();
      this.isConnectedSubject.next(networkStatus);
    });
    window.addEventListener('offline', async () => {
      const networkStatus = await this.updateNetworkStatus();
      this.isConnectedSubject.next(networkStatus);
    });

    setInterval(() => {
      this.updateNetworkStatus();
    }, 60000);
  }

  public get networkStatus() {
    return this.checkNetworkStatus();
  }

  public async updateNetworkStatus(): Promise<boolean> {
    const networkStatus = await this.checkNetworkStatus();
    if (networkStatus != this.isConnectedSubject.value) this.isConnectedSubject.next(networkStatus);
    return networkStatus;
  }

  private async checkNetworkStatus(): Promise<boolean> {
    try {
      const online = await fetch(`${environment.apiBaseUrl}/?q=` + new Date().getTime());
      return online.status >= 200 && online.status < 300;
    } catch (err) {
      return false;
    }
  }
}
