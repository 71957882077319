import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'aria-pagination',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss',
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() nrOfPages!: number;
  @Input() activePage!: number;
  @Input() shouldGoTop!: boolean;

  @Output() pageChangedEvent = new EventEmitter<number>();

  numbers!: Array<number>;

  ngOnInit(): void {
    this.numbers = this.getPageNumbers();
  }

  ngOnChanges() {
    this.numbers = this.getPageNumbers();
  }

  getPageNumbers() {
    const maxPages = Math.min(3, this.nrOfPages);
    const offset = this.activePage === this.nrOfPages ? -2 : -1;
    return Array.from({ length: maxPages }, (_, i) => {
      if (this.activePage === 1) {
        return this.activePage + i;
      }

      if (this.activePage === 2 && this.activePage === this.nrOfPages) {
        return this.activePage + i - 1;
      }

      return this.activePage + offset + i;
    });
  }

  setPage(pageNr: number) {
    this.pageChangedEvent.emit(pageNr);
  }
}
