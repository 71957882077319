<aria-onboarding-wrapper [showBack]="true" *ngIf="!emailSent; else emailSentSection" title="Forgot password?"
    subtitle="Don&#8217;t worry. Enter your account&#8217;s email address and we&#8217;ll send you a link to reset your password.">
    <div body>
        <div id="aria-forgot-password-form" class="w-full flex items-center flex-col">
            <form class="flex flex-col text-sm text-black mx-5 items-center w-[300px] sm:w-[240px]">
                <aria-text-input class="w-full" placeholder="Email" name="Email" [formControl]="fpControl"
                    [serverError]="serverErrorVM" id="aria-forgot-password-input">
                </aria-text-input>
            </form>

            <aria-submit-button [loading]="loading" textColor="#fff" (onButtonPressed)="resetPassword()"
                class="sm:w-[240px] mt-2 3xs:w-[300px]" text="Reset password"></aria-submit-button>
        </div>
    </div>
</aria-onboarding-wrapper>

<ng-template #emailSentSection>
    <aria-onboarding-wrapper>
        <div body>
            <div class="items-center flex-col flex w-full" id="aria-forgot-password-header">
                <p class="sm:text-[36px] font-[800] leading-9 xs:text-[24px]">Email on the way</p>
                <p class="text-[16px] font-thin mt-2 leading-5 max-w-[300px] text-center font-marfa-light">
                    Instructions for setting your password have been sent to
                    {{ fpControl.value }}
                </p>
                <p class="text-[16px] font-thin mt-6 mb-3 leading-5 max-w-[300px] text-center font-marfa-light">If you
                    don&#8217;t receive it right away, check your spam folder.</p>

                <aria-submit-button (onButtonPressed)="sendAgain()" bgColor="#F3F5F7" textColor="#000"
                    text="Send email again" class="sm:w-[240px] mt-2"></aria-submit-button>

                <aria-submit-button routerLink="/login" text="Return to login"
                    class="sm:w-[240px] mt-2"></aria-submit-button>
            </div>
        </div>
    </aria-onboarding-wrapper>
</ng-template>