import { Routes, mapToCanActivate } from '@angular/router';
import { AppRoutes } from './constants/appRoutes';
import { AuthGuard } from './guards/auth.guard';
import { LandingGuard } from './guards/landing.guard';
import { MainComponent } from './pages/chat/chat.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { InstitutionAccessComponent } from './pages/institution-access/institution-access.component';
import { InstitutionLoginComponent } from './pages/institution-login/institution-login.component';
import { LandingComponent } from './pages/landing/landing.component';
import { LoginComponent } from './pages/login/login.component';
import { MagicLinkComponent } from './pages/magic-link/magic-link.component';
import { PricingCallbackComponent } from './pages/pricing-callback/pricing-callback.component';
import { UserSettingsComponent } from './pages/settings/user-settings/user-settings.component';
import { SignupComponent } from './pages/signup/signup.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';

export const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    canActivate: mapToCanActivate([LandingGuard]),
  },
  {
    path: AppRoutes.Login,
    component: LoginComponent,
  },
  {
    path: AppRoutes.InstitutionLogin,
    component: InstitutionLoginComponent,
  },
  {
    path: AppRoutes.Register,
    component: SignupComponent,
  },
  {
    path: AppRoutes.MagicLink,
    component: MagicLinkComponent,
  },
  {
    path: AppRoutes.ForgotPassword,
    component: ForgotPasswordComponent,
  },
  {
    path: AppRoutes.InstitutionAccess,
    component: InstitutionAccessComponent,
  },
  {
    path: AppRoutes.Main,
    component: MainComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: `${AppRoutes.Login}/:id`,
    component: LoginComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: AppRoutes.Settings,
    component: UserSettingsComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  {
    path: AppRoutes.Unauthorized,
    component: UnauthorizedComponent,
  },
  {
    path: AppRoutes.PurchaseSuccess,
    component: PricingCallbackComponent,
  },
  {
    path: '**',
    component: MainComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
];
