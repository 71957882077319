import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Subject } from 'rxjs';
import { OnboardingWrapperComponent } from '../../components/onboarding/onboarding-wrapper/onboarding-wrapper.component';
import { SubmitButtonComponent } from '../../components/onboarding/submit-button/submit-button.component';
import { TextInputComponent } from '../../components/onboarding/text-input/text-input.component';
import { AuthService } from '../../services/auth.service';
import { TextInputService } from '../../services/text-input.service';

@Component({
  selector: 'aria-forgot-password',
  standalone: true,
  imports: [
    TextInputComponent,
    ReactiveFormsModule,
    RouterModule,
    CommonModule,
    SubmitButtonComponent,
    OnboardingWrapperComponent,
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  fpControl = new FormControl('', [Validators.required, Validators.email]);
  emailSent = false;
  serverErrorVM!: string;
  loading!: boolean;

  private _unsubscribe$ = new Subject<void>();

  constructor(private _authService: AuthService, private _textInputService: TextInputService) {}

  ngOnInit(): void {
    this._unsubscribe$ = new Subject();
  }

  ngOnDestroy(): void {
    this._unsubscribe$.complete();
  }

  sendAgain() {
    this.emailSent = !this.emailSent;
    this.fpControl.reset();
  }

  resetPassword() {
    this.serverErrorVM = '';
    if (!this.fpControl.valid || !this.fpControl.value) {
      this.fpControl.markAsTouched();
      this._textInputService.shakeInput();
      return;
    }

    this.loading = true;
    this._authService
      .resetPassword(this.fpControl.value)
      .subscribe({
        next: (response: boolean) => {
          if (response) {
            this.emailSent = true;
            return;
          }

          this.serverErrorVM = `Unexpected error ocurred, please try again.`;
        },
        error: (response: HttpErrorResponse) => {
          if (response.error?.message) {
            this.serverErrorVM = response.error?.message;
            return;
          }

          this.serverErrorVM = `Unexpected error ocurred, please try again.`;
        },
      })
      .add(() => {
        this.loading = false;
      });
  }
}
