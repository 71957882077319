import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private showSidebarSubject = new Subject<boolean>();
  public showSidebarObs = this.showSidebarSubject.asObservable();

  state = window.innerWidth <= 768;

  constructor() {}

  showHide() {
    this.state = !this.state;
    this.showSidebarSubject.next(this.state);
  }

  hide() {
    this.state = true;
    this.showSidebarSubject.next(this.state);
  }

  show() {
    this.state = false;
    this.showSidebarSubject.next(this.state);
  }
}
