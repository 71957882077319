import { Injectable } from '@angular/core';
import { loadStripe } from '@stripe/stripe-js';
import { forkJoin, from } from 'rxjs';
import { ProductDto } from '../api/aria/models';
import { StripeService } from '../api/aria/services';
import { AppRoutes } from '../constants/appRoutes';
import { environment } from '../environments/environment';
import { UserInfoModel } from '../models/user-info-model';

@Injectable({
  providedIn: 'root',
})
export class ClientStripeService {
  constructor(private _stripeService: StripeService) {}

  createCheckoutSession(plan: ProductDto, user: UserInfoModel) {
    const loadStripeObs$ = from(loadStripe(environment.stripePublicKey));
    const checkoutSessionObs$ = this._stripeService.apiStripeSessionPost$Json({
      body: {
        cancelUrl: `${location.origin}/${AppRoutes.Main}`,
        successUrl: `${location.origin}/${AppRoutes.PurchaseSuccess}?session_id={CHECKOUT_SESSION_ID}`,
        priceId: plan.price?.storePriceId,
        userEmail: user.email,
        productId: plan.id,
      },
    });

    return forkJoin([checkoutSessionObs$, loadStripeObs$]);
  }

  createCustomerPortalSession(transactionId: string) {
    return this._stripeService.apiStripeSessionCustomerPost$Json({
      body: {
        returnUrl: location.origin,
        subscriptionId: transactionId,
      },
    });
  }
}
