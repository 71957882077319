/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiMessageSessionIdPost$Json } from '../fn/message/api-message-session-id-post-json';
import { ApiMessageSessionIdPost$Json$Params } from '../fn/message/api-message-session-id-post-json';
import { apiMessageSessionIdPost$Plain } from '../fn/message/api-message-session-id-post-plain';
import { ApiMessageSessionIdPost$Plain$Params } from '../fn/message/api-message-session-id-post-plain';
import { UserMessageDto } from '../models/user-message-dto';

@Injectable({ providedIn: 'root' })
export class MessageService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMessageSessionIdPost()` */
  static readonly ApiMessageSessionIdPostPath = '/api/message/{sessionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMessageSessionIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessageSessionIdPost$Plain$Response(params: ApiMessageSessionIdPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserMessageDto>> {
    return apiMessageSessionIdPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMessageSessionIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessageSessionIdPost$Plain(params: ApiMessageSessionIdPost$Plain$Params, context?: HttpContext): Observable<UserMessageDto> {
    return this.apiMessageSessionIdPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserMessageDto>): UserMessageDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMessageSessionIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessageSessionIdPost$Json$Response(params: ApiMessageSessionIdPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserMessageDto>> {
    return apiMessageSessionIdPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMessageSessionIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessageSessionIdPost$Json(params: ApiMessageSessionIdPost$Json$Params, context?: HttpContext): Observable<UserMessageDto> {
    return this.apiMessageSessionIdPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserMessageDto>): UserMessageDto => r.body)
    );
  }

}
