import { CommonModule } from '@angular/common';
import { ApplicationRef, DoBootstrap, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AppComponent } from './app.component';
import { HttpInterceptorProviders, appConfig } from './app.config';
import { routes } from './app.routes';
import { environment } from './environments/environment';
import { AuthService } from './services/auth.service';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule.forRoot(routes),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.apiBaseUrl, environment.platformApiUrl, environment.identityServerBaseUrl],
        sendAccessToken: true,
      },
    }),
  ],
  declarations: [AppComponent],
  providers: [appConfig.providers, HttpInterceptorProviders],
})
export class AppModule implements DoBootstrap {
  constructor(private _authService: AuthService) {}

  ngDoBootstrap(application: ApplicationRef): void {
    this._authService.bootstrap().subscribe({
      next: () => {
        application.bootstrap(AppComponent);
      },
      error: () => {
        application.bootstrap(AppComponent);
      },
    });
  }
}
