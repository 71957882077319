<article class="flex flex-1 text-base mx-auto gap-3 md:px-5 lg:px-1 xl:px-5 max-w-[48rem] group final-completion">
  <div class="flex w-full flex-row-reverse gap-1 empty:hidden items-end rtl:items-start">
    <div class="rounded-3xl bg-[#f4f4f4] px-5 py-2.5 dark:bg-[#333333] max-w-[70%] min-h-[20px]">
      <div class="flex flex-row-reverse">{{ message }}</div>
    </div>
  </div>
  <div
    class="absolute bottom-0 right-full top-0 -mr-3.5 hidden pr-5 pt-1 [.group\/conversation-turn:hover_&amp;]:block">
    <span data-state="open">
      <button aria-label="Edit message"
        class="flex h-9 w-9 items-center justify-center rounded-full text-token-text-secondary transition hover:bg-token-main-surface-tertiary"><svg
          xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" class="icon-md">
          <path fill="currentColor" fill-rule="evenodd"
            d="M13.293 4.293a4.536 4.536 0 1 1 6.414 6.414l-1 1-7.094 7.094A5 5 0 0 1 8.9 20.197l-4.736.79a1 1 0 0 1-1.15-1.151l.789-4.736a5 5 0 0 1 1.396-2.713zM13 7.414l-6.386 6.387a3 3 0 0 0-.838 1.628l-.56 3.355 3.355-.56a3 3 0 0 0 1.628-.837L16.586 11zm5 2.172L14.414 6l.293-.293a2.536 2.536 0 0 1 3.586 3.586z"
            clip-rule="evenodd"></path>
        </svg>
      </button>
    </span>
  </div>
</article>

<ng-template #userCredentialsPicture>
  <div class="w-9 h-9 bg-yellow-400 rounded-full flex justify-center items-center">
    <span class="text-current text-[12px] font-semibold" *ngIf="user?.userInitials">{{
      user.userInitials
      }}</span>
  </div>
</ng-template>