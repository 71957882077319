/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiSubscriptionGet$Json } from '../fn/subscription/api-subscription-get-json';
import { ApiSubscriptionGet$Json$Params } from '../fn/subscription/api-subscription-get-json';
import { apiSubscriptionGet$Plain } from '../fn/subscription/api-subscription-get-plain';
import { ApiSubscriptionGet$Plain$Params } from '../fn/subscription/api-subscription-get-plain';
import { apiSubscriptionOwnerIdGet$Json } from '../fn/subscription/api-subscription-owner-id-get-json';
import { ApiSubscriptionOwnerIdGet$Json$Params } from '../fn/subscription/api-subscription-owner-id-get-json';
import { apiSubscriptionOwnerIdGet$Plain } from '../fn/subscription/api-subscription-owner-id-get-plain';
import { ApiSubscriptionOwnerIdGet$Plain$Params } from '../fn/subscription/api-subscription-owner-id-get-plain';
import { apiSubscriptionPost$Json } from '../fn/subscription/api-subscription-post-json';
import { ApiSubscriptionPost$Json$Params } from '../fn/subscription/api-subscription-post-json';
import { apiSubscriptionPost$Plain } from '../fn/subscription/api-subscription-post-plain';
import { ApiSubscriptionPost$Plain$Params } from '../fn/subscription/api-subscription-post-plain';
import { apiSubscriptionPut$Json } from '../fn/subscription/api-subscription-put-json';
import { ApiSubscriptionPut$Json$Params } from '../fn/subscription/api-subscription-put-json';
import { apiSubscriptionPut$Plain } from '../fn/subscription/api-subscription-put-plain';
import { ApiSubscriptionPut$Plain$Params } from '../fn/subscription/api-subscription-put-plain';
import { apiSubscriptionSubscriptionIdCancelPut$Json } from '../fn/subscription/api-subscription-subscription-id-cancel-put-json';
import { ApiSubscriptionSubscriptionIdCancelPut$Json$Params } from '../fn/subscription/api-subscription-subscription-id-cancel-put-json';
import { apiSubscriptionSubscriptionIdCancelPut$Plain } from '../fn/subscription/api-subscription-subscription-id-cancel-put-plain';
import { ApiSubscriptionSubscriptionIdCancelPut$Plain$Params } from '../fn/subscription/api-subscription-subscription-id-cancel-put-plain';
import { ReceiptDto } from '../models/receipt-dto';

@Injectable({ providedIn: 'root' })
export class SubscriptionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiSubscriptionGet()` */
  static readonly ApiSubscriptionGetPath = '/api/subscription';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSubscriptionGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubscriptionGet$Plain$Response(params?: ApiSubscriptionGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ReceiptDto>>> {
    return apiSubscriptionGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSubscriptionGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubscriptionGet$Plain(params?: ApiSubscriptionGet$Plain$Params, context?: HttpContext): Observable<Array<ReceiptDto>> {
    return this.apiSubscriptionGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ReceiptDto>>): Array<ReceiptDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSubscriptionGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubscriptionGet$Json$Response(params?: ApiSubscriptionGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ReceiptDto>>> {
    return apiSubscriptionGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSubscriptionGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubscriptionGet$Json(params?: ApiSubscriptionGet$Json$Params, context?: HttpContext): Observable<Array<ReceiptDto>> {
    return this.apiSubscriptionGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ReceiptDto>>): Array<ReceiptDto> => r.body)
    );
  }

  /** Path part for operation `apiSubscriptionPut()` */
  static readonly ApiSubscriptionPutPath = '/api/subscription';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSubscriptionPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSubscriptionPut$Plain$Response(params?: ApiSubscriptionPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceiptDto>> {
    return apiSubscriptionPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSubscriptionPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSubscriptionPut$Plain(params?: ApiSubscriptionPut$Plain$Params, context?: HttpContext): Observable<ReceiptDto> {
    return this.apiSubscriptionPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceiptDto>): ReceiptDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSubscriptionPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSubscriptionPut$Json$Response(params?: ApiSubscriptionPut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceiptDto>> {
    return apiSubscriptionPut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSubscriptionPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSubscriptionPut$Json(params?: ApiSubscriptionPut$Json$Params, context?: HttpContext): Observable<ReceiptDto> {
    return this.apiSubscriptionPut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceiptDto>): ReceiptDto => r.body)
    );
  }

  /** Path part for operation `apiSubscriptionPost()` */
  static readonly ApiSubscriptionPostPath = '/api/subscription';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSubscriptionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSubscriptionPost$Plain$Response(params?: ApiSubscriptionPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceiptDto>> {
    return apiSubscriptionPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSubscriptionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSubscriptionPost$Plain(params?: ApiSubscriptionPost$Plain$Params, context?: HttpContext): Observable<ReceiptDto> {
    return this.apiSubscriptionPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceiptDto>): ReceiptDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSubscriptionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSubscriptionPost$Json$Response(params?: ApiSubscriptionPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceiptDto>> {
    return apiSubscriptionPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSubscriptionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSubscriptionPost$Json(params?: ApiSubscriptionPost$Json$Params, context?: HttpContext): Observable<ReceiptDto> {
    return this.apiSubscriptionPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceiptDto>): ReceiptDto => r.body)
    );
  }

  /** Path part for operation `apiSubscriptionOwnerIdGet()` */
  static readonly ApiSubscriptionOwnerIdGetPath = '/api/subscription/{ownerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSubscriptionOwnerIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubscriptionOwnerIdGet$Plain$Response(params: ApiSubscriptionOwnerIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ReceiptDto>>> {
    return apiSubscriptionOwnerIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSubscriptionOwnerIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubscriptionOwnerIdGet$Plain(params: ApiSubscriptionOwnerIdGet$Plain$Params, context?: HttpContext): Observable<Array<ReceiptDto>> {
    return this.apiSubscriptionOwnerIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ReceiptDto>>): Array<ReceiptDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSubscriptionOwnerIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubscriptionOwnerIdGet$Json$Response(params: ApiSubscriptionOwnerIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ReceiptDto>>> {
    return apiSubscriptionOwnerIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSubscriptionOwnerIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSubscriptionOwnerIdGet$Json(params: ApiSubscriptionOwnerIdGet$Json$Params, context?: HttpContext): Observable<Array<ReceiptDto>> {
    return this.apiSubscriptionOwnerIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ReceiptDto>>): Array<ReceiptDto> => r.body)
    );
  }

  /** Path part for operation `apiSubscriptionSubscriptionIdCancelPut()` */
  static readonly ApiSubscriptionSubscriptionIdCancelPutPath = '/api/subscription/{subscriptionId}/cancel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSubscriptionSubscriptionIdCancelPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSubscriptionSubscriptionIdCancelPut$Plain$Response(params: ApiSubscriptionSubscriptionIdCancelPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceiptDto>> {
    return apiSubscriptionSubscriptionIdCancelPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSubscriptionSubscriptionIdCancelPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSubscriptionSubscriptionIdCancelPut$Plain(params: ApiSubscriptionSubscriptionIdCancelPut$Plain$Params, context?: HttpContext): Observable<ReceiptDto> {
    return this.apiSubscriptionSubscriptionIdCancelPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceiptDto>): ReceiptDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSubscriptionSubscriptionIdCancelPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSubscriptionSubscriptionIdCancelPut$Json$Response(params: ApiSubscriptionSubscriptionIdCancelPut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceiptDto>> {
    return apiSubscriptionSubscriptionIdCancelPut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSubscriptionSubscriptionIdCancelPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSubscriptionSubscriptionIdCancelPut$Json(params: ApiSubscriptionSubscriptionIdCancelPut$Json$Params, context?: HttpContext): Observable<ReceiptDto> {
    return this.apiSubscriptionSubscriptionIdCancelPut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceiptDto>): ReceiptDto => r.body)
    );
  }

}
