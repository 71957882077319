/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiSessionGet$Json } from '../fn/session/api-session-get-json';
import { ApiSessionGet$Json$Params } from '../fn/session/api-session-get-json';
import { apiSessionGet$Plain } from '../fn/session/api-session-get-plain';
import { ApiSessionGet$Plain$Params } from '../fn/session/api-session-get-plain';
import { apiSessionPost$Json } from '../fn/session/api-session-post-json';
import { ApiSessionPost$Json$Params } from '../fn/session/api-session-post-json';
import { apiSessionPost$Plain } from '../fn/session/api-session-post-plain';
import { ApiSessionPost$Plain$Params } from '../fn/session/api-session-post-plain';
import { apiSessionSessionIdDelete } from '../fn/session/api-session-session-id-delete';
import { ApiSessionSessionIdDelete$Params } from '../fn/session/api-session-session-id-delete';
import { apiSessionSessionIdGet$Json } from '../fn/session/api-session-session-id-get-json';
import { ApiSessionSessionIdGet$Json$Params } from '../fn/session/api-session-session-id-get-json';
import { apiSessionSessionIdGet$Plain } from '../fn/session/api-session-session-id-get-plain';
import { ApiSessionSessionIdGet$Plain$Params } from '../fn/session/api-session-session-id-get-plain';
import { apiSessionSessionIdMessagesGet$Json } from '../fn/session/api-session-session-id-messages-get-json';
import { ApiSessionSessionIdMessagesGet$Json$Params } from '../fn/session/api-session-session-id-messages-get-json';
import { apiSessionSessionIdMessagesGet$Plain } from '../fn/session/api-session-session-id-messages-get-plain';
import { ApiSessionSessionIdMessagesGet$Plain$Params } from '../fn/session/api-session-session-id-messages-get-plain';
import { UserMessageDto } from '../models/user-message-dto';
import { UserSessionDto } from '../models/user-session-dto';

@Injectable({ providedIn: 'root' })
export class SessionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiSessionGet()` */
  static readonly ApiSessionGetPath = '/api/session';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSessionGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSessionGet$Plain$Response(params?: ApiSessionGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserSessionDto>>> {
    return apiSessionGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSessionGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSessionGet$Plain(params?: ApiSessionGet$Plain$Params, context?: HttpContext): Observable<Array<UserSessionDto>> {
    return this.apiSessionGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserSessionDto>>): Array<UserSessionDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSessionGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSessionGet$Json$Response(params?: ApiSessionGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserSessionDto>>> {
    return apiSessionGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSessionGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSessionGet$Json(params?: ApiSessionGet$Json$Params, context?: HttpContext): Observable<Array<UserSessionDto>> {
    return this.apiSessionGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserSessionDto>>): Array<UserSessionDto> => r.body)
    );
  }

  /** Path part for operation `apiSessionPost()` */
  static readonly ApiSessionPostPath = '/api/session';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSessionPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSessionPost$Plain$Response(params?: ApiSessionPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserSessionDto>> {
    return apiSessionPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSessionPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSessionPost$Plain(params?: ApiSessionPost$Plain$Params, context?: HttpContext): Observable<UserSessionDto> {
    return this.apiSessionPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserSessionDto>): UserSessionDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSessionPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSessionPost$Json$Response(params?: ApiSessionPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserSessionDto>> {
    return apiSessionPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSessionPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSessionPost$Json(params?: ApiSessionPost$Json$Params, context?: HttpContext): Observable<UserSessionDto> {
    return this.apiSessionPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserSessionDto>): UserSessionDto => r.body)
    );
  }

  /** Path part for operation `apiSessionSessionIdGet()` */
  static readonly ApiSessionSessionIdGetPath = '/api/session/{sessionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSessionSessionIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSessionSessionIdGet$Plain$Response(params: ApiSessionSessionIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserSessionDto>> {
    return apiSessionSessionIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSessionSessionIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSessionSessionIdGet$Plain(params: ApiSessionSessionIdGet$Plain$Params, context?: HttpContext): Observable<UserSessionDto> {
    return this.apiSessionSessionIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserSessionDto>): UserSessionDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSessionSessionIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSessionSessionIdGet$Json$Response(params: ApiSessionSessionIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserSessionDto>> {
    return apiSessionSessionIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSessionSessionIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSessionSessionIdGet$Json(params: ApiSessionSessionIdGet$Json$Params, context?: HttpContext): Observable<UserSessionDto> {
    return this.apiSessionSessionIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserSessionDto>): UserSessionDto => r.body)
    );
  }

  /** Path part for operation `apiSessionSessionIdDelete()` */
  static readonly ApiSessionSessionIdDeletePath = '/api/session/{sessionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSessionSessionIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSessionSessionIdDelete$Response(params: ApiSessionSessionIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiSessionSessionIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSessionSessionIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSessionSessionIdDelete(params: ApiSessionSessionIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiSessionSessionIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSessionSessionIdMessagesGet()` */
  static readonly ApiSessionSessionIdMessagesGetPath = '/api/session/{sessionId}/messages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSessionSessionIdMessagesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSessionSessionIdMessagesGet$Plain$Response(params: ApiSessionSessionIdMessagesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserMessageDto>>> {
    return apiSessionSessionIdMessagesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSessionSessionIdMessagesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSessionSessionIdMessagesGet$Plain(params: ApiSessionSessionIdMessagesGet$Plain$Params, context?: HttpContext): Observable<Array<UserMessageDto>> {
    return this.apiSessionSessionIdMessagesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserMessageDto>>): Array<UserMessageDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSessionSessionIdMessagesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSessionSessionIdMessagesGet$Json$Response(params: ApiSessionSessionIdMessagesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserMessageDto>>> {
    return apiSessionSessionIdMessagesGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSessionSessionIdMessagesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSessionSessionIdMessagesGet$Json(params: ApiSessionSessionIdMessagesGet$Json$Params, context?: HttpContext): Observable<Array<UserMessageDto>> {
    return this.apiSessionSessionIdMessagesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserMessageDto>>): Array<UserMessageDto> => r.body)
    );
  }

}
