import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { NkodaLinkPanelService } from '../../services/nkoda-link-panel.service';

@Component({
  selector: 'aria-close-nkoda-link-panel',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './close-nkoda-link-panel.component.html',
  styleUrl: './close-nkoda-link-panel.component.scss',
})
export class CloseNkodaLinkPanelComponent implements OnInit {
  isHidden = true;

  private _unsubscribeObs$ = new Subject();

  constructor(private _linkPanelService: NkodaLinkPanelService) {}

  ngOnInit(): void {
    this._unsubscribeObs$ = new Subject();
    this._linkPanelService.showObs.pipe(takeUntil(this._unsubscribeObs$)).subscribe({
      next: (isHidden) => (this.isHidden = isHidden),
    });
  }

  showHide() {
    if (this.isHidden) {
      this.isHidden = false;
      this._linkPanelService.show();
      return;
    }

    this.isHidden = true;
    this._linkPanelService.hide();
  }
}
