import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { Subject, takeUntil } from 'rxjs';
import { ProductDto, ReceiptDto } from '../../api/aria/models';
import { UserInfoModel } from '../../models/user-info-model';
import { AuthService } from '../../services/auth.service';
import { ClientStripeService } from '../../services/client-stripe.service';
import { SubmitButtonComponent } from '../onboarding/submit-button/submit-button.component';

@Component({
  selector: 'aria-subscription-plan',
  standalone: true,
  imports: [CommonModule, SvgIconComponent, SubmitButtonComponent],
  templateUrl: './subscription-plan.component.html',
  styleUrl: './subscription-plan.component.scss',
})
export class SubscriptionPlanComponent implements OnInit, OnChanges, OnDestroy {
  @Input() planInfo!: ProductDto;
  @Input() subscriptionInfo!: Array<ReceiptDto>;
  @Input() selectedProductId!: string;
  @Input() index: number = 0;
  @Input() products!: Array<ProductDto>;

  user!: UserInfoModel;
  loading!: boolean;
  upgradeDowngradeText: string = 'Upgrade';

  ownedPlans!: Array<string>;
  private _unsubscribeObs$ = new Subject();

  constructor(private _clientStripeService: ClientStripeService, private _authService: AuthService) {}

  ngOnInit(): void {
    this._authService.userObs$.pipe(takeUntil(this._unsubscribeObs$)).subscribe({
      next: (user) => {
        this.user = user;
      },
    });

    this.setUpgradeDowngradeText();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['subscriptionInfo']) {
      this.ownedPlans = this.subscriptionInfo?.map((x) => x.ariaProductId!);
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeObs$.complete();
  }

  createCheckoutSession(plan: ProductDto) {
    this.loading = true;

    const paidSubscriptions = this.subscriptionInfo?.filter((x) => x.subscriptionType === 1);
    if (paidSubscriptions?.length) {
      const transactionId = this.subscriptionInfo.find((x) => x.transactionId)?.transactionId;
      this._clientStripeService
        .createCustomerPortalSession(transactionId!)
        .pipe(takeUntil(this._unsubscribeObs$))
        .subscribe({
          next: (url) => {
            window.location.href = url + `/subscriptions/${transactionId}/update`;
          },
        })
        .add(() => {
          this.loading = false;
        });

      return;
    }

    this._clientStripeService
      .createCheckoutSession(plan, this.user)
      .pipe(takeUntil(this._unsubscribeObs$))
      .subscribe({
        next: ([checkoutSession, stripe]) => {
          if (!checkoutSession?.checkoutSessionId) return;
          stripe?.redirectToCheckout({
            sessionId: checkoutSession.checkoutSessionId,
          });
        },
      })
      .add(() => {
        this.loading = false;
      });
  }

  setUpgradeDowngradeText() {
    const products = this.products.filter((x) => !x.isDefault);

    if (!this.ownedPlans?.length) {
      this.upgradeDowngradeText = `Upgrade to ${products[this.index - 1]?.title}`;
      return;
    }

    if (this.index - 1 === products.length - 1) {
      this.upgradeDowngradeText = `Upgrade to ${products[this.index - 1]?.title}`;
      return;
    }

    if (this.index - 1 !== products.length - 1 && this.index - 1 !== 0) {
      this.upgradeDowngradeText = `Upgrade to ${products[this.index - 1]?.title}`;
      return;
    }

    this.upgradeDowngradeText = `Downgrade to ${products[this.index - 1]?.title}`;
  }
}
