<div [class]="isPanelHidden ? 'w-0 transition-all duration-500' : 'w-[660px] mr-4 pl-4 transition-all duration-500'">
    <div class="h-full relative pr-2 overflow-x-auto scroll-hide"
        *ngIf="(titles?.length || composers?.length) && !isRequestInProgress">
        <div class="flex flex-row">
            <div class="flex flex-row items-center px-2 py-1 cursor-pointer" *ngFor="let tab of tabs"
                (click)="selectTab(tab)" [class.active-tab]="tab.isActive" [class.disabled-tab]="!tab.hasItems">
                <span *ngIf="tab.iconPath" class="mr-2">
                    <svg-icon [src]="tab.iconPath"
                        [svgStyle]="{'color': 'var(--text-primary)', 'margin-top': '-2px'}"></svg-icon>
                </span>
                <span>{{tab.name}}</span>
            </div>
        </div>
        <div *ngIf="selectedTab === 1 && composers">
            <div class="flex flex-row py-3 items-center justify-center w-full">
                <aria-pagination (pageChangedEvent)="pageChanged($event)" [nrOfPages]="composers.length"
                    [activePage]="currentPageIndex"></aria-pagination>
            </div>

            <div class="rounded-[10px] bg-[#d9d9d9] dark:bg-[#333] p-[10px] relative">
                <div>
                    <div class="flex flex-row">
                        <div>
                            <img class="w-24" [src]="selectedComposer?.composer?.coverImage?.url" alt="">
                        </div>
                        <div class="mx-2 mt-2">
                            <div>{{selectedComposer?.composer?.name}}</div>
                        </div>
                    </div>
                    <div>
                        <div class="h-[1px] w-full bg-[#8b8b8b] my-[14px]"></div>
                        <div class="pb-4">
                            <div [class]="isComposerInfoExpanded ? 'composer-container-expanded' : 'composer-container'"
                                class="text-[11px]">
                                <div class="biography">Biography</div>
                                <div *ngIf="selectedComposer?.composer?.description"
                                    class="biography-info text-[rgb(139,139,139)]">
                                    {{selectedComposer?.composer?.description}}
                                </div>

                                <div *ngIf="isComposerInfoExpanded" class="flex flex-row mt-4">
                                    <div class="leading-5">
                                        <div *ngIf="selectedComposer?.composer?.dateOfBirth">Date of birth:
                                            <span class="text-[#c8c8c8]">
                                                {{ selectedComposer?.composer?.dateOfBirth | date }}
                                            </span>
                                        </div>
                                        <div></div>
                                        <div *ngIf="selectedComposer?.composer?.dateOfDeath">Date of death:
                                            <span class="text-[#c8c8c8]">
                                                {{ selectedComposer?.composer?.dateOfDeath | date }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="absolute text-[#8b8b8b] bottom-0 left-1/2 transform -translate-x-1/2">
                    <div *ngIf="isComposerInfoExpanded; else more">
                        <div class="flex flex-row items-center cursor-pointer" (click)="toggleMoreInfo()">
                            <svg-icon src="/assets/icons/btns/arrow-up.svg"
                                [svgStyle]="{'margin-bottom': '2px', 'width.px': '10'}"></svg-icon>
                            <div class="ml-1">Hide</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="w-full">
                <aria-nkoda-link-edition-list
                    *ngIf="selectedComposer?.composer?.editions && selectedComposer?.composer?.editions?.length"
                    [editions]="selectedComposer.composer?.editions!"></aria-nkoda-link-edition-list>
            </div>
        </div>

        <div *ngIf="selectedTab === 0 && titles" class="pt-6">
            <aria-nkoda-link-title-list [titles]="titles"></aria-nkoda-link-title-list>
        </div>
    </div>
</div>

<div class="absolute bottom-1/2 top-1/2 -ml-9 -mt-10">
    <aria-close-nkoda-link-panel></aria-close-nkoda-link-panel>
</div>

<ng-template #more>
    <div class="absolute text-[#8b8b8b] bottom-0 left-1/2 transform -translate-x-1/2">
        <div class="flex flex-row items-center cursor-pointer" (click)="toggleMoreInfo()">
            <svg-icon src="/assets/icons/btns/arrow-down.svg"
                [svgStyle]="{'margin-bottom': '2px', 'width.px': '10'}"></svg-icon>
            <div class="ml-1">More</div>
        </div>
    </div>
</ng-template>

<ng-template #openPanel>
    <div class="flex h-6 w-6 flex-col items-center">
        <div class="h-3 w-1 rounded-full"
            style="background: var(--text-tertiary); transform: translateY(0.15rem) rotate(15deg) translateZ(0px);">
        </div>
        <div class="h-3 w-1 rounded-full"
            style="background: var(--text-tertiary); transform: translateY(-0.15rem) rotate(-15deg) translateZ(0px);">
        </div>
    </div>
</ng-template>