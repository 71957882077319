import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SvgIconComponent } from 'angular-svg-icon';
import { ModalService } from 'ngx-modal-ease';
import { combineLatest, filter, Subject, takeUntil, tap } from 'rxjs';
import { CategoryDto, ModelConfigDisplayDto, ProductDto, ReceiptDto, UserSettingsDto } from '../../api/aria/models';
import { AppTheme, Theme } from '../../constants/themeConstans';
import { PricingComponent } from '../../pages/pricing/pricing.component';
import { ClientThresholdService } from '../../services/client-threshold.service';
import { SettingsService } from '../../services/settings.service';
import { SidebarService } from '../../services/sidebar.service';
import { SubscriptionInfoService } from '../../services/subscription-info.service';
import { ThemeService } from '../../services/theme.service';
import { OpenAiService } from './../../services/open-ai.service';

@Component({
  selector: 'aria-header',
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit, OnDestroy {
  assistantKeySelect!: FormControl;
  isSelectorOpen = false;
  isProductSelectorOpen = false;
  isUserProfileOpen = false;
  isDarkMode = false;

  products!: Array<ProductDto>;
  b2cProducts!: Array<ProductDto>;
  userSettings: UserSettingsDto = {};

  sideBarStatus!: boolean;

  categories!: Array<CategoryDto>;
  selectedProduct!: ProductDto;
  subscriptions!: Array<ReceiptDto>;

  selectedModelConfig!: ModelConfigDisplayDto;

  isSubscriptionHighlighted = false;
  upgradeDowngradeText = 'Upgrade';
  hideUpgradeText!: boolean;

  private _unsubscribe$: Subject<void> = new Subject();

  constructor(
    private _elementRef: ElementRef,
    private _openAiService: OpenAiService,
    private _themeService: ThemeService,
    private _sidebarService: SidebarService,
    private _settingsService: SettingsService,
    private _modalService: ModalService,
    private _subscriptionInfoService: SubscriptionInfoService,
    private _thresholdService: ClientThresholdService
  ) {}

  ngOnInit(): void {
    this.sideBarStatus = this._sidebarService.state;
    if (localStorage.getItem(Theme) === AppTheme.Dark) {
      this.isDarkMode = true;
    }

    this._sidebarService.showSidebarObs.pipe(takeUntil(this._unsubscribe$)).subscribe({
      next: (sideBarStatus) => {
        this.sideBarStatus = sideBarStatus;
      },
    });

    const productObs$ = this._subscriptionInfoService.userProductsObs$
      .pipe(takeUntil(this._unsubscribe$))
      .pipe(filter((x) => x !== null && x !== undefined && x.length > 0))
      .pipe(tap((products) => (this.products = products)));

    const b2cProductsObs$ = this._subscriptionInfoService.b2cProductsObs$
      .pipe(takeUntil(this._unsubscribe$))
      .pipe(filter((x) => x !== null && x !== undefined && x.length > 0))
      .pipe(tap((b2cProducts) => (this.b2cProducts = b2cProducts)));

    const subscriptionInfoObs$ = this._subscriptionInfoService.subscriptionsObs$
      .pipe(takeUntil(this._unsubscribe$))
      .pipe(tap((subscriptions) => (this.subscriptions = subscriptions)));

    const selectedProductObs$ = this._settingsService.selectedProductObs$.pipe(takeUntil(this._unsubscribe$));
    const userSettingsObs$ = this._settingsService.userSettingsObs$.pipe(takeUntil(this._unsubscribe$));

    combineLatest({
      b2cProducts: b2cProductsObs$,
      products: productObs$,
      subscriptions: subscriptionInfoObs$,
      userSettings: userSettingsObs$,
    }).subscribe({
      next: (results) => {
        this.b2cProducts = results.b2cProducts;
        this.subscriptions = results.subscriptions;
        this.products = results.products;

        if (results.userSettings) {
          this.userSettings = results.userSettings;
          this.selectProduct(this.products.find((x) => x.id === this.userSettings.productSelected)!);
        }
      },
    });

    selectedProductObs$.subscribe({
      next: (selectedProduct) => {
        this.selectedProduct = selectedProduct;
        if (this.selectedProduct?.categories) {
          this.categories = this.selectedProduct.categories!;
        }
      },
    });

    this._settingsService.selectedModelConfigObs$.pipe(takeUntil(this._unsubscribe$)).subscribe({
      next: (selectedModelConfig) => {
        this.selectedModelConfig = selectedModelConfig;
      },
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe$.complete();
  }

  showSelector() {
    this.isProductSelectorOpen = false;
    this.isSelectorOpen = !this.isSelectorOpen;
  }

  showProductSelector() {
    this.isSelectorOpen = false;
    this.isProductSelectorOpen = !this.isProductSelectorOpen;
  }

  createThread() {
    this._openAiService.createSession().pipe(takeUntil(this._unsubscribe$)).subscribe();
  }

  showSubscription() {
    this.isProductSelectorOpen = false;
    this.isSubscriptionHighlighted = true;
    this._modalService
      .open(PricingComponent, {
        size: {
          padding: '0',
        },
        overlay: {
          backgroundColor: '#29292960',
        },
      })
      .subscribe({
        next: () => {},
        error: () => {},
        complete: () => {
          this.isSubscriptionHighlighted = false;
        },
      });
  }

  selectModelConfig(selectedItem: ModelConfigDisplayDto) {
    this.selectedModelConfig = selectedItem;
    this._settingsService.modelConfig = selectedItem;
    this.isSelectorOpen = false;
  }

  selectProduct(selectProduct: ProductDto) {
    if (!this.userSettings) {
      this.userSettings = {};
    }

    this.selectedProduct = selectProduct;
    this.userSettings.productSelected = selectProduct?.id;
    this._settingsService.product = this.selectedProduct;

    this.isProductSelectorOpen = false;
    this.setUpgradeDowngradeText();
  }

  showSideBar() {
    this._sidebarService.showHide();
  }

  switchColorMode() {
    this._themeService.switchTheme();
  }

  setUpgradeDowngradeText() {
    this.upgradeDowngradeText = `Upgrade`;
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: Event) {
    if (!this.isSelectorOpen && !this.isProductSelectorOpen) {
      return;
    }

    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside && this.isSelectorOpen) {
      this.showSelector();
    }

    if (!clickedInside && this.isProductSelectorOpen) {
      this.showProductSelector();
    }
  }
}
