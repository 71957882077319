import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
  selector: '[ariaScrollSectionDetect]',
  standalone: true,
})
export class ScrollDetectDirective implements OnInit, OnDestroy {
  @Output() sectionChange = new EventEmitter<string>();

  private scrollSubscription!: Subscription;

  constructor(private _el: ElementRef) {}

  ngOnInit(): void {
    this.scrollSubscription = fromEvent(this._el.nativeElement, 'scroll').subscribe(() => {
      this.detectCurrentSection();
    });
  }

  ngOnDestroy(): void {
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
  }

  private detectCurrentSection(): void {
    const sections = this._el.nativeElement.querySelectorAll('aria-ai-message');
    let currentSectionId = '';

    sections.forEach((section: HTMLElement) => {
      const rect = section.getBoundingClientRect();
      if (rect.top >= 0 && rect.top < window.innerHeight / 2) {
        currentSectionId = section.id;
      }
    });

    if (this.isScrollAtMax(this._el.nativeElement)) {
      const lastSectionId = this.getLastSectionId(sections);
      this.sectionChange.emit(lastSectionId!);
      return;
    }

    if (currentSectionId) {
      this.sectionChange.emit(currentSectionId);
    }
  }

  private getLastSectionId(sections: Array<HTMLElement>) {
    if (!sections || !sections.length) return null;
    return sections[sections.length - 1]?.id;
  }

  private isScrollAtMax(element: HTMLElement) {
    return element.scrollTop + element.clientHeight >= element.scrollHeight;
  }
}
