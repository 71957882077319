<div class="fixed left-0 top-1/2 z-40" [ngClass]="sideBarStatus ? 'sidebar-transition-hidden' : 'sidebar-transition'">
  <aria-close-sidebar [isSideBarHidden]="sideBarStatus"></aria-close-sidebar>
</div>

<div class="flex-shrink-0 overflow-x-hidden bg-token-sidebar-surface-primary h-full text-gray-100 font-marfa"
  [ngClass]="sideBarStatus ? 'sidebar-hidden' : 'sidebar'">

  <div class="h-full w-[260px]">
    <div class="flex h-full min-h-0 flex-col">
      <div class="flex h-full min-h-0 flex-col transition-opacity opacity-100">
        <div class="relative h-full w-full flex-1 items-start border-white/20">
          <h2 class="chat-history">Chat history</h2>
          <nav class="flex h-full w-full flex-col px-3 pb-3.5" aria-label="Chat history">
            <div
              class="flex-col flex-1 transition-opacity duration-500 -mr-2 pr-2 overflow-y-auto text-[var(--sidebar-text-primary)]">
              <div class="flex flex-row">
                <div class="sticky left-0 right-0 top-0 z-20 pt-3.5 w-[100%]">
                  <div class="pb-0.5 last:pb-0">
                    <div (click)="createSession()" class="group flex h-10 items-center gap-2 rounded-lg bg-token-sidebar-surface-primary 
                      pl-2 font-medium hover:bg-token-sidebar-surface-secondary">
                      <div class="h-7 w-7 flex-shrink-0">
                        <div class="gizmo-shadow-stroke relative flex h-full items-center justify-center rounded-full">
                          <svg-icon src="/assets/icons/aria-logo.svg" [svgStyle]="{ 'width.px': 24 }"></svg-icon>
                        </div>
                      </div>
                      <div class="flex align-middle justify-between w-full">
                        <div class="grow overflow-hidden text-ellipsis whitespace-nowrap text-sm cursor-pointer">
                          New chat
                        </div>
                        <div class="w-5 h-5 sidebar-edit cursor-pointer flex items-center">
                          <svg-icon src="/assets/icons/btns/new-chat.svg"
                            [svgStyle]="{ 'width.px': 24, 'color': 'var(--text-secondary)' }"></svg-icon>
                        </div>
                      </div>

                      <div class="flex gap-3">
                        <span class="flex items-center"><button class="text-token-text-primary"></button></span>
                      </div>
                    </div>
                  </div>
                </div>
                <button type="button" (click)="showOrHide()"
                  class="md:hidden mt-3.5 flex h-10 w-10 items-center justify-center text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"><span
                    class="sr-only">Close sidebar</span>
                  <svg-icon class="mt-0.5" src="/assets/icons/btns/close.svg"
                    [svgStyle]="{ 'width.px': 24, 'color': 'var(--text-secondary)' }"></svg-icon>
                </button>
              </div>
              <div class="flex flex-col gap-2 text-sm">
                <div>
                  <span>
                    <div class="relative mt-5" style="height: auto; opacity: 1">
                      <ol *ngFor="let period of keyValuePairs; let i = index" class="mb-5">
                        <p class="text-[11px] ml-3 font-semibold text-gray-400">
                          <span *ngIf="period === '0' && organizedSession[i].length">Today</span>
                          <span *ngIf="period === '1' && organizedSession[i].length">Previous 7 days</span>
                          <span *ngIf="period === '2' && organizedSession[i].length">Previous 30 days</span>
                        </p>
                        <li *ngFor="let session of organizedSession[i]" class="relative z-[15] mt-1"
                          style="opacity: 1; height: auto">
                          <div (click)="selectSession(session)"
                            class="group relative rounded-lg active:opacity-90 hover:bg-token-sidebar-surface-secondary cursor-pointer"
                            [ngClass]="session.isSelected ? 'bg-token-sidebar-surface-secondary' : ''">
                            <a class="flex items-center gap-2 px-4 py-[6px]" data-twe-ripple-init
                              data-twe-ripple-color="#999">
                              <div class="relative grow overflow-hidden whitespace-nowrap font-extralight"
                                *ngIf="session?.firstMessage">
                                <p class="overflow-hidden max-w-48 overflow-ellipsis">
                                  {{ session?.firstMessage?.text === null || session?.firstMessage?.text === undefined
                                  || session?.firstMessage?.text === '' ? "New Chat" : session.firstMessage?.text }}
                                </p>
                                <div
                                  class="absolute bottom-0 right-0 top-0 bg-gradient-to-l to-transparent from-token-sidebar-surface-primary w-8 from-token-sidebar-surface-primary from-0% group-hover:w-20 group-hover:from-token-sidebar-surface-secondary group-hover:from-60%">
                                </div>
                              </div>
                              <div class="relative grow overflow-hidden whitespace-nowrap font-extralight"
                                *ngIf="!session?.firstMessage">
                                <p class="overflow-hidden max-w-48 overflow-ellipsis">
                                  {{ "New chat" }}
                                </p>
                                <div
                                  class="absolute bottom-0 right-0 top-0 bg-gradient-to-l to-transparent from-token-sidebar-surface-primary w-8 from-token-sidebar-surface-primary from-0% group-hover:w-20 group-hover:from-token-sidebar-surface-secondary group-hover:from-60%">
                                </div>
                              </div>
                            </a>
                            <div
                              class="absolute bottom-0 right-0 top-0 items-center gap-1.5 pr-1 hidden group-hover:flex">
                              <button
                                class="flex items-center justify-center transition hover:text-token-text-secondary radix-state-open:text-token-text-secondary"
                                type="button" id="radix-:rt:" aria-haspopup="menu" aria-expanded="false"
                                data-state="closed" (click)="deleteSession(session)">
                                <span class="w-6 h-6" data-state="closed">
                                  <svg-icon src="/assets/icons/btns/delete.svg"
                                    [svgStyle]="{ 'color': 'white' }"></svg-icon>
                                </span>
                              </button>
                            </div>
                          </div>
                        </li>
                      </ol>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div class="flex flex-col pt-2 empty:hidden dark:border-white/20">
              <div class="flex w-full items-center">
                <div class="grow">
                  <div class="group relative">
                    <div *ngIf="showUserSettings"
                      class="popover absolute bg-token-sidebar-surface-primary bottom-full left-0 z-20 mb-1 w-full overflow-hidden text-[12px] rounded-lg border border-[#313131] dark:bg-token-main-surface-primary p-1.5 shadow-lg outline-none opacity-100 translate-y-0"
                      role="menu">
                      <nav role="none">
                        <div class="ml-3 mr-4 py-2 text-token-text-secondary overflow-hidden break-words" role="none">
                          {{ user.email }}
                        </div>
                        <div *ngIf="isSubscriptionManagementAvailable">
                          <a as="button" (click)="openSubscriptionManagement()"
                            class="flex gap-2 rounded p-2.5 text-xs cursor-pointer focus:ring-0 radix-disabled:pointer-events-none radix-disabled:opacity-50 group items-center hover:bg-token-sidebar-surface-secondary"
                            id="headlessui-menu-item-:r6p:" role="menuitem" tabindex="-1">
                            <svg-icon src="/assets/icons/aria-logo-lg.svg" [svgStyle]="{ 'width.px': 20 }"></svg-icon>
                            My plans
                          </a>
                        </div>
                        <div class="h-px bg-token-border-dark my-1.5" role="none"></div>
                        <a as="button" (click)="logout()"
                          class="flex gap-2 rounded p-2.5 text-sm cursor-pointer focus:ring-0 radix-disabled:pointer-events-none radix-disabled:opacity-50 group items-center hover:bg-token-sidebar-surface-secondary"
                          id="headlessui-menu-item-:r6q:" role="menuitem" tabindex="-1"><svg width="20" height="20"
                            viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-md">
                            <path d="M11 3H7C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21H11"
                              stroke="currentColor" stroke-width="2" stroke-linecap="round"></path>
                            <path d="M20 12H11M20 12L16 16M20 12L16 8" stroke="currentColor" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round"></path>
                          </svg>Log out</a>
                      </nav>
                    </div>
                    <button (click)="showUserSettings = !showUserSettings" data-twe-ripple-init
                      data-twe-ripple-color="#999"
                      class="flex w-full items-center gap-2 rounded-lg p-2 text-sm hover:bg-token-sidebar-surface-secondary group-ui-open:bg-token-sidebar-surface-secondary"
                      type="button">
                      <div class="flex-shrink-0">
                        <div class="flex items-center justify-center overflow-hidden rounded-full">
                          <div class="relative flex">
                            <img referrerpolicy="no-referrer" (error)="user.picture = ''"
                              *ngIf="user?.picture; else userCredentialsPicture" alt=""
                              class="rounded-sm bg-transparent w-7 h-7" [src]="user.picture" />
                          </div>
                        </div>
                      </div>
                      <div class="relative -top-px grow -space-y-px text-left max-w-48">
                        <div *ngIf="user?.fullName" class="whitespace-nowrap overflow-hidden overflow-ellipsis">
                          {{ user.fullName }}
                        </div>
                        <div class="text-[10px] text-secondary-500" *ngIf="thresholdInfo && thresholdInfo.hasThreshold">
                          <div *ngIf="thresholdInfo.currentThreshold !== 0">Messages left: {{
                            thresholdInfo.currentThreshold }}</div>

                          <div *ngIf="thresholdInfo.currentThreshold === 0">
                            <p>Daily message limit exceeded</p>
                          </div>
                        </div>

                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #userCredentialsPicture>
  <div class="w-8 h-8 bg-yellow-400 rounded-full flex justify-center items-center">
    <span *ngIf="user?.userInitials" class="text-black font-semibold">{{ user.userInitials }}</span>
  </div>
</ng-template>