import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'aria-user-settings',
  standalone: true,
  templateUrl: './user-settings.component.html',
  styleUrl: './user-settings.component.scss',
  imports: [CommonModule],
})
export class UserSettingsComponent {}
