import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { OnboardingWrapperComponent } from '../../components/onboarding/onboarding-wrapper/onboarding-wrapper.component';
import { AppRoutes } from '../../constants/appRoutes';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'aria-unauthorized',
  standalone: true,
  imports: [RouterModule, OnboardingWrapperComponent],
  templateUrl: './unauthorized.component.html',
  styleUrl: './unauthorized.component.scss',
})
export class UnauthorizedComponent implements OnInit, OnDestroy {
  private _unsubscribeObs$!: Subject<void>;

  constructor(private _authService: AuthService, private _router: Router) {}

  ngOnInit(): void {
    this._unsubscribeObs$ = new Subject();
  }

  ngOnDestroy(): void {
    this._unsubscribeObs$.complete();
  }

  goToLogin() {
    if (!this._authService.isLoggedIn()) {
      this._router.navigate([AppRoutes.Landing]);
    }

    this._authService
      .logout()
      .pipe(takeUntil(this._unsubscribeObs$))
      .subscribe({
        next: () => {
          this._router.navigate([AppRoutes.Login]);
        },
      });
  }
}
