<div class="h-screen w-screen">
    <div class="w-full h-full dark:bg-[#171717] bg-[#333333] overflow-auto scroll-hide">
        <div class="flex flex-col items-center justify-center dark:bg-[#171717] bg-[#333333] pb-5 md:h-full w-full">
            <div class="sticky w-full dark:bg-[#171717] bg-[#333333]">
                <div class="mx-2 mb-4 h-[45px] pricing-text-secondary text-center flex items-center justify-center 
                        rounded-[12px] pricing-title">
                    Upgrade your ARIA-i
                    plan

                    <div class="absolute right-5 cursor-pointer" (click)="closeModal()">
                        <svg-icon src="/assets/icons/btns/cross.svg"
                            [svgStyle]="{ 'width.px': '16', 'color': '#8b8b8b' }"></svg-icon>
                    </div>
                </div>
            </div>
            <div class="w-full h-full items-center flex justify-center" *ngIf="plans">
                <div class="grid xs:gap-4 sm:gap-4" [style]="gridTemplateCols">
                    <div *ngFor="let plan of plans; let i = index" class="md:border-r-[#8b8b8b] md:pr-5"
                        [class]="i === plans.length - 1 ? '' : 'md:border-r-[1px]'">

                        <aria-subscription-plan class="max-h-80" [products]="plans" [index]="i" [planInfo]="plan"
                            [selectedProductId]="selectedProductId" [subscriptionInfo]="subscriptions">
                        </aria-subscription-plan>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <!-- <div class="flex justify-center flex-col">
        <div class="dark:bg-[#171717] bg-[#333333] flex flex-col justify-center pt-2 pb-5 px-10">
            <div>
                <div
                    class="mx-2 mb-4 h-[45px] pricing-text-secondary text-center flex items-center justify-center rounded-[12px] pricing-title">
                    Upgrade your ARIA-i
                    plan

                    <div class="absolute right-5 cursor-pointer" (click)="closeModal()">
                        <svg-icon src="/assets/icons/btns/cross.svg"
                            [svgStyle]="{ 'width.px': '16', 'color': '#8b8b8b' }"></svg-icon>
                    </div>
                </div>
            </div>

            <div>
                <div *ngFor="let plan of plans; let i = index">
                    <aria-subscription-plan [products]="plans" [index]="i" [planInfo]="plan"
                        [selectedProductId]="selectedProductId" [subscriptionInfo]="subscriptions"
                        class="h-full"></aria-subscription-plan>
                </div>
            </div>
        </div>
    </div> -->
</div>