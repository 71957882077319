import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

export function emailAvailability(authService: AuthService): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    if (!control.value) {
      return of(null);
    }

    return timer(500).pipe(
      switchMap(() =>
        authService.checkEmailAvailability(control.value).pipe(
          map((exists: boolean) => (exists ? null : { emailExists: true })),
          catchError(() => of(null))
        )
      )
    );
  };
}
