<div class="flex flex-row justify-between w-full mb-3 pb-3"
    [class]="titles.length - 1 !== i ? 'border-b-[1px] border-[#8b8b8b]' : ''"
    *ngFor="let item of titles; let i = index">
    <div>
        <a class="rounded-xl" target="_blank" [href]="'https://app.nkoda.com/work?ref=' + item?.title?.editionId">
            <img [src]="item?.title?.coverImage?.url" class="w-24 rounded-xl">
        </a>
    </div>

    <div class="w-full flex justify-between pl-4" *ngIf="item">
        <div class="flex flex-col justify-between h-full">
            <div>
                <div class="flex flex-col">
                    <a class="text-sm cursor-pointer text-ellipsis overflow-hidden"
                        [href]="'https://app.nkoda.com/work?ref=' + item?.title?.editionId" target="_blank">
                        {{ item?.title?.worktitleTitle }}
                    </a>
                    <a class="text-sm text-ellipsis overflow-hidden text-[#8c8c8c]">
                        {{ item?.title?.workTitleSubTitle }}
                    </a>
                </div>
            </div>

            <div>
                <div class="flex flex-col">
                    <div *ngIf="item?.title?.workTitleContributers && item?.title?.workTitleContributers?.length">
                        <a class="text-xs text-[#8b8b8b] cursor-pointer" target="_blank"
                            [href]="'https://app.nkoda.com/artist?ref=' + item?.title?.workTitleContributerRefs![0]">{{item?.title?.workTitleContributers![0]}}</a>

                    </div>
                    <a class="text-xs" target="_blank" *ngIf="item?.title?.publisher"
                        [href]="'https://app.nkoda.com/publisher?ref=' + item?.title?.publisher?.id">{{
                        item?.title?.publisher?.name
                        }}
                        pages</a>
                </div>
            </div>
        </div>

    </div>
</div>