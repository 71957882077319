import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ModelConfigurationDto, ProductDto, UserSettingsDto } from '../api/aria/models';
import { UserService } from '../api/aria/services';
import { UserInfoModel } from '../models/user-info-model';
import { ModelConfigDisplayDto } from './../api/aria/models/model-config-display-dto';
import { ClientThresholdService } from './client-threshold.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private _userSettings: UserSettingsDto = {};

  private _product: ProductDto = {};
  private _products: Array<ProductDto> = [];

  private _modelConfig: ModelConfigDisplayDto = {};
  private _modelConfigs: Array<ModelConfigDisplayDto> = [];

  private _user!: UserInfoModel;

  private _selectedModelConfigSubject = new BehaviorSubject<ModelConfigurationDto>(this._modelConfig);
  public selectedModelConfigObs$ = this._selectedModelConfigSubject.asObservable();

  private _userSettingsSubject = new BehaviorSubject<UserSettingsDto>(this._userSettings);
  public userSettingsObs$ = this._userSettingsSubject.asObservable();

  private _productsSubject = new BehaviorSubject<Array<ProductDto>>(this._products);
  public productsObs$ = this._productsSubject.asObservable();

  private _selectedProductSubject = new BehaviorSubject<ProductDto>(this._product);
  public selectedProductObs$ = this._selectedProductSubject.asObservable();

  constructor(private _userService: UserService, private _thresholdService: ClientThresholdService) {}

  set user(user: UserInfoModel) {
    this._user = user;
  }

  set products(value: Array<ProductDto>) {
    this._products = value;
    this._productsSubject.next(this._products);
    const product = this._products.find((x) => this._userSettings?.productSelected === x.id) ?? this._products[0];
    this.product = product;
  }

  get product() {
    return this._product;
  }

  get modelConfig() {
    return this._modelConfig;
  }

  get userSettings() {
    return this._userSettings;
  }

  set userSettings(value: UserSettingsDto) {
    this._userSettings = value;
    this._userSettingsSubject.next(value);
  }

  set modelConfig(nextValue: ModelConfigDisplayDto) {
    if (!nextValue) return;

    this._modelConfig = nextValue;
    this._userSettings.modelConfigId = nextValue.id;
    this._selectedModelConfigSubject.next(nextValue);

    this.updateUserSettings();
  }

  set product(nextValue: ProductDto) {
    if (!nextValue) return;

    this._product = nextValue;

    this._userSettings.productSelected = nextValue.id;
    this._thresholdService.getUserThreshold(this._product.id!);

    this.setModelConfig();
    this._selectedProductSubject.next(nextValue);
  }

  setProductId(productId: string) {
    this._userSettings.productSelected = productId;
    this.updateUserSettings();
  }

  updateUserSettings() {
    if (!this._userSettings?.userIdentityId && this._user?.sub) {
      this._userSettings.userIdentityId = this._user.sub;
    }

    this._userService
      .apiUserSettingsPost$Json({
        body: this._userSettings,
      })
      .subscribe({
        next: (userSettings) => {
          this._userSettings = userSettings;
        },
      });
  }

  private setModelConfig() {
    if (!this._product || !this._product.modelConfigsData) return;

    const modelConfig = this._product.modelConfigsData.find((x) => x.id === this._userSettings?.modelConfigId);
    if (!modelConfig) {
      const modelConfig = this._product.modelConfigsData[0];
      this.modelConfig = modelConfig;
      return;
    }

    this.modelConfig = modelConfig!;
  }
}
