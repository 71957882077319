<div class="flex items-center justify-between z-10 h-14 p-2 font-semibold">
  <div class="header-menu w-20">
    <button class="w-5 h-5 ml-4 -mt-1" (click)="showSideBar()">
      <svg-icon src="/assets/icons/btns/hamburger.svg"
        [svgStyle]="{ 'width.px': 20, 'color': 'var(--text-primary)' }"></svg-icon>
    </button>
  </div>
  <div class="flex items-center">
    <div (click)="showSelector()" *ngIf="categories && categories.length"
      class="group flex cursor-pointer items-center gap-1 rounded-xl py-2 px-3 text-lg font-medium hover:bg-gray-100 dark:hover:bg-[#121212] radix-state-open:bg-gray-50"
      type="button">
      <div class="text-[14px] leading-4">{{ selectedModelConfig?.categoryDisplayName ??
        selectedModelConfig?.categoryName
        }}</div>
      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" class="text-token-text-tertiary">
        <path d="M11.3346
        7.83203L8.00131
        11.1654L4.66797
        7.83203" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
      </svg>
    </div>

    <div (click)="showProductSelector()" *ngIf="products && products.length"
      class="group flex cursor-pointer items-center gap-1 rounded-xl py-2 px-3 text-lg font-medium hover:bg-gray-100 dark:hover:bg-[#121212] radix-state-open:bg-gray-50">
      <div class="text-[14px] leading-4">{{ selectedProduct?.title }}</div>
      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" class="text-token-text-tertiary">
        <path d="M11.3346
        7.83203L8.00131
        11.1654L4.66797
        7.83203" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
      </svg>
    </div>

    <div *ngIf="isProductSelectorOpen">
      <div class="assistant-selector xs:left-0" [style]="sideBarStatus ? 'left: 10px' : 'left: 270px'">
        <div
          class="mt-2 overflow-hidden rounded-lg border dark:border-[#212121] bg-token-main-surface-primary shadow-lg"
          style="outline: none;
            --radix-dropdown-menu-content-transform-origin: var(
              --radix-popper-transform-origin
            );
            --radix-dropdown-menu-content-available-width: var(
              --radix-popper-available-width
            );
            --radix-dropdown-menu-content-available-height: var(
              --radix-popper-available-height
            );
            --radix-dropdown-menu-trigger-width: var(--radix-popper-anchor-width);
            --radix-dropdown-menu-trigger-height: var(
              --radix-popper-anchor-height
            );
            pointer-events: auto;
          ">
          <div *ngFor="let product of products" (click)="selectProduct(product)">
            <div role="menuitem" class="flex gap-2 m-1.5 rounded p-2.5 text-sm cursor-pointer focus:ring-0 
            hover:bg-token-main-surface-secondary radix-disabled:pointer-events-none 
            radix-disabled:opacity-50 group !pr-3 !opacity-100">
              <div class="flex grow items-center justify-between gap-2">
                <div>
                  <div class="flex items-center gap-3">
                    <div class="w-7 h-7 flex items-center justify-center rounded-full dark:bg-transparent bg-black">
                      <svg-icon src="assets/icons/aria-logo-lg.svg"
                        [svgStyle]="{ 'width.px': 20, 'color': product.color, 'margin-top': '-1px' }">
                      </svg-icon>
                    </div>
                    <div class="flex flex-col max-w-48">
                      <div>{{ product.title }}</div>
                      <div class="text-xs">{{ product.subTitle }}</div>
                    </div>
                  </div>
                </div>
                <svg *ngIf="product?.id === selectedProduct?.id; else unSelected" width="20" height="20"
                  viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                  class="icon-md flex-shrink-0 block">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM14.0755 5.93219C14.5272 6.25003 14.6356 6.87383 14.3178 7.32549L9.56781 14.0755C9.39314 14.3237 9.11519 14.4792 8.81226 14.4981C8.50934 14.517 8.21422 14.3973 8.01006 14.1727L5.51006 11.4227C5.13855 11.014 5.16867 10.3816 5.57733 10.0101C5.98598 9.63855 6.61843 9.66867 6.98994 10.0773L8.65042 11.9039L12.6822 6.17451C13 5.72284 13.6238 5.61436 14.0755 5.93219Z"
                    fill="currentColor"></path>
                </svg>
              </div>
            </div>
          </div>


          <div role="menuitem" class="flex justify-center pb-4 px-4">
            <button (click)="showSubscription()" *ngIf="!hideUpgradeText"
              class="w-full h-10 rounded-[12px] cursor-pointer text-sm bg-[#00FF3C] text-black text-center flex justify-center items-center">
              {{upgradeDowngradeText}}</button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isSelectorOpen">
      <div class="assistant-selector" [style]="sideBarStatus ? 'left: 10px' : 'left: 270px'">
        <div
          class="mt-2 overflow-hidden rounded-lg border dark:border-[#212121] bg-token-main-surface-primary shadow-lg"
          style="outline: none;
            --radix-dropdown-menu-content-transform-origin: var(
              --radix-popper-transform-origin
            );
            --radix-dropdown-menu-content-available-width: var(
              --radix-popper-available-width
            );
            --radix-dropdown-menu-content-available-height: var(
              --radix-popper-available-height
            );
            --radix-dropdown-menu-trigger-width: var(--radix-popper-anchor-width);
            --radix-dropdown-menu-trigger-height: var(
              --radix-popper-anchor-height
            );
            pointer-events: auto;
          ">
          <div role="menuitem" *ngFor="let modelConfig of selectedProduct.modelConfigsData"
            (click)="selectModelConfig(modelConfig)"
            class="flex gap-2 m-1.5 rounded p-2.5 text-sm cursor-pointer focus:ring-0 hover:bg-token-main-surface-secondary radix-disabled:pointer-events-none radix-disabled:opacity-50 group !pr-3 !opacity-100">
            <div class="flex grow items-center justify-between gap-2">
              <div>
                <div class="flex items-center gap-3">
                  <div>{{ modelConfig.categoryDisplayName ?? modelConfig.categoryName }}</div>
                </div>
              </div>
              <svg *ngIf="modelConfig?.id === selectedModelConfig?.id; else unSelected" width="20" height="20"
                viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-md flex-shrink-0 block">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM14.0755 5.93219C14.5272 6.25003 14.6356 6.87383 14.3178 7.32549L9.56781 14.0755C9.39314 14.3237 9.11519 14.4792 8.81226 14.4981C8.50934 14.517 8.21422 14.3973 8.01006 14.1727L5.51006 11.4227C5.13855 11.014 5.16867 10.3816 5.57733 10.0101C5.98598 9.63855 6.61843 9.66867 6.98994 10.0773L8.65042 11.9039L12.6822 6.17451C13 5.72284 13.6238 5.61436 14.0755 5.93219Z"
                  fill="currentColor"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-row justify-between">
    <div class="header-edit mr-4">
      <button class="w-5 h-5" (click)="createThread()">
        <svg-icon src="/assets/icons/btns/new-chat.svg"
          [svgStyle]="{ 'width.px': 24, 'color': 'var(--text-primary)' }"></svg-icon>
      </button>
    </div>

    <button (click)="switchColorMode()" class="mr-2 mt-[0.5px]">
      <svg-icon src="/assets/icons/btns/sun.svg"
        [svgStyle]="{ 'width.px': 24, 'color': 'var(--text-primary)' }"></svg-icon>
    </button>
  </div>
</div>

<ng-template #compositionSvg>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-md">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M15.4646 19C15.2219 20.6961 13.7632 22 12 22C10.2368 22 8.77806 20.6961 8.53544 19H6C4.34315 19 3 17.6569 3 16V13.5C3 12.9477 3.44772 12.5 4 12.5H4.5C5.32843 12.5 6 11.8284 6 11C6 10.1716 5.32843 9.5 4.5 9.5H4C3.44772 9.5 3 9.05229 3 8.5L3 6C3 4.34315 4.34315 3 6 3L18 3C19.6569 3 21 4.34315 21 6L21 16C21 17.6569 19.6569 19 18 19H15.4646ZM12 20C12.8284 20 13.5 19.3284 13.5 18.5V18C13.5 17.4477 13.9477 17 14.5 17H18C18.5523 17 19 16.5523 19 16L19 6C19 5.44772 18.5523 5 18 5L6 5C5.44772 5 5 5.44772 5 6V7.53544C6.69615 7.77806 8 9.23676 8 11C8 12.7632 6.69615 14.2219 5 14.4646L5 16C5 16.5523 5.44771 17 6 17H9.5C10.0523 17 10.5 17.4477 10.5 18V18.5C10.5 19.3284 11.1716 20 12 20Z"
      fill="currentColor"></path>
  </svg>
</ng-template>

<ng-template #unSelected>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
    class="icon-md flex-shrink-0 block">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
      fill="currentColor" opacity="0.16"></path>
  </svg>
</ng-template>