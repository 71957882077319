import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { SidebarService } from '../../services/sidebar.service';

@Component({
  selector: 'aria-close-sidebar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './close-sidebar.component.html',
  styleUrl: './close-sidebar.component.scss',
})
export class CloseSidebarComponent implements OnInit {
  @Input() isSideBarHidden!: boolean;

  constructor(private _sidebarService: SidebarService) {}

  ngOnInit(): void {}

  showHide() {
    this._sidebarService.showHide();
  }
}
