import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ModalService } from 'ngx-modal-ease';
import { ResearcherHistoryDto } from '../../api/aria/models';

@Component({
  selector: 'aria-full-prompt',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './full-prompt.component.html',
  styleUrl: './full-prompt.component.scss',
})
export class FullPromptComponent implements OnInit {
  researcherHistoryDto!: ResearcherHistoryDto;
  userHistory!: Array<string>;

  constructor(private _modalService: ModalService) {}
  ngOnInit(): void {
    this.researcherHistoryDto = this._modalService.options?.data!['researcherHistory'] as ResearcherHistoryDto;
    this.userHistory = this.researcherHistoryDto?.userHistory ?? [];
  }

  closeDialog() {
    this._modalService.close();
  }
}
