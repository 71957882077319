<div
    class="bg-token-main-surface-primary flex flex-col gap-3 px-4 pb-1 text-sm text-token-text-primary sm:px-6 sm:pb-2 md:pl-0 md:pt-5">
    <button (click)="closeDialog()"
        class="absolute right-10 top-10 text-token-text-tertiary hover:text-token-text-secondary">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.34315 6.34338L17.6569 17.6571M17.6569 6.34338L6.34315 17.6571" stroke="currentColor"
                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
    </button>
    <form class="p-10 last-of-type:border-b-0 flex flex-row">
        <div class="flex flex-row justify-around w-full">
            <div>
                <div class="flex flex-col">
                    <label class="mb-2" for="">Model</label>
                    <input disabled class="rounded-lg p-2 border dark:border-[#313131]" type="text"
                        [value]="selectedModelName">
                </div>
                <div class="flex flex-col mt-4">
                    <label class="mb-2" for="">Deployment Name</label>
                    <input disabled class="rounded-lg p-2 border dark:border-[#313131]" type="text"
                        [value]="researcherHistory?.deploymentName ?? 0">
                </div>
                <div class="flex flex-col mt-4">
                    <label class="mb-2" for="">Max Tokens</label>
                    <input disabled class="rounded-lg p-2 border dark:border-[#313131]" type="number"
                        [value]="researcherHistory?.maxToken ?? 0">
                </div>
                <div class="mt-4 flex flex-col">
                    <label class="mb-2" for="">Temperature</label>
                    <input disabled min="0" max="2" size="0.1" step="0.1"
                        class="rounded-lg p-2 border dark:border-[#313131]" type="number"
                        [value]="researcherHistory?.temperature ?? 0">
                </div>
                <div class="mt-4 flex flex-col">
                    <label class="mb-2" for="">Nucleus Sampling Factor</label>
                    <input disabled min="0" max="1" size="0.1" step="0.1"
                        class="rounded-lg p-2 border dark:border-[#313131]" type="number"
                        [value]="researcherHistory?.nucleusSamplingFactor ?? 0">
                </div>
                <div class="mt-4 flex flex-col">
                    <label class="mb-2" for="">History Count</label>
                    <input disabled min="-2" max="2" size="0.1" step="0.1"
                        class="rounded-lg p-2 border dark:border-[#313131]" type="number"
                        [value]="researcherHistory?.historyCount ?? 10">
                </div>

                <div>
                    <a (click)="openEmbeddingModel()"
                        class="text-[var(--text-secondary)] dark:text-black dark:bg-white mt-4 bg-token-sidebar-surface-secondary px-4 py-2 rounded-[4px] text-sm flex items-center w-full cursor-pointer">
                        Embedded
                        Data
                    </a>
                </div>
            </div>
            <div>
                <div class="flex flex-col">
                    <label class="mb-2" for="">Category</label>
                    <input disabled class="rounded-lg p-2 border dark:border-[#313131]" type="text"
                        [value]="researcherHistory?.categoryName">
                </div>
                <div class="flex flex-col mt-4">
                    <label class="mb-2" for="">Frequency Penalty</label>
                    <input disabled min="-2" max="2" size="0.1" step="0.1"
                        class="rounded-lg p-2 border dark:border-[#313131]" type="number"
                        [value]="researcherHistory?.frequencyPenalty ?? 0">
                </div>
                <div class="mt-4 flex flex-col">
                    <label class="mb-2" for="">Presence Penalty</label>
                    <input disabled min="-2" max="2" size="0.1" step="0.1"
                        class="rounded-lg p-2 border dark:border-[#313131]" type="number"
                        [value]="researcherHistory?.presencePenalty ?? 0">
                </div>
                <div class="mt-4 flex flex-col">
                    <label class="mb-2" for="">Nearest Neighbors Count (KNN)</label>
                    <input disabled min="-2" max="2" size="0.1" step="0.1"
                        class="rounded-lg p-2 border dark:border-[#313131]" type="number"
                        [value]="researcherHistory?.kNearestNeighbors ?? 5">
                </div>
                <div class="mt-4 flex flex-col">
                    <label class="mb-2" for="">Completion Token (TBD)</label>
                    <input disabled min="-2" max="2" size="0.1" step="0.1"
                        class="rounded-lg p-2 border dark:border-[#313131]" type="number" value="0">
                </div>
                <div class="mt-4 flex flex-col">
                    <label class="mb-2" for="">Prompt Token (TBD)</label>
                    <input disabled min="-2" max="2" size="0.1" step="0.1"
                        class="rounded-lg p-2 border dark:border-[#313131]" type="number" value="0">
                </div>
                <div>
                    <a (click)="openFullPromptModal()"
                        class="text-[var(--text-secondary)] dark:text-black dark:bg-white mt-4 bg-token-sidebar-surface-secondary px-4 py-2 rounded-[4px] text-sm flex items-center w-full cursor-pointer">Full
                        prompt (Context)</a>
                </div>
            </div>
        </div>
    </form>
</div>