import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function extendedPatternValidator(pattern: RegExp, errorKey: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const isValid = pattern.test(control.value);
    const model = { [errorKey]: { value: control.value, valid: isValid } };

    return isValid ? null : model;
  };
}
