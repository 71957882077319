import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable()
export class BearerInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const isApiUrl = req.url.startsWith(environment.apiBaseUrl) || req.url.startsWith(environment.platformApiUrl);
    if (this.authService.isLoggedIn() && isApiUrl) {
      req = req.clone({
        setHeaders: { Authorization: this.authService.getAuthTokenHeader() },
      });
    }

    return next.handle(req);
  }
}
