import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from '../constants/appRoutes';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(private _router: Router, private _authService: AuthService) {}

  canActivate() {
    const isLoggedIn = this._authService.isLoggedIn();
    if (isLoggedIn) {
      return true;
    }

    return this._router.navigate([AppRoutes.Login]);
  }
}
