<div class="font-marfa h-svh bg-black w-full flex justify-between items-center flex-col">
    <div class="flex flex-col items-center bg-black mb-[-1px] w-full">
        <div class="flex flex-row align-middle items-center mt-[30px] cursor-pointer" routerLink="/">
            <div class="bg-white w-12 h-12 rounded-2xl p-1 flex items-center justify-center">
                <img class="fill-black w-9 h-9" src="../../../assets/icons/aria-logo-lg.svg">
            </div>
            <div class="text-[18px] ml-3.5 text-white">ARIA-I
            </div>
        </div>

        <div class="relative mt-[55px] rounded-t-[30px] sm:w-[576px] bg-white text-black 3xs:w-[340px]"
            [class]="showFooter ? '' : 'rounded-b-[30px] pb-8'">
            <div id="header">
                <div class="items-center flex-col flex w-full 3xs:px-4 sm:px-0">
                    <h1 class="text-[36px] font-[800] leading-9 text-center mt-10" *ngIf="title">{{ title }}</h1>
                    <h3 class="text-sm font-thin mt-2 leading-5 font-marfa-light max-w-[360px] text-center"
                        *ngIf="subtitle">{{ subtitle }}
                    </h3>
                </div>
            </div>

            <a class="absolute left-4 top-4 flex flex-row cursor-pointer" *ngIf="showBack" (click)="goBack()">
                <img src="/assets/icons/btns/arrow-left.svg" alt="" />
                <span class="ml-2 3xs:hidden sm:flex">Back</span>
            </a>

            <div id="body" class="mt-[30px] w-full flex items-center flex-col">
                @defer {
                <ng-content select="[body]" class="bg-black"></ng-content>
                }
            </div>
        </div>

        <div id="footer" *ngIf="showFooter"
            class="text-[12px] max-w-[576px] flex flex-col min-w-[340px] sm:w-[576px] justify-center h-[60px] items-center bg-[#333] text-white rounded-b-[30px]">
            @defer {
            <ng-content select="[footer]"></ng-content>
            }
        </div>
    </div>

    <div class="text-[#8b8b8b] text-[14px] pt-4 pb-8 text-center flex flex-col bg-black w-full">
        <div>
            <p class="text-sm font-marfa-light leading-5 text-center">
                <a href="https://www.nkoda.com/legal/end-user-agreement" target="_blank" class="font-marfa">Terms of
                    Service</a> and <a href="https://www.nkoda.com/legal/privacy-policy" target="_blank"
                    class="font-marfa">Privacy
                    Policy</a>
            </p>
        </div>
        <div class="flex flex-row justify-center w-full">
            <span class="font-marfa-light font-[50] mr-3">Need help?</span>
            <a href="mailto:hello@nkoda.com">hello&#64;nkoda.com</a>
        </div>
    </div>
</div>