<div
    class="popover bg-token-main-surface-primary overflow-y-auto flex flex-col gap-3 px-4 pb-1 text-sm text-token-text-primary sm:px-6 sm:pb-2 md:pl-0 w-full h-full">
    <button (click)="closeDialog()"
        class="absolute right-4 top-4 text-token-text-tertiary hover:text-token-text-secondary">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.34315 6.34338L17.6569 17.6571M17.6569 6.34338L6.34315 17.6571" stroke="currentColor"
                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
    </button>
    <div *ngIf="embeddings && embeddings.length" class="h-full prose pl-10">
        <div *ngFor="let item of embeddings">
            <h3>Data</h3>
            <p> {{item}}</p>
        </div>
    </div>
    <div *ngIf="!embeddings || !embeddings.length" class="w-full flex justify-center p-10">There was no embeddings used
        for
        this completion.</div>
</div>