<div class="aria-container h-svh dark:bg-[#212121]">
  <aria-sidebar class="aria-sidebar" [user]="user" (isClosed)="sidebarClosed($event)"></aria-sidebar>
  <aria-header class="aria-header"></aria-header>

  <div class="aria-main">
    <div class="flex w-full h-full">
      <div class="z-0 flex h-full w-full overflow-hidden flex-col col-span-7 md:col-span-7">
        <main class="relative h-full w-full flex-1 transition-width">
          <div role="presentation" class="flex h-full flex-col">
            <div class="flex-1">
              <div ariaScrollSectionDetect (sectionChange)="sectionChange($any($event))"
                class="relative h-full overflow-y-auto overflow-x-hidden" #scroll id="aria-chat">
                <div class="absolute left-0 right-0 pb-6">
                  <div *ngIf="selectedSessionModel?.messages?.length">
                    <section *ngFor="let item of selectedSessionModel.messages; let i = index">
                      <aria-user-message *ngIf="item?.role === roleTypes.User" [user]="user"
                        [message]="item?.text ?? ''">
                      </aria-user-message>

                      <aria-ai-message [id]="item.id"
                        *ngIf="item?.role === roleTypes.Aria || item?.role === roleTypes.Error"
                        [isLastElement]="i === selectedSessionModel.messages.length - 1" [isAdmin]="user.isAdmin"
                        [message]="item"
                        [isGenerating]="isGenerationInProgress && i === selectedSessionModel.messages.length - 1"
                        (regenerateMessageEvent)="regenerateLastMessage(item)">
                      </aria-ai-message>
                    </section>
                  </div>
                </div>
                <div class="flex h-full flex-col items-center justify-center"
                  *ngIf="!selectedSessionModel?.messages?.length && loaded">

                  <div class="text-2xl font-medium text-center" *ngIf="modelConfig?.quickIntro; else defaultSalutation">
                    Click the hand for a quick intro to ARIA-i
                  </div>

                  <div class="relative cursor-pointer" (click)="setQuickIntroMessage()">
                    <div class="mb-3 h-20 w-20">
                      <div class="gizmo-shadow-stroke relative flex h-full items-center justify-center rounded-full">
                        <ng-lottie class="w-20 h-20" [options]="lottieOptions"></ng-lottie>
                      </div>
                    </div>
                  </div>

                  <aria-predefined-questions
                    (predefinedQuestionClicked)="sendMessageWithGivenMessage($event)"></aria-predefined-questions>
                </div>
              </div>

              <button *ngIf="!isAutoScrollEnabled" (click)="scrollChatToBottom(true, 0, 'smooth')" class="cursor-pointer absolute z-10 rounded-full bg-clip-padding border right-1/2 bg-token-main-surface-primary bottom-2 
                dark:border-[#313131] hover:scale-120 hover:transition-500"><svg width="24" height="24"
                  viewBox="0 0 24 24" fill="none" class="m-1 ">
                  <path d="M17 13L12 18L7 13M12 6L12 17" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"></path>
                </svg>
              </button>
            </div>
          </div>
        </main>

        <div class="bottom-0 left-auto ">
          <div class="w-full pt-2 md:pt-0 md:w-[calc(100%-.5rem)]">
            <form class="stretch mx-2 flex flex-row gap-3 last:mb-2 md:last:mb-6 mx-auto max-w-[46rem]">
              <div class="relative flex h-full flex-1 items-stretch md:flex-col">
                <form class="flex w-full items-center" [formGroup]="messageFormGroup">
                  <div
                    class="overflow-hidden [&amp;:has(textarea:focus)]:shadow-[0_2px_6px_rgba(0,0,0,.05)] flex flex-col w-full
                     flex-grow relative border rounded-2xl bg-token-main-surface-primary align-middle justify-center dark:border-[#313131] max-h-[212px]">
                    <textarea id="prompt-textarea" tabindex="0" data-id="root" rows="1" placeholder="Message ARIA-i..."
                      class="m-0 w-full resize-none border-0 bg-transparent py-[10px] pr-10 focus:ring-0 focus-visible:ring-0 dark:bg-transparent  md:py-3.5 md:pr-12
                       pl-3 md:pl-4 text-sm" formControlName="message" (keydown)="onEnterPress($event)">
                    </textarea>
                    <button *ngIf="!isGenerationInProgress" [disabled]="isSendButtonDisabled" (click)="sendMessage()"
                      class="absolute bg-gray-800  dark:bg-[#c0c0c0] right-2 bottom-1.5 disabled:opacity-10 
                       text-white p-0.5 rounded-lg transition-colors md:bottom-2.5">
                      <span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="text-white dark:text-black">
                          <path d="M7 11L12 6L17
                            11M12 18V7" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round">
                          </path>
                        </svg>
                      </span>
                    </button>

                    <button type="button" *ngIf="isGenerationInProgress" (click)="stopGeneration()"
                      class="absolute rounded-full border-2 border-gray-950 p-1 dark:border-gray-200 right-3"><svg
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"
                        class="h-2 w-2 text-token-text-primary" height="16" width="16">
                        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z"
                          stroke-width="0"></path>
                      </svg>
                    </button>
                  </div>
                </form>
              </div>
            </form>

            <div class="text-[11px] text-center text-secondary mt-1">
              ARIA-i can make mistakes. Please double-check its responses.
            </div>
          </div>
        </div>
      </div>

      <aria-nkoda-link-panel *ngIf="modelConfig?.shouldUseContentPrompt"
        class="3xs:hidden md:flex z-0 overflow-hidden pb-2 pr-2 rounded-l-lg rounded-bl-lg border-l dark:border-[#333] border-[#f4f4f4]"></aria-nkoda-link-panel>
    </div>
  </div>
</div>

<ng-template #defaultSalutation>
  <div class="mb-5 text-2xl font-medium">
    How can I help you today?
  </div>
</ng-template>