import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ModalService } from 'ngx-modal-ease';

@Component({
  selector: 'aria-embedding',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './embedding.component.html',
  styleUrl: './embedding.component.scss',
})
export class EmbeddingComponent implements OnInit {
  embeddings!: Array<string>;

  constructor(private _modalService: ModalService) {}
  ngOnInit(): void {
    this.embeddings = this._modalService.options?.data!['embeddings'] as Array<string>;
  }

  closeDialog() {
    this._modalService.close();
  }
}
