/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiStripeEventPost } from '../fn/stripe/api-stripe-event-post';
import { ApiStripeEventPost$Params } from '../fn/stripe/api-stripe-event-post';
import { apiStripeSessionCheckoutSessionIdPut$Json } from '../fn/stripe/api-stripe-session-checkout-session-id-put-json';
import { ApiStripeSessionCheckoutSessionIdPut$Json$Params } from '../fn/stripe/api-stripe-session-checkout-session-id-put-json';
import { apiStripeSessionCheckoutSessionIdPut$Plain } from '../fn/stripe/api-stripe-session-checkout-session-id-put-plain';
import { ApiStripeSessionCheckoutSessionIdPut$Plain$Params } from '../fn/stripe/api-stripe-session-checkout-session-id-put-plain';
import { apiStripeSessionCustomerPost$Json } from '../fn/stripe/api-stripe-session-customer-post-json';
import { ApiStripeSessionCustomerPost$Json$Params } from '../fn/stripe/api-stripe-session-customer-post-json';
import { apiStripeSessionCustomerPost$Plain } from '../fn/stripe/api-stripe-session-customer-post-plain';
import { ApiStripeSessionCustomerPost$Plain$Params } from '../fn/stripe/api-stripe-session-customer-post-plain';
import { apiStripeSessionPost$Json } from '../fn/stripe/api-stripe-session-post-json';
import { ApiStripeSessionPost$Json$Params } from '../fn/stripe/api-stripe-session-post-json';
import { apiStripeSessionPost$Plain } from '../fn/stripe/api-stripe-session-post-plain';
import { ApiStripeSessionPost$Plain$Params } from '../fn/stripe/api-stripe-session-post-plain';
import { CheckoutSessionResponse } from '../models/checkout-session-response';
import { ReceiptDto } from '../models/receipt-dto';

@Injectable({ providedIn: 'root' })
export class StripeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiStripeSessionPost()` */
  static readonly ApiStripeSessionPostPath = '/api/stripe/session';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStripeSessionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStripeSessionPost$Plain$Response(params?: ApiStripeSessionPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CheckoutSessionResponse>> {
    return apiStripeSessionPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStripeSessionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStripeSessionPost$Plain(params?: ApiStripeSessionPost$Plain$Params, context?: HttpContext): Observable<CheckoutSessionResponse> {
    return this.apiStripeSessionPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CheckoutSessionResponse>): CheckoutSessionResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStripeSessionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStripeSessionPost$Json$Response(params?: ApiStripeSessionPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CheckoutSessionResponse>> {
    return apiStripeSessionPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStripeSessionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStripeSessionPost$Json(params?: ApiStripeSessionPost$Json$Params, context?: HttpContext): Observable<CheckoutSessionResponse> {
    return this.apiStripeSessionPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CheckoutSessionResponse>): CheckoutSessionResponse => r.body)
    );
  }

  /** Path part for operation `apiStripeSessionCheckoutSessionIdPut()` */
  static readonly ApiStripeSessionCheckoutSessionIdPutPath = '/api/stripe/session/{checkoutSessionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStripeSessionCheckoutSessionIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStripeSessionCheckoutSessionIdPut$Plain$Response(params: ApiStripeSessionCheckoutSessionIdPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceiptDto>> {
    return apiStripeSessionCheckoutSessionIdPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStripeSessionCheckoutSessionIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStripeSessionCheckoutSessionIdPut$Plain(params: ApiStripeSessionCheckoutSessionIdPut$Plain$Params, context?: HttpContext): Observable<ReceiptDto> {
    return this.apiStripeSessionCheckoutSessionIdPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceiptDto>): ReceiptDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStripeSessionCheckoutSessionIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStripeSessionCheckoutSessionIdPut$Json$Response(params: ApiStripeSessionCheckoutSessionIdPut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceiptDto>> {
    return apiStripeSessionCheckoutSessionIdPut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStripeSessionCheckoutSessionIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStripeSessionCheckoutSessionIdPut$Json(params: ApiStripeSessionCheckoutSessionIdPut$Json$Params, context?: HttpContext): Observable<ReceiptDto> {
    return this.apiStripeSessionCheckoutSessionIdPut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceiptDto>): ReceiptDto => r.body)
    );
  }

  /** Path part for operation `apiStripeSessionCustomerPost()` */
  static readonly ApiStripeSessionCustomerPostPath = '/api/stripe/session/customer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStripeSessionCustomerPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStripeSessionCustomerPost$Plain$Response(params?: ApiStripeSessionCustomerPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return apiStripeSessionCustomerPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStripeSessionCustomerPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStripeSessionCustomerPost$Plain(params?: ApiStripeSessionCustomerPost$Plain$Params, context?: HttpContext): Observable<string> {
    return this.apiStripeSessionCustomerPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStripeSessionCustomerPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStripeSessionCustomerPost$Json$Response(params?: ApiStripeSessionCustomerPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return apiStripeSessionCustomerPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStripeSessionCustomerPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStripeSessionCustomerPost$Json(params?: ApiStripeSessionCustomerPost$Json$Params, context?: HttpContext): Observable<string> {
    return this.apiStripeSessionCustomerPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiStripeEventPost()` */
  static readonly ApiStripeEventPostPath = '/api/stripe/event';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStripeEventPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStripeEventPost$Response(params?: ApiStripeEventPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiStripeEventPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStripeEventPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStripeEventPost(params?: ApiStripeEventPost$Params, context?: HttpContext): Observable<void> {
    return this.apiStripeEventPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
