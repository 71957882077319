import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChatSectionService {
  private _chatSectionSubject = new BehaviorSubject<string | null>(null);
  public chatSectionObs$ = this._chatSectionSubject.asObservable();

  constructor() {}

  next(value: string | null) {
    this._chatSectionSubject.next(value);
  }

  getLastSectionId() {
    const sections = document.querySelectorAll('aria-ai-message');
    if (!sections || !sections.length) return null;
    return sections[sections.length - 1]?.id;
  }
}
