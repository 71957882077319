import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, OnDestroy, OnInit } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subject, takeUntil } from 'rxjs';
import { ComposerNkodaLinkDto, NkodaLinkDto, NkodaLinksDto, TitleNkodaLinkDto } from '../../api/aria/models';
import { LinkService } from '../../api/aria/services/link.service';
import { SwiperDirective } from '../../directives/swiper.directive';
import { LinkType } from '../../models/link-type';
import { ChatSectionService } from '../../services/chat-section.service';
import { NkodaLinkPanelService } from '../../services/nkoda-link-panel.service';
import { NkodaLinkEditionListComponent } from '../nkoda-link-edition-list/nkoda-link-edition-list.component';
import { NkodaLinkTitleListComponent } from '../nkoda-link-title-list/nkoda-link-title-list.component';
import { PaginationComponent } from '../pagination/pagination.component';
import { TabModel } from './../../models/tab-model';
import { CloseNkodaLinkPanelComponent } from './../close-nkoda-link-panel/close-nkoda-link-panel.component';

@Component({
  selector: 'aria-nkoda-link-panel',
  standalone: true,
  imports: [
    SvgIconComponent,
    CommonModule,
    SwiperDirective,
    CloseNkodaLinkPanelComponent,
    NgxSkeletonLoaderModule,
    NkodaLinkEditionListComponent,
    PaginationComponent,
    NkodaLinkTitleListComponent,
  ],
  templateUrl: './nkoda-link-panel.component.html',
  styleUrl: './nkoda-link-panel.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NkodaLinkPanelComponent implements OnInit, OnDestroy {
  data: NkodaLinksDto = {};
  composers: Array<ComposerNkodaLinkDto> = [];
  titles: Array<TitleNkodaLinkDto> = [];

  selectedComposer!: ComposerNkodaLinkDto;
  selectedTab: number = 0;
  selectedSectionId!: string;

  tabs!: Array<TabModel>;
  currentPageIndex = 1;

  linkType = LinkType;

  isComposerInfoExpanded = false;
  isRequestInProgress = false;

  hasNextItems = false;
  hasPrevItems = false;
  isPanelHidden = true;

  private _unsubscribeObs$ = new Subject();
  constructor(
    private _linkService: LinkService,
    private _nkodaLinkPanel: NkodaLinkPanelService,
    private _chatSectionService: ChatSectionService
  ) {}

  ngOnInit(): void {
    this.initializeTabs();
    this._nkodaLinkPanel.showObs.pipe(takeUntil(this._unsubscribeObs$)).subscribe({
      next: (isHidden) => (this.isPanelHidden = isHidden),
    });

    this._nkodaLinkPanel.requestLoadingOb$.pipe(takeUntil(this._unsubscribeObs$)).subscribe({
      next: (isInProgress) => (this.isRequestInProgress = isInProgress),
    });

    this._nkodaLinkPanel.panelDataObs$.pipe(takeUntil(this._unsubscribeObs$)).subscribe({
      next: (data) => {
        this.data = data;
        this.selectedSectionId = this._chatSectionService.getLastSectionId()!;
        this.initializeTabs();
        this.mapData();
      },
    });

    this._chatSectionService.chatSectionObs$.pipe(takeUntil(this._unsubscribeObs$)).subscribe({
      next: (sectionId) => {
        if (!sectionId) return;
        this.selectedSectionId = sectionId;
        this.mapData();
      },
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeObs$.complete();
  }

  setNextItem() {
    if (this.selectedComposer.hasData) {
      return;
    }

    const link = {
      id: this.selectedComposer.id,
    } as NkodaLinkDto;
    this._linkService
      .apiLinkPut$Json({
        body: link,
      })
      .subscribe({
        next: (updatedLink) => {
          if (updatedLink.linkType === 0) {
            this.selectedComposer.hasData = updatedLink.hasData;
            if (updatedLink.object) {
              const composer = updatedLink as ComposerNkodaLinkDto;
              composer.composer = JSON.parse(updatedLink.object);

              const index = this.composers.findIndex((x) => x.id === composer.id);
              this.composers[index].composer = composer.composer;
              this.composers[index].hasData = true;
              this.selectedComposer = composer;
            }
          }
        },
      });
  }

  pageChanged(pageNr: number) {
    this.currentPageIndex = pageNr;
    this.setItemByPage();
  }

  setItemByPage() {
    this.selectedComposer = this.composers[this.currentPageIndex - 1];
    const composerIndex = this.composers.findIndex((x) => x.id === this.selectedComposer.id);
    this.hasPrevItems = this.currentPageIndex - 1 !== 0;
    this.hasNextItems = composerIndex !== this.composers.length - 1;

    this.setNextItem();
  }

  toggleMoreInfo() {
    this.isComposerInfoExpanded = !this.isComposerInfoExpanded;
  }

  selectTab(tab: TabModel) {
    if (!tab?.hasItems) return;

    const currentActiveTab = this.tabs.find((x) => x.isActive);
    if (currentActiveTab) this.tabs[currentActiveTab.id!].isActive = false;

    this.tabs[tab.id!].isActive = true;
    this.selectedTab = tab.id!;
  }

  private mapData() {
    if (!this.data || (!this.data.composerLinks?.length && !this.data.titleLinks?.length)) {
      this.composers = [];
      this.titles = [];
      this._nkodaLinkPanel.hide();
      return;
    }

    this.composers = [];
    this.titles = [];

    if (this.data.composerLinks && this.data.composerLinks.length) {
      const composers = this.data.composerLinks.filter((x) => x.messageId === this.selectedSectionId);
      this.composers = composers;
      this.selectedComposer = this.composers[0];
    }

    if (this.data.titleLinks && this.data.titleLinks.length) {
      const titles = this.data.titleLinks.filter((x) => x.messageId === this.selectedSectionId);
      this.titles = titles;
    }

    this.tabs.forEach((tab, index) => {
      if (index === 0) {
        this.tabs[index].hasItems = this.titles.length > 0;
      }

      if (index === 1) {
        this.tabs[index].hasItems = this.composers.length > 0;
      }

      if (!tab.hasItems) tab.isActive = tab.hasItems;
    });

    const active = this.tabs.find((x) => x.isActive) ?? this.tabs.find((x) => x.hasItems);
    this.selectTab(active!);
    if (active) {
      this._nkodaLinkPanel.show();
    }

    setTimeout(() => {
      this.currentPageIndex = 1;
    }, 500);
  }

  private initializeTabs() {
    this.tabs = [
      {
        name: 'Titles',
        id: 0,
        isActive: false,
        hasItems: false,
        iconPath: '/assets/icons/work.svg',
      },
      {
        name: 'Composers',
        id: 1,
        isActive: false,
        hasItems: false,
        iconPath: '/assets/icons/composer.svg',
      },
    ];
  }
}
