/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiProductB2CGet$Json } from '../fn/product/api-product-b-2-c-get-json';
import { ApiProductB2CGet$Json$Params } from '../fn/product/api-product-b-2-c-get-json';
import { apiProductB2CGet$Plain } from '../fn/product/api-product-b-2-c-get-plain';
import { ApiProductB2CGet$Plain$Params } from '../fn/product/api-product-b-2-c-get-plain';
import { apiProductChannelChannelRefSeatgroupSeatGroupIdGet$Json } from '../fn/product/api-product-channel-channel-ref-seatgroup-seat-group-id-get-json';
import { ApiProductChannelChannelRefSeatgroupSeatGroupIdGet$Json$Params } from '../fn/product/api-product-channel-channel-ref-seatgroup-seat-group-id-get-json';
import { apiProductChannelChannelRefSeatgroupSeatGroupIdGet$Plain } from '../fn/product/api-product-channel-channel-ref-seatgroup-seat-group-id-get-plain';
import { ApiProductChannelChannelRefSeatgroupSeatGroupIdGet$Plain$Params } from '../fn/product/api-product-channel-channel-ref-seatgroup-seat-group-id-get-plain';
import { apiProductChannelChannelRefSeatgroupSeatGroupRefConfigurationPost$Json } from '../fn/product/api-product-channel-channel-ref-seatgroup-seat-group-ref-configuration-post-json';
import { ApiProductChannelChannelRefSeatgroupSeatGroupRefConfigurationPost$Json$Params } from '../fn/product/api-product-channel-channel-ref-seatgroup-seat-group-ref-configuration-post-json';
import { apiProductChannelChannelRefSeatgroupSeatGroupRefConfigurationPost$Plain } from '../fn/product/api-product-channel-channel-ref-seatgroup-seat-group-ref-configuration-post-plain';
import { ApiProductChannelChannelRefSeatgroupSeatGroupRefConfigurationPost$Plain$Params } from '../fn/product/api-product-channel-channel-ref-seatgroup-seat-group-ref-configuration-post-plain';
import { apiProductDefaultGet } from '../fn/product/api-product-default-get';
import { ApiProductDefaultGet$Params } from '../fn/product/api-product-default-get';
import { apiProductGet$Json } from '../fn/product/api-product-get-json';
import { ApiProductGet$Json$Params } from '../fn/product/api-product-get-json';
import { apiProductGet$Plain } from '../fn/product/api-product-get-plain';
import { ApiProductGet$Plain$Params } from '../fn/product/api-product-get-plain';
import { apiProductIdDelete$Json } from '../fn/product/api-product-id-delete-json';
import { ApiProductIdDelete$Json$Params } from '../fn/product/api-product-id-delete-json';
import { apiProductIdDelete$Plain } from '../fn/product/api-product-id-delete-plain';
import { ApiProductIdDelete$Plain$Params } from '../fn/product/api-product-id-delete-plain';
import { apiProductPagedGet$Json } from '../fn/product/api-product-paged-get-json';
import { ApiProductPagedGet$Json$Params } from '../fn/product/api-product-paged-get-json';
import { apiProductPagedGet$Plain } from '../fn/product/api-product-paged-get-plain';
import { ApiProductPagedGet$Plain$Params } from '../fn/product/api-product-paged-get-plain';
import { apiProductPost$Json } from '../fn/product/api-product-post-json';
import { ApiProductPost$Json$Params } from '../fn/product/api-product-post-json';
import { apiProductPost$Plain } from '../fn/product/api-product-post-plain';
import { ApiProductPost$Plain$Params } from '../fn/product/api-product-post-plain';
import { apiProductProductIdGet$Json } from '../fn/product/api-product-product-id-get-json';
import { ApiProductProductIdGet$Json$Params } from '../fn/product/api-product-product-id-get-json';
import { apiProductProductIdGet$Plain } from '../fn/product/api-product-product-id-get-plain';
import { ApiProductProductIdGet$Plain$Params } from '../fn/product/api-product-product-id-get-plain';
import { apiProductProductIdThresholdGet$Json } from '../fn/product/api-product-product-id-threshold-get-json';
import { ApiProductProductIdThresholdGet$Json$Params } from '../fn/product/api-product-product-id-threshold-get-json';
import { apiProductProductIdThresholdGet$Plain } from '../fn/product/api-product-product-id-threshold-get-plain';
import { ApiProductProductIdThresholdGet$Plain$Params } from '../fn/product/api-product-product-id-threshold-get-plain';
import { apiProductPut$Json } from '../fn/product/api-product-put-json';
import { ApiProductPut$Json$Params } from '../fn/product/api-product-put-json';
import { apiProductPut$Plain } from '../fn/product/api-product-put-plain';
import { ApiProductPut$Plain$Params } from '../fn/product/api-product-put-plain';
import { apiProductUserGet$Json } from '../fn/product/api-product-user-get-json';
import { ApiProductUserGet$Json$Params } from '../fn/product/api-product-user-get-json';
import { apiProductUserGet$Plain } from '../fn/product/api-product-user-get-plain';
import { ApiProductUserGet$Plain$Params } from '../fn/product/api-product-user-get-plain';
import { apiProductUserIdentityUserIdGet$Json } from '../fn/product/api-product-user-identity-user-id-get-json';
import { ApiProductUserIdentityUserIdGet$Json$Params } from '../fn/product/api-product-user-identity-user-id-get-json';
import { apiProductUserIdentityUserIdGet$Plain } from '../fn/product/api-product-user-identity-user-id-get-plain';
import { ApiProductUserIdentityUserIdGet$Plain$Params } from '../fn/product/api-product-user-identity-user-id-get-plain';
import { apiProductUserUserIdentityIdConfigurationPost$Json } from '../fn/product/api-product-user-user-identity-id-configuration-post-json';
import { ApiProductUserUserIdentityIdConfigurationPost$Json$Params } from '../fn/product/api-product-user-user-identity-id-configuration-post-json';
import { apiProductUserUserIdentityIdConfigurationPost$Plain } from '../fn/product/api-product-user-user-identity-id-configuration-post-plain';
import { ApiProductUserUserIdentityIdConfigurationPost$Plain$Params } from '../fn/product/api-product-user-user-identity-id-configuration-post-plain';
import { ProductDto } from '../models/product-dto';
import { ProductDtoPagedResult } from '../models/product-dto-paged-result';
import { ProductWithConfigDto } from '../models/product-with-config-dto';
import { ThresholdInfo } from '../models/threshold-info';

@Injectable({ providedIn: 'root' })
export class ProductService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiProductGet()` */
  static readonly ApiProductGetPath = '/api/product';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGet$Plain$Response(params?: ApiProductGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductDto>>> {
    return apiProductGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGet$Plain(params?: ApiProductGet$Plain$Params, context?: HttpContext): Observable<Array<ProductDto>> {
    return this.apiProductGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductDto>>): Array<ProductDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGet$Json$Response(params?: ApiProductGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductDto>>> {
    return apiProductGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductGet$Json(params?: ApiProductGet$Json$Params, context?: HttpContext): Observable<Array<ProductDto>> {
    return this.apiProductGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductDto>>): Array<ProductDto> => r.body)
    );
  }

  /** Path part for operation `apiProductPut()` */
  static readonly ApiProductPutPath = '/api/product';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductPut$Plain$Response(params?: ApiProductPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductDto>> {
    return apiProductPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductPut$Plain(params?: ApiProductPut$Plain$Params, context?: HttpContext): Observable<ProductDto> {
    return this.apiProductPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductDto>): ProductDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductPut$Json$Response(params?: ApiProductPut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductDto>> {
    return apiProductPut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductPut$Json(params?: ApiProductPut$Json$Params, context?: HttpContext): Observable<ProductDto> {
    return this.apiProductPut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductDto>): ProductDto => r.body)
    );
  }

  /** Path part for operation `apiProductPost()` */
  static readonly ApiProductPostPath = '/api/product';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductPost$Plain$Response(params?: ApiProductPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductDto>> {
    return apiProductPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductPost$Plain(params?: ApiProductPost$Plain$Params, context?: HttpContext): Observable<ProductDto> {
    return this.apiProductPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductDto>): ProductDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductPost$Json$Response(params?: ApiProductPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductDto>> {
    return apiProductPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductPost$Json(params?: ApiProductPost$Json$Params, context?: HttpContext): Observable<ProductDto> {
    return this.apiProductPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductDto>): ProductDto => r.body)
    );
  }

  /** Path part for operation `apiProductUserGet()` */
  static readonly ApiProductUserGetPath = '/api/product/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUserGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductUserGet$Plain$Response(params?: ApiProductUserGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductDto>>> {
    return apiProductUserGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductUserGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductUserGet$Plain(params?: ApiProductUserGet$Plain$Params, context?: HttpContext): Observable<Array<ProductDto>> {
    return this.apiProductUserGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductDto>>): Array<ProductDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUserGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductUserGet$Json$Response(params?: ApiProductUserGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductDto>>> {
    return apiProductUserGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductUserGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductUserGet$Json(params?: ApiProductUserGet$Json$Params, context?: HttpContext): Observable<Array<ProductDto>> {
    return this.apiProductUserGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductDto>>): Array<ProductDto> => r.body)
    );
  }

  /** Path part for operation `apiProductB2CGet()` */
  static readonly ApiProductB2CGetPath = '/api/product/b2c';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductB2CGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductB2CGet$Plain$Response(params?: ApiProductB2CGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductDto>>> {
    return apiProductB2CGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductB2CGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductB2CGet$Plain(params?: ApiProductB2CGet$Plain$Params, context?: HttpContext): Observable<Array<ProductDto>> {
    return this.apiProductB2CGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductDto>>): Array<ProductDto> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductB2CGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductB2CGet$Json$Response(params?: ApiProductB2CGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductDto>>> {
    return apiProductB2CGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductB2CGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductB2CGet$Json(params?: ApiProductB2CGet$Json$Params, context?: HttpContext): Observable<Array<ProductDto>> {
    return this.apiProductB2CGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductDto>>): Array<ProductDto> => r.body)
    );
  }

  /** Path part for operation `apiProductProductIdGet()` */
  static readonly ApiProductProductIdGetPath = '/api/product/{productId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductProductIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductProductIdGet$Plain$Response(params: ApiProductProductIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductDto>> {
    return apiProductProductIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductProductIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductProductIdGet$Plain(params: ApiProductProductIdGet$Plain$Params, context?: HttpContext): Observable<ProductDto> {
    return this.apiProductProductIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductDto>): ProductDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductProductIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductProductIdGet$Json$Response(params: ApiProductProductIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductDto>> {
    return apiProductProductIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductProductIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductProductIdGet$Json(params: ApiProductProductIdGet$Json$Params, context?: HttpContext): Observable<ProductDto> {
    return this.apiProductProductIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductDto>): ProductDto => r.body)
    );
  }

  /** Path part for operation `apiProductPagedGet()` */
  static readonly ApiProductPagedGetPath = '/api/product/paged';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductPagedGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductPagedGet$Plain$Response(params?: ApiProductPagedGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductDtoPagedResult>> {
    return apiProductPagedGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductPagedGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductPagedGet$Plain(params?: ApiProductPagedGet$Plain$Params, context?: HttpContext): Observable<ProductDtoPagedResult> {
    return this.apiProductPagedGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductDtoPagedResult>): ProductDtoPagedResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductPagedGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductPagedGet$Json$Response(params?: ApiProductPagedGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductDtoPagedResult>> {
    return apiProductPagedGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductPagedGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductPagedGet$Json(params?: ApiProductPagedGet$Json$Params, context?: HttpContext): Observable<ProductDtoPagedResult> {
    return this.apiProductPagedGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductDtoPagedResult>): ProductDtoPagedResult => r.body)
    );
  }

  /** Path part for operation `apiProductDefaultGet()` */
  static readonly ApiProductDefaultGetPath = '/api/product/default';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductDefaultGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductDefaultGet$Response(params?: ApiProductDefaultGet$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiProductDefaultGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductDefaultGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductDefaultGet(params?: ApiProductDefaultGet$Params, context?: HttpContext): Observable<void> {
    return this.apiProductDefaultGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiProductIdDelete()` */
  static readonly ApiProductIdDeletePath = '/api/product/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductIdDelete$Plain$Response(params: ApiProductIdDelete$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductDto>> {
    return apiProductIdDelete$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductIdDelete$Plain(params: ApiProductIdDelete$Plain$Params, context?: HttpContext): Observable<ProductDto> {
    return this.apiProductIdDelete$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductDto>): ProductDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductIdDelete$Json$Response(params: ApiProductIdDelete$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductDto>> {
    return apiProductIdDelete$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductIdDelete$Json(params: ApiProductIdDelete$Json$Params, context?: HttpContext): Observable<ProductDto> {
    return this.apiProductIdDelete$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductDto>): ProductDto => r.body)
    );
  }

  /** Path part for operation `apiProductChannelChannelRefSeatgroupSeatGroupIdGet()` */
  static readonly ApiProductChannelChannelRefSeatgroupSeatGroupIdGetPath = '/api/product/channel/{channelRef}/seatgroup/{seatGroupId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductChannelChannelRefSeatgroupSeatGroupIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductChannelChannelRefSeatgroupSeatGroupIdGet$Plain$Response(params: ApiProductChannelChannelRefSeatgroupSeatGroupIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductWithConfigDto>> {
    return apiProductChannelChannelRefSeatgroupSeatGroupIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductChannelChannelRefSeatgroupSeatGroupIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductChannelChannelRefSeatgroupSeatGroupIdGet$Plain(params: ApiProductChannelChannelRefSeatgroupSeatGroupIdGet$Plain$Params, context?: HttpContext): Observable<ProductWithConfigDto> {
    return this.apiProductChannelChannelRefSeatgroupSeatGroupIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductWithConfigDto>): ProductWithConfigDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductChannelChannelRefSeatgroupSeatGroupIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductChannelChannelRefSeatgroupSeatGroupIdGet$Json$Response(params: ApiProductChannelChannelRefSeatgroupSeatGroupIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductWithConfigDto>> {
    return apiProductChannelChannelRefSeatgroupSeatGroupIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductChannelChannelRefSeatgroupSeatGroupIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductChannelChannelRefSeatgroupSeatGroupIdGet$Json(params: ApiProductChannelChannelRefSeatgroupSeatGroupIdGet$Json$Params, context?: HttpContext): Observable<ProductWithConfigDto> {
    return this.apiProductChannelChannelRefSeatgroupSeatGroupIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductWithConfigDto>): ProductWithConfigDto => r.body)
    );
  }

  /** Path part for operation `apiProductUserIdentityUserIdGet()` */
  static readonly ApiProductUserIdentityUserIdGetPath = '/api/product/user/{identityUserId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUserIdentityUserIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductUserIdentityUserIdGet$Plain$Response(params: ApiProductUserIdentityUserIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductWithConfigDto>> {
    return apiProductUserIdentityUserIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductUserIdentityUserIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductUserIdentityUserIdGet$Plain(params: ApiProductUserIdentityUserIdGet$Plain$Params, context?: HttpContext): Observable<ProductWithConfigDto> {
    return this.apiProductUserIdentityUserIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductWithConfigDto>): ProductWithConfigDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUserIdentityUserIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductUserIdentityUserIdGet$Json$Response(params: ApiProductUserIdentityUserIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductWithConfigDto>> {
    return apiProductUserIdentityUserIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductUserIdentityUserIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductUserIdentityUserIdGet$Json(params: ApiProductUserIdentityUserIdGet$Json$Params, context?: HttpContext): Observable<ProductWithConfigDto> {
    return this.apiProductUserIdentityUserIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductWithConfigDto>): ProductWithConfigDto => r.body)
    );
  }

  /** Path part for operation `apiProductChannelChannelRefSeatgroupSeatGroupRefConfigurationPost()` */
  static readonly ApiProductChannelChannelRefSeatgroupSeatGroupRefConfigurationPostPath = '/api/product/channel/{channelRef}/seatgroup/{seatGroupRef}/configuration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductChannelChannelRefSeatgroupSeatGroupRefConfigurationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductChannelChannelRefSeatgroupSeatGroupRefConfigurationPost$Plain$Response(params: ApiProductChannelChannelRefSeatgroupSeatGroupRefConfigurationPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductWithConfigDto>> {
    return apiProductChannelChannelRefSeatgroupSeatGroupRefConfigurationPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductChannelChannelRefSeatgroupSeatGroupRefConfigurationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductChannelChannelRefSeatgroupSeatGroupRefConfigurationPost$Plain(params: ApiProductChannelChannelRefSeatgroupSeatGroupRefConfigurationPost$Plain$Params, context?: HttpContext): Observable<ProductWithConfigDto> {
    return this.apiProductChannelChannelRefSeatgroupSeatGroupRefConfigurationPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductWithConfigDto>): ProductWithConfigDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductChannelChannelRefSeatgroupSeatGroupRefConfigurationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductChannelChannelRefSeatgroupSeatGroupRefConfigurationPost$Json$Response(params: ApiProductChannelChannelRefSeatgroupSeatGroupRefConfigurationPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductWithConfigDto>> {
    return apiProductChannelChannelRefSeatgroupSeatGroupRefConfigurationPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductChannelChannelRefSeatgroupSeatGroupRefConfigurationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductChannelChannelRefSeatgroupSeatGroupRefConfigurationPost$Json(params: ApiProductChannelChannelRefSeatgroupSeatGroupRefConfigurationPost$Json$Params, context?: HttpContext): Observable<ProductWithConfigDto> {
    return this.apiProductChannelChannelRefSeatgroupSeatGroupRefConfigurationPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductWithConfigDto>): ProductWithConfigDto => r.body)
    );
  }

  /** Path part for operation `apiProductUserUserIdentityIdConfigurationPost()` */
  static readonly ApiProductUserUserIdentityIdConfigurationPostPath = '/api/product/user/{userIdentityId}/configuration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUserUserIdentityIdConfigurationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUserUserIdentityIdConfigurationPost$Plain$Response(params: ApiProductUserUserIdentityIdConfigurationPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductWithConfigDto>> {
    return apiProductUserUserIdentityIdConfigurationPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductUserUserIdentityIdConfigurationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUserUserIdentityIdConfigurationPost$Plain(params: ApiProductUserUserIdentityIdConfigurationPost$Plain$Params, context?: HttpContext): Observable<ProductWithConfigDto> {
    return this.apiProductUserUserIdentityIdConfigurationPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductWithConfigDto>): ProductWithConfigDto => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductUserUserIdentityIdConfigurationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUserUserIdentityIdConfigurationPost$Json$Response(params: ApiProductUserUserIdentityIdConfigurationPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductWithConfigDto>> {
    return apiProductUserUserIdentityIdConfigurationPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductUserUserIdentityIdConfigurationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProductUserUserIdentityIdConfigurationPost$Json(params: ApiProductUserUserIdentityIdConfigurationPost$Json$Params, context?: HttpContext): Observable<ProductWithConfigDto> {
    return this.apiProductUserUserIdentityIdConfigurationPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductWithConfigDto>): ProductWithConfigDto => r.body)
    );
  }

  /** Path part for operation `apiProductProductIdThresholdGet()` */
  static readonly ApiProductProductIdThresholdGetPath = '/api/product/{productId}/threshold';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductProductIdThresholdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductProductIdThresholdGet$Plain$Response(params: ApiProductProductIdThresholdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ThresholdInfo>> {
    return apiProductProductIdThresholdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductProductIdThresholdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductProductIdThresholdGet$Plain(params: ApiProductProductIdThresholdGet$Plain$Params, context?: HttpContext): Observable<ThresholdInfo> {
    return this.apiProductProductIdThresholdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ThresholdInfo>): ThresholdInfo => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProductProductIdThresholdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductProductIdThresholdGet$Json$Response(params: ApiProductProductIdThresholdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ThresholdInfo>> {
    return apiProductProductIdThresholdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiProductProductIdThresholdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiProductProductIdThresholdGet$Json(params: ApiProductProductIdThresholdGet$Json$Params, context?: HttpContext): Observable<ThresholdInfo> {
    return this.apiProductProductIdThresholdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ThresholdInfo>): ThresholdInfo => r.body)
    );
  }

}
