<aria-onboarding-wrapper title="Welcome back!" subtitle="Please login in to access your account" [showFooter]="true"
    [showBack]="false">
    <div body>
        <form [formGroup]="loginFormGroup" (ngSubmit)="submit()"
            class="flex flex-col text-sm text-black mx-5 items-center w-[300px] sm:w-[260px]">
            <aria-text-input class="w-full" placeholder="Email" type="email" name="Email" [formControl]="userName"
                id="aria-email-input">
            </aria-text-input>
            <aria-text-input class="w-full" placeholder="Password" type="password" [formControl]="password"
                name="Password" id="aria-password-input">
            </aria-text-input>

            <div class="w-full flex items-start justify-start">
                <p class="onboarding-text-error text-[10px] ml-4"
                    *ngIf="serverErrorVM && userName.value && password.value">
                    {{ serverErrorVM }}
                </p>
            </div>

            <div class="flex flex-row text-[11px] items-center justify-between w-full mb-4 mt-1">
                <div class="flex flex-row items-center justify-center">
                    <aria-check-box (valueEmitted)="saveCredentials($event)" class="h-6"></aria-check-box>

                    <span class="font-thin font-marfa-light">Remember me</span>
                </div>
                <a class="font-marfa cursor-pointer" routerLink="/forgot-password">Forgot password?</a>
            </div>

            <aria-submit-button class="w-full" text="Login" [loading]="requestInProgress"></aria-submit-button>
        </form>

        <div id="login-socials" class="my-6">
            <p class="flex justify-center text-center mb-4 text-sm">or continue with</p>
            <aria-provider></aria-provider>
        </div>
    </div>

    <div footer>
        <div class="flex flex-row">
            <p class="font-marfa-light">Get your magic link</p>
            <a routerLink="/magic-link" class="ml-3">Sign-in password free</a>
        </div>
        <div class="flex flex-row mt-1">
            <p class="font-marfa-light">If you don't have a Aria-I account</p>
            <a routerLink="/register" class="ml-3">Sign Up</a>
        </div>
    </div>
</aria-onboarding-wrapper>