import { CommonModule, Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { OnboardingWrapperComponent } from '../../components/onboarding/onboarding-wrapper/onboarding-wrapper.component';
import { SubmitButtonComponent } from '../../components/onboarding/submit-button/submit-button.component';
import { TextInputComponent } from '../../components/onboarding/text-input/text-input.component';
import { AuthService } from '../../services/auth.service';
import { TextInputService } from '../../services/text-input.service';

@Component({
  selector: 'aria-magic-link',
  standalone: true,
  imports: [
    TextInputComponent,
    ReactiveFormsModule,
    RouterModule,
    CommonModule,
    OnboardingWrapperComponent,
    SubmitButtonComponent,
  ],
  templateUrl: './magic-link.component.html',
  styleUrl: './magic-link.component.scss',
})
export class MagicLinkComponent implements OnInit, OnDestroy {
  magicLinkControl = new FormControl('', [Validators.required, Validators.email]);
  emailSent = false;
  serverErrorVM!: string;
  loading!: boolean;

  private _unsubscribe$ = new Subject<void>();

  constructor(
    private _authService: AuthService,
    private _textInputService: TextInputService,
    private _location: Location
  ) {}

  ngOnInit(): void {
    this._unsubscribe$ = new Subject();
  }

  ngOnDestroy(): void {
    this._unsubscribe$.complete();
  }

  goBack() {
    this._location.back();
  }

  submit() {
    this.serverErrorVM = '';
    if (!this.magicLinkControl.valid || !this.magicLinkControl.value) {
      this.magicLinkControl.markAsTouched();
      this._textInputService.shakeInput();
      return;
    }

    this.loading = true;
    this._authService
      .getMagicLink(this.magicLinkControl.value)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe({
        next: (result: boolean) => {
          if (result) {
            this.emailSent = true;
            return;
          }

          this.serverErrorVM = `Unexpected error ocurred, please try again.`;
        },
        error: (response: HttpErrorResponse) => {
          this.serverErrorVM = response.error.errorMessage;
        },
      })
      .add(() => (this.loading = false));
  }

  sendAgain() {
    this.emailSent = false;
    this.magicLinkControl.reset();
  }
}
