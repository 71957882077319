import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TitleNkodaLinkDto } from '../../api/aria/models';

@Component({
  selector: 'aria-nkoda-link-title-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './nkoda-link-title-list.component.html',
  styleUrl: './nkoda-link-title-list.component.scss',
})
export class NkodaLinkTitleListComponent {
  @Input() titles: Array<TitleNkodaLinkDto> = [];
}
