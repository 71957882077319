<article class="w-full mt-4" style="--avatar-color: #8B8B8B hover:show-controls" (mouseover)="showControls = true"
    (mouseleave)="showControls = false">
    <div class="pr-4 pl-6 justify-center text-base m-auto">
        <div class="flex flex-1 text-base mx-auto gap-3 md:px-5 lg:px-1 xl:px-5 max-w-[48rem] group final-completion">
            <div class="flex-shrink-0 flex flex-col relative items-end">
                <div>
                    <div class="pt-0.5">
                        <div
                            class="gizmo-shadow-stroke flex items-center justify-center overflow-hidden rounded-full bot-avatar">
                            <div class="relative p-1 rounded-sm h-7 w-7 flex items-center justify-center">
                                <svg-icon src="../../../assets/icons/aria-logo-lg.svg"
                                    [svgStyle]="{ 'width.px': 18, 'color': 'var(--text-primary)'}"></svg-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="relative flex w-full flex-col lg:w-[100%] agent-turn sender-text-area">
                <div class="font-medium select-none h-8 items-center flex">ARIA-i</div>
                <div class="flex-col gap-1 md:gap-3 font-light text-sm text-[13px] 3xs:-ml-10 md:-ml-6  mt-2">
                    <div class="flex flex-grow flex-col max-w-full">
                        <div
                            class="min-h-[20px] max-w-full text-message flex flex-col items-start gap-3 [.text-message+&amp;]:mt-5 3xs:mr-4 md:mr-0">
                            <div class="w-14 h-14" *ngIf="(isAriaThinkingObs$ | async) && isGenerating; else markdown">
                                <ng-lottie class="w-14 h-14" [options]="lottieOptions"></ng-lottie>
                            </div>
                        </div>
                    </div>
                    <div class="justify-start" [style.opacity]="showControls || isLastElement ? '1' : 0">
                        <div class="text-gray-400 flex self-end mt-2 -ml-1" *ngIf="!isGenerating">
                            <button (click)="copyAnswerToClipBoard()" data-twe-ripple-init
                                class="rounded-full w-6 h-6 flex items-center justify-center"
                                data-twe-ripple-color="#999" data-twe-ripple-rippleDuration="1000ms">
                                <svg-icon src="assets/icons/btns/copy.svg"
                                    [svgStyle]="{ 'width.px': 20, 'fill': 'var(--text-primary)' }"></svg-icon>
                            </button>
                            <button (click)="regenerateMessage()" data-twe-ripple-init data-twe-ripple-color="#999"
                                class="rounded-full w-6 h-6 flex items-center justify-center">
                                <div>
                                    <svg-icon src="assets/icons/btns/regenerate.svg" class="-mt-2"
                                        [svgStyle]="{ 'width.px': 18, 'fill': 'var(--text-primary)', 'margin-top': '-2px' }"></svg-icon>
                                </div>
                            </button>
                            <button *ngIf="isAdmin" (click)="showPromptConfigurations()" data-twe-ripple-init
                                data-twe-ripple-color="#999"
                                class="rounded-full w-6 h-6 flex items-center justify-center">
                                <div>
                                    <svg-icon src="assets/icons/btns/info.svg" class="-mt-2"
                                        [svgStyle]="{ 'width.px': 24, 'fill': 'var(--text-primary)' }"></svg-icon>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</article>

<ng-template #markdown>
    <markdown *ngIf="message.text" class="prose dark:prose-invert font-light text-sm text-[13px] break-words 
        3xs:w-[92%] md:w-full 3xs:block overflow-x-hidden" data="{{message.text}}{{isGenerating ? ' ●' : ''}}">
    </markdown>

    <div *ngIf="message.role === roleType.Error">
        <div class="prose error font-[500]">{{ errorText }}</div>
    </div>
</ng-template>