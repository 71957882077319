export class StringUtils {
  static capitalizeFirstLetter(givenString: string) {
    return givenString.charAt(0).toUpperCase() + givenString.slice(1);
  }

  static isNullOrEmpty(givenString: string | null | undefined) {
    return givenString === '' || givenString === null || givenString === undefined;
  }

  static uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}
