<div class="flex flex-row justify-center w-full">
    <div class="max-w-[256px] flex" [class.provider-grid]="useGrid">
        <div class="flex justify-center place-items-center provider-{{i + 1}}"
            *ngFor="let provider of providers; let i = index">
            <button data-twe-ripple-init data-twe-ripple-color="#999"
                class="rounded-[50%] w-12 h-12 bg-[#f1f1f1] flex items-center justify-center mx-2 my-1"
                (click)="loginProvider(provider)">
                <span class="btn btn--{{ provider.toLocaleLowerCase() }} cursor-pointer"></span>
            </button>
        </div>
    </div>
</div>